import { FC } from 'react'
import clsx from 'clsx'
import { FormGroup } from '@mui/material'
import styles from './CreateUserStepContainer.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

export const CreateUserStepContainer: FC<{ withCard?: boolean; firstStep?: true }> = ({
  children,
  withCard,
  firstStep,
}) => {
  const { mobile } = useBreakpoints()
  return (
    <FormGroup className={clsx(styles.groupContainer, mobile && styles.mobile)}>
      <div
        className={clsx(
          styles.content,
          withCard && styles.card,
          mobile && firstStep && styles.mobile
        )}
      >
        {children}
      </div>
    </FormGroup>
  )
}
