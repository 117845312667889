import { FC } from 'react'
import { useCreateUserContext } from './useCreateUserContext'
import { CreateUserStepContainer } from './CreateUserStepContainer'
import { UserOrderPermission } from '../../userManagementPage/order-permission/UserOrderPermission'

export const CreateUserFormStepThree: FC = () => {
  const {
    cartOrderableStatus,
    setCartOrderableStatus,
    cartPermissionStatus,
    setCartPermissionStatus,
    usersToNotify,
    setUsersToNotify,
    allowedToOrder,
    setAllowedToOrder,
    maxValueFullRange,
    setMaxValueFullRange,
    maxValueOrders,
    maxValueOrdersActive,
    setMaxValueOrders,
    maxValueFullRangeActive,
    setMaxValueFullRangeActive,
    setMaxValueOrdersActive,
    projectCartIds,
    setProjectCartIds,
  } = useCreateUserContext()
  return (
    <CreateUserStepContainer>
      <UserOrderPermission
        compact
        cartOrderableStatus={cartOrderableStatus}
        setCartOrderableStatus={setCartOrderableStatus}
        cartPermissionStatus={cartPermissionStatus}
        setCartPermissionStatus={setCartPermissionStatus}
        usersToNotify={usersToNotify}
        setUsersToNotify={setUsersToNotify}
        allowedToOrder={allowedToOrder}
        setAllowedToOrder={setAllowedToOrder}
        maxValueFullRange={maxValueFullRange}
        setMaxValueFullRange={setMaxValueFullRange}
        maxValueOrders={maxValueOrders}
        maxValueOrdersActive={maxValueOrdersActive}
        setMaxValueOrders={setMaxValueOrders}
        maxValueFullRangeActive={maxValueFullRangeActive}
        setMaxValueFullRangeActive={setMaxValueFullRangeActive}
        setMaxValueOrdersActive={setMaxValueOrdersActive}
        projectCartIds={projectCartIds}
        setProjectCartIds={setProjectCartIds}
      />
    </CreateUserStepContainer>
  )
}
