import { MultiSelectionString, ShoppingCartForDropdown } from '@obeta/models/lib/models'
import { PopoverOrigin } from '@mui/material'
import Grid from '@mui/material/Grid2'
import clsx from 'clsx'
import styles from './OrderDetailsSelectionBar.module.scss'
import { DropdownTemplates } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { OrderV2 } from '@obeta/schema'

// Actions
import {
  addOrderItemsToCartGraphQL,
  AddOrderItemsToCartGraphQLResultAction,
  CartsActionTypes,
} from '@obeta/data/lib/actions'

// Assets
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'

// Components
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { PrimaryButton } from '../custom-button/CustomButton'
import { SplitButton } from '../split-button/SplitButton'
import { SelectionBar } from '../selection-bar/SelectionBar'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useDispatch } from 'react-redux'
import { useActionNotification, useSelectShoppingCart } from '@obeta/data/lib/hooks'
import { useOrderDetailsContext } from '@obeta/data/lib/stores/useOrderDetailsContext'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import React, { useRef } from 'react'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { IdsTransferActionButton } from '../ids/IdsTransferActionButton'
import { useIdsBulkTransferBack } from '@obeta/data/lib/hooks/ids/useIdsBulkTransferBack'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { AnimatedCartButton } from '../add-items-to-cart-button/AnimatedCartButton'
import {
  EButtonState,
  useAnimatedButtonState,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

interface Props {
  dropDownCarts: ShoppingCartForDropdown[]
  modifiedOrderItemAmounts: { orderItemId: string; amount: number }[]
  order: OrderV2 | null
  resetSelectedOrderItems: () => void
  selectedOrderItems: MultiSelectionString
  searchTerm: string
}
export const OrderDetailsSelectionBar: React.FC<Props> = ({
  dropDownCarts,
  modifiedOrderItemAmounts,
  order,
  resetSelectedOrderItems,
  searchTerm,
  selectedOrderItems,
}) => {
  const { setAnimationState: setButtonState, animationState } = useAnimatedButtonState()
  const waitForAction = useActionNotification(CartsActionTypes.AddOrderItemsToCartGraphQLResult)
  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

  const { desktop, mobile, tabletAll } = useBreakpoints()
  const cartsV2 = useCartsv2WithPricesAndStock()
  const dispatch = useDispatch()
  const splitButtonRef = useRef<HTMLDivElement>(null)
  const popoverState = usePopoverState(splitButtonRef)
  const onSetActiveCart = useSelectShoppingCart()
  const userV2 = useUserV2()

  const bulkTransferIds = useIdsBulkTransferBack('order')

  const defaultCartId = userV2?.settings?.defaultCartId || ''
  const defaultCart = cartsV2.find((cart) => cart.id === defaultCartId)
  const cartName = defaultCart ? defaultCart.name : '-'

  const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
  const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

  const showIdsTransferBackButton = useIsIdsTransferBackPossible()

  const { itemState, orderItems } = useOrderDetailsContext()
  const getSelectedOrderItemCount = () => {
    // SELECT ALL W/ OR W/OUT EXCLUDE
    if (selectedOrderItems.selectAll) {
      if (searchTerm !== '' || itemState !== 'all') {
        if (selectedOrderItems.exclude.length) {
          if (!orderItems?.length) return 0
          return orderItems?.length - selectedOrderItems.exclude.length || 0
        }
        return orderItems.length
      } else {
        if (selectedOrderItems.exclude.length) {
          if (!order?.itemCount) return 0
          return order?.itemCount - selectedOrderItems.exclude.length || 0
        }
        return order?.itemCount ?? 0
      }
    }
    // INCLUDE ONLY
    if (selectedOrderItems.include) {
      return selectedOrderItems.include.length
    }
  }
  function handleAddToCart(cartId: string) {
    addMultipleOrderItemsToCart(cartId)
  }

  const selectedOrderItemCount = getSelectedOrderItemCount()
  const addMultipleOrderItemsToCart = (cartId: string) => {
    if (order) {
      if (isAddToCartProcessIndicator) {
        setButtonState(EButtonState.Loading)
      }
      // Note, addOrderItemsToCartGraphQLEffect handles the user-facing notification rendering regarding this action
      dispatch(
        addOrderItemsToCartGraphQL(
          {
            orderId: order.id as string,
            cartId: cartId,
            include: selectedOrderItems.include,
            exclude: selectedOrderItems.exclude,
            amount: modifiedOrderItemAmounts,
            search: {
              searchTerm,
              orderBy: '',
              orderDir: '',
              filter: itemState === 'all' ? '' : itemState,
            },
          },
          selectedOrderItemCount ?? 0
        )
      )
      if (isAddToCartProcessIndicator) {
        waitForAction((action: AddOrderItemsToCartGraphQLResultAction) => {
          setButtonState(action.success ? EButtonState.Success : EButtonState.Error)
          resetSelectedOrderItems() // deselect all order items
        })
      } else {
        // deselect all order items
        resetSelectedOrderItems()
      }
      onSetActiveCart(cartId)
    }
  }

  return (
    <SelectionBar portalKey="order-details-selection-bar">
      <div className={styles.selectionBar}>
        <Grid className={styles.selectionBarItem} size={{ xs: 6 }}>
          <DropdownTemplates
            anchorOrigin={anchorOrigin}
            desktop={desktop}
            disabled={(selectedOrderItemCount as number) <= 0}
            mobile={mobile}
            order={{
              id: order?.id ?? '0',
              multiSelection: {
                selectAll: selectedOrderItems.selectAll,
                include: selectedOrderItems.include,
                exclude: selectedOrderItems.exclude,
                search: selectedOrderItems.search,
              },
              modifiedOrderItemAmounts,
              selectedItemCount: (selectedOrderItemCount as number) ?? 0,
            }}
            productsToAdd={[
              {
                productId: '',
                amount: 0,
              },
            ]}
            selectedArticleCount={selectedOrderItemCount}
            templatesType={DropdownTemplatesType.ADD_ORDER_ITEMS}
            transformOrigin={transformOrigin}
          />
        </Grid>
        <Grid className={clsx(styles.selectionBarItem, styles.selectionBarCart)} size={{ xs: 6 }}>
          {mobile &&
            (isAddToCartProcessIndicator ? (
              <AnimatedCartButton
                buttonState={animationState}
                disabled={!selectedOrderItemCount}
                onClick={() => handleAddToCart(defaultCartId)}
              />
            ) : (
              <PrimaryButton
                disabled={!selectedOrderItemCount}
                leftIcon={<AddShoppingCartIcon />}
                onClick={() => {
                  handleAddToCart(defaultCartId)
                }}
              />
            ))}
          {(tabletAll || desktop) && (
            <>
              {showIdsTransferBackButton && order && (
                <IdsTransferActionButton
                  onClick={() =>
                    bulkTransferIds({
                      orderId: order.id,
                      include: selectedOrderItems.include,
                      exclude: selectedOrderItems.exclude,
                      amount: modifiedOrderItemAmounts,
                      search: {
                        searchTerm,
                        orderBy: '',
                        orderDir: '',
                        filter: itemState === 'all' ? '' : itemState,
                      },
                    })
                  }
                  amountOfProducts={selectedOrderItemCount}
                  disabled={selectedOrderItemCount === 0}
                  className={styles.idsButton}
                />
              )}
              {isAddToCartProcessIndicator ? (
                <AnimatedCartButton
                  ref={splitButtonRef}
                  disabled={!selectedOrderItemCount}
                  buttonState={animationState}
                  cartsLength={cartsV2.length}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => handleAddToCart(defaultCartId)}
                  size={mobile ? 'large' : 'small'}
                />
              ) : cartsV2.length > 1 ? (
                <SplitButton
                  disabled={!selectedOrderItemCount}
                  loading={false}
                  size={mobile ? 'large' : 'small'}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => handleAddToCart(defaultCartId)}
                />
              ) : (
                <PrimaryButton
                  disabled={!selectedOrderItemCount}
                  leftIcon={<AddShoppingCartIcon />}
                  size={mobile ? 'large' : 'small'}
                  onClick={() => handleAddToCart(defaultCartId)}
                >
                  {cartName}
                </PrimaryButton>
              )}
              <DropdownCartsBase
                carts={dropDownCarts}
                mobile={mobile}
                onOptionSelected={(cart) => {
                  handleAddToCart(cart.id)
                }}
                dropdown={{
                  anchorEl: mobile ? null : popoverState.anchorEl,
                  onClose: popoverState.onClose,
                  open: popoverState.open,
                }}
              />
            </>
          )}
        </Grid>
      </div>
    </SelectionBar>
  )
}
