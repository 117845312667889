import React, { useEffect } from 'react'
import styles from './ShoppingCartShippingForm.module.scss'
import { Controller, FieldErrors, Control } from 'react-hook-form'
import { Box, Button, Divider, SvgIcon, TextField, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import { checkSplittAddress, splittAddress } from '@obeta/utils/lib/splitt-address'

interface Props {
  control: Control<DeliveryAddressV2 & { address: string; zip: string }>
  handleSubmit
  errors: FieldErrors
  isDirty: boolean
  isValid: boolean
  reset
  deliveryAddress: DeliveryAddressV2 | undefined
  updateShippingAddress: (deliveryAddress: DeliveryAddressV2, addressId?: string) => void
  isEditableCopyOfSpecialTour: boolean
  setIsEditableCopyOfSpecialTour: (isEditableCopyOfSpecialTour: boolean) => void
  trigger: (
    name?:
      | 'address'
      | 'name1'
      | 'name2'
      | 'city'
      | 'zip'
      | ('address' | 'name1' | 'name2' | 'city' | 'zip')[]
      | undefined
  ) => Promise<boolean>
}

export const ShoppingCartShippingForm: React.FC<Props> = (props) => {
  const {
    control,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    reset, //useForm hook from "react-hook-form"
    trigger,
    deliveryAddress,
    updateShippingAddress,
    isEditableCopyOfSpecialTour,
    setIsEditableCopyOfSpecialTour,
  } = props

  useEffect(() => {
    trigger(['zip', 'name1', 'name2', 'city', 'address']).then()
  }, [trigger])

  const onSubmit = (values) => {
    const addressSplit = splittAddress(values.address)

    const deliveryAddress: DeliveryAddressV2 = {
      name1: values.name1,
      name2: values.name2,
      zipCode: values.zip,
      city: values.city,
      street: addressSplit?.[1] ?? '',
      houseNumber: addressSplit?.[2] ?? '',
    }

    // update delivery address and reset address id
    updateShippingAddress(deliveryAddress, '')
    if (isEditableCopyOfSpecialTour) {
      setIsEditableCopyOfSpecialTour(false)
    }

    reset({
      name1: values.name1,
      name2: values.name2,
      address: (addressSplit?.[1] ?? '') + ' ' + (addressSplit?.[2] ?? ''),
      city: values.city,
      zip: values.zip,
    })
  }

  const { t } = useTranslation()
  const theme = useTheme()

  const handleCancel = () => {
    if (!isEditableCopyOfSpecialTour) {
      reset({
        name1: deliveryAddress?.name1,
        name2: deliveryAddress?.name2,
        address: deliveryAddress?.street + ' ' + deliveryAddress?.houseNumber,
        city: deliveryAddress?.city,
        zip: deliveryAddress?.zipCode,
      })
    } else {
      setIsEditableCopyOfSpecialTour(false)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={'row'} spacing={1}>
          <>
            <Grid size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.COMPANY_NAME')}*
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    error={!!errors.name1}
                    className={styles.text_input}
                    variant="outlined"
                    id="name1"
                    name="name1"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.name1 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.name1.type === 'required'
                              ? t('ADDRESSES.ERROR.COMPANY_REQUIRED')
                              : errors.name1.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.COMPANY_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name1"
                defaultValue={deliveryAddress?.name1}
                control={control}
                rules={{
                  required: true,
                  maxLength: 35,
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.CUSTOMER_NAME')}
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="name2"
                    name="name2"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.name2 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.name2.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.NAME_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name2"
                defaultValue={deliveryAddress?.name2}
                control={control}
                rules={{
                  maxLength: 35,
                }}
              />
            </Grid>
            <Grid className={styles.grid} size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.ADDRESS')}*
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="address"
                    name="address"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    helperText={
                      errors.address ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.address?.type === 'required'
                              ? t('ADDRESSES.ERROR.ADDRESS_REQUIRED')
                              : errors.address?.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.STREET_NUMBER_MAX_LENGTH')
                              : t('ADDRESSES.ERROR.ADDRESS_INVALID')}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="address"
                defaultValue={`${deliveryAddress?.street} ${deliveryAddress?.houseNumber}`}
                control={control}
                rules={{
                  required: true,
                  maxLength: 60,
                  validate: checkSplittAddress,
                }}
              />
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.ZIP_CODE')}*
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    error={!!errors.zip}
                    className={styles.text_input}
                    variant="outlined"
                    id="zip"
                    name="zip"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.zip ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.zip.type === 'required'
                              ? t('ADDRESSES.ERROR.ZIP_CODE_REQUIRED')
                              : t('ADDRESSES.ERROR.ZIP_CODE_INVALID')}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                defaultValue={deliveryAddress?.zipCode}
                name="zip"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{5}$/i,
                    message: t('ADDRESSES.ERROR.ZIP_CODE_INVALID'),
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Typography variant={'body'}>{t('SHOPPING_CART.CHECKOUT.ADDRESS.CITY')}*</Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    error={!!errors.city}
                    className={styles.text_input}
                    variant="outlined"
                    id="city"
                    name="city"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.city ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.city.type === 'required'
                              ? t('ADDRESSES.ERROR.CITY_REQUIRED')
                              : errors.city.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.CITY_INVALID')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="city"
                defaultValue={deliveryAddress?.city}
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                }}
              />
            </Grid>
          </>
          {/* Buttons and Divider are only rendered, if the user is editing the delivery address */}
          {(isDirty || isEditableCopyOfSpecialTour) && (
            <>
              <Grid size={{ xs: 12 }}>
                <Divider className={styles.divider} />
              </Grid>
              <Grid className={styles.editingButtonsGrid} size={{ xs: 12 }}>
                <Button
                  variant={'tertiary'}
                  onClick={handleCancel}
                  type="reset"
                  className={styles.cancelButton}
                >
                  {t('SHOPPING_CART.ACTIONS.ABORT')}
                </Button>
                <Button disabled={!isValid} variant={'contained'} color="secondary" type="submit">
                  {t('SHOPPING_CART.CHECKOUT.DELIVERY_CHANGE_BUTTON')}
                </Button>
              </Grid>
            </>
          )}
          <Grid size={{ xs: 12 }}>
            <Divider className={styles.divider} />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
