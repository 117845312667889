import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'

const USER_ID_SEPARATOR = '-'
export function buildUserId({
  userId,
  companyId,
  includeSubuser,
}: {
  userId: string
  companyId: string
  includeSubuser?: boolean
}): string {
  if (userId.length === 0 || companyId.length === 0) {
    throw new Error(
      `Can not build customerId from empty userId or companyId (userId:"${userId}", companyId: "${companyId}")`
    )
  }

  // handles i.e. the following  user ids "1010-6509-9", "1010-6509", "6509-9"
  if (userId.includes(USER_ID_SEPARATOR)) {
    const parts = userId.split(USER_ID_SEPARATOR)
    if (parts[0] === companyId) {
      if (parts.length === 3 && includeSubuser) return `${parts[0]}-${parts[1]}-${parts[2]}`
      return `${parts[0]}-${parts[1]}`
    }
    const userIdWithoutSubUserId = parts[0]
    if (includeSubuser) {
      const suberUserId = parts[1]
      return `${companyId}-${userIdWithoutSubUserId}-${suberUserId}`
    }
    return `${companyId}-${userIdWithoutSubUserId}`
  }
  return `${companyId}-${userId}`
}

export function buildUserIdByUser(user: UserSubuser, includeSubuser: boolean) {
  return buildUserId({ userId: user.customerNumber, companyId: user.companyId, includeSubuser })
}
