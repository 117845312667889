import React, { FC, useCallback } from 'react'
import { Box, SvgIcon, TextField, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { useCreateUserContext } from './useCreateUserContext'
import { CreateUserStepContainer } from './CreateUserStepContainer'
import styles from './CreateUserDialogStepOne.module.scss'

const ErrorText: FC<{ errors: Array<string | null> }> = ({ errors }) => {
  const theme = useTheme()
  if (errors.filter(Boolean).length === 0) return null
  return (
    <Box className={styles.errors}>
      {errors.filter(Boolean).map((error) => (
        <div className={styles.error}>
          <SvgIcon
            style={{ marginRight: '0.25rem' }}
            component={WarningIcon}
            fontSize={'small'}
            htmlColor={theme.palette.primary.main}
          />
          <Typography variant={'smallText'} color={theme.palette.primary.main}>
            {error}
          </Typography>
        </div>
      ))}
    </Box>
  )
}

export const CreateUserFormStepOne: FC = () => {
  const {
    name,
    setName,
    emailConfirmation,
    setEmailConfirmation,
    emailConfirmationNotValidError,
    emailConfirmationError,
    setEmailConfirmationError,
    setEmailConfirmationNotValidError,
    setEmail,
    emailError,
    setEmailError,
    email,
  } = useCreateUserContext()
  const { t } = useTranslation()

  const validateEmailConfirmation = useCallback(
    (emailValue?: string) => {
      const compareValue = emailValue ?? email

      if (emailConfirmation) {
        if (isValidEmail(emailConfirmation)) {
          setEmailConfirmationNotValidError(null)
        } else {
          setEmailConfirmationNotValidError(
            t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.EMAIL_ADDRESS_NOT_VALID')
          )
        }
      }

      if (compareValue === emailConfirmation) {
        setEmailConfirmationError(null)
      } else {
        setEmailConfirmationError(
          t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.EMAIL_ADDRESS_CONFIRMATION_ERROR')
        )
      }
    },
    [email, emailConfirmation, setEmailConfirmationError, setEmailConfirmationNotValidError, t]
  )

  return (
    <CreateUserStepContainer withCard firstStep>
      <Typography className={styles.label} variant="body">
        {t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.NAME')}*
      </Typography>
      <TextField
        variant="outlined"
        id="name"
        name="name"
        value={name}
        onChange={(event) => {
          setName(event.target.value)
        }}
        style={{ marginBottom: '2rem' }}
      />
      <Typography className={styles.label} variant="body">
        {t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.EMAIL_ADDRESS')}*
      </Typography>
      <TextField
        variant="outlined"
        id="email"
        name="email"
        value={email}
        onChange={(event) => {
          if (emailConfirmation) {
            validateEmailConfirmation(event.target.value)
          }
          setEmailError(null)
          setEmail(event.target.value)
        }}
        onBlur={() => {
          const notValid = !(email && isValidEmail(email))
          if (notValid) {
            setEmailError(t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.EMAIL_ADDRESS_NOT_VALID'))
          }
        }}
        style={{ marginBottom: '1rem' }}
        helperText={emailError ? <ErrorText errors={[emailError]} /> : null}
        error={Boolean(emailError)}
      />
      <Typography className={styles.label} variant="body">
        {t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.EMAIL_ADDRESS_CONFIRMATION')}*
      </Typography>
      <TextField
        variant="outlined"
        id="emailConfirmation"
        name="emailConfirmation"
        value={emailConfirmation}
        onChange={(event) => {
          setEmailConfirmationError(null)
          setEmailConfirmationNotValidError(null)
          setEmailConfirmation(event.target.value)
        }}
        onBlur={(event) => validateEmailConfirmation()}
        helperText={<ErrorText errors={[emailConfirmationNotValidError, emailConfirmationError]} />}
        error={Boolean(emailConfirmationError)}
      />
      <Typography variant={'smallText'} className={styles.requiredField}>
        {`*${t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.REQUIRED_FIELD')}`}
      </Typography>
    </CreateUserStepContainer>
  )
}
