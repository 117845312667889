import { FC } from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import { Step, StepLabel, Stepper, StepIconProps } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { ReactComponent as CheckIcon } from '@obeta/assets/icon/check_circle_outline.svg'
import styles from './FormProgress.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

const CustomStepIconComponent: FC<StepIconProps> = (props) => {
  const { active, completed } = props
  return (
    <div
      className={clsx(
        styles.customStepIcon,
        active && styles.active,
        completed && styles.completed
      )}
    >
      <div className={styles.customStepIconCircle}>{completed && <CheckIcon />}</div>
    </div>
  )
}

// code from mui docs with minor color and size adjustments
// https://mui.com/material-ui/react-stepper/#system-CustomizedSteppers.js
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#bebebe',
    borderTopWidth: 1,
    borderRadius: 1,
  },
}))

type Props = {
  steps: string[]
  activeStep: number
  className?: string
}

export const FormProgress: FC<Props> = ({ steps, activeStep, className }) => {
  const { mobile } = useBreakpoints()
  return (
    <div className={clsx(styles.container, className)}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
        {steps.map((step, index) => {
          const isActive = index === activeStep
          return (
            <Step key={step}>
              <StepLabel
                className={clsx(styles.stepLabel, isActive && styles.active)}
                StepIconComponent={CustomStepIconComponent}
              >
                {!mobile || isActive ? step : ''}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
