import React, { useEffect } from 'react'
import styles from './ShoppingCartOrderDeclarations.module.scss'
import { Box, SvgIcon, TextField, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { ContentMask } from './ContentMask'

interface Props {
  control: Control
  errors: FieldErrors
  additionalText: string
  commission: string
  phone: string
  phoneIsRequired: boolean
  trigger: (name?: string | string[]) => Promise<boolean>
  userCanWriteAdditionalText: boolean
  userCanWriteCommission: boolean
  userHasToWriteCommission: boolean
  isDeactivated?: boolean
}
export const ShoppingCartOrderDeclarations: React.FC<Props> = (props) => {
  const {
    control,
    errors, // provided via useForm hook from "react-hook-form"
    additionalText,
    commission,
    phone,
    trigger,
    phoneIsRequired,
    userCanWriteAdditionalText,
    userCanWriteCommission,
    userHasToWriteCommission,
    isDeactivated,
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  useEffect(() => {
    trigger(['phone', 'commission', 'additionalText']).then()
  }, [trigger])
  // See https://react-hook-form.com/api/usewatch/ - to observe changing input value
  const inputValueForAdditionalText = useWatch({ control, name: 'additionalText' }) ?? ''
  const commissionText: string = useWatch({ control, name: 'commission' }) ?? ''
  const isCommissionTextError = userHasToWriteCommission && commissionText.length === 0

  return (
    <div className={styles.orderDeclarations}>
      {isDeactivated && <ContentMask />}
      <Typography variant="h4">{t('SHOPPING_CART.CHECKOUT.ORDER_DECLARATIONS')}</Typography>
      <form>
        <Grid
          container
          direction={'row'}
          spacing={1}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Grid className={styles.commissionGrid} size={{ xs: 12, md: 6 }}>
            <Typography
              data-testid="order-declarations-commission-header"
              variant={'body'}
              color={userCanWriteCommission ? 'text.primary' : 'text.disabled'}
            >
              {t('SHOPPING_CART.CHECKOUT.COMMISSION')}
            </Typography>
            <Controller
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  disabled={!userCanWriteCommission}
                  error={!!errors.commission}
                  className={styles.text_input}
                  variant="outlined"
                  id="commission"
                  name="commission"
                  onChange={onChange}
                  value={value}
                  placeholder={userCanWriteCommission ? t('SHOPPING_CART.PROVIDE_IF_GIVEN') : ''}
                  onBlur={onBlur}
                  helperText={
                    errors.commission || isCommissionTextError ? (
                      <Box display={'flex'}>
                        <SvgIcon
                          className={styles.helperTextIcon}
                          component={WarningIcon}
                          fontSize={'small'}
                          htmlColor={theme.palette.primary.main}
                        />
                        <Typography variant={'smallText'} color={theme.palette.primary.main}>
                          {errors?.commission?.type === 'maxLength' &&
                            t('SHOPPING_CART.CHECKOUT.ERROR.COMMISSION_MAX_LENGTH')}
                          {errors?.commission?.type !== 'maxLength' &&
                            isCommissionTextError &&
                            t('SHOPPING_CART.CHECKOUT.ERROR.COMMISSION_NOT_EMPTY')}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )
                  }
                />
              )}
              name="commission"
              control={control}
              defaultValue={commission}
              rules={{
                required: userHasToWriteCommission,
                maxLength: 30,
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant={'body'}>
              {t('SHOPPING_CART.CHECKOUT.PHONE')}
              {phoneIsRequired ? '*' : ''}
            </Typography>
            <Controller
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  error={!!errors.phone}
                  className={styles.text_input}
                  variant="outlined"
                  id="phone"
                  name="phone"
                  onChange={onChange}
                  placeholder={'+49 |'}
                  value={value}
                  onBlur={onBlur}
                  helperText={
                    errors.phone ? (
                      <Box display={'flex'}>
                        <SvgIcon
                          className={styles.helperTextIcon}
                          component={WarningIcon}
                          fontSize={'small'}
                          htmlColor={theme.palette.primary.main}
                        />
                        <Typography variant={'smallText'} color={theme.palette.primary.main}>
                          {t('SHOPPING_CART.CHECKOUT.ERROR.PHONE_INVALID')}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )
                  }
                />
              )}
              name="phone"
              control={control}
              defaultValue={phone}
              rules={{
                required: phoneIsRequired,
                minLength: phoneIsRequired ? 6 : 0,
                maxLength: 20,
                // Only  +, - , / , and digits allowed
                pattern: /^[0-9+/-]+$/,
              }}
            />
          </Grid>
          <div className={styles.additionalText}>
            <div>
              <Typography
                variant={'body'}
                color={userCanWriteAdditionalText ? 'text.primary' : 'text.disabled'}
              >
                {t('SHOPPING_CART.CHECKOUT.ADDITIONAL_TEXT')}
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    className={clsx(styles.text_input, styles.additionalTextInput)}
                    disabled={!userCanWriteAdditionalText}
                    variant="outlined"
                    multiline
                    id="additionalText"
                    name="additionalText"
                    onChange={onChange}
                    placeholder={
                      userCanWriteAdditionalText ? t('SHOPPING_CART.YOUR_ADDITIONAL_TEXT') : ''
                    }
                    value={value}
                    inputProps={{ maxLength: 130 }}
                    rows={4}
                    onBlur={onBlur}
                  />
                )}
                name="additionalText"
                control={control}
                defaultValue={additionalText}
                rules={{
                  required: false,
                  maxLength: 130,
                }}
              />
            </div>
            <Grid container>
              <Grid size={{ xs: 9, md: 10 }}>
                <Typography
                  variant={'smallText'}
                  color={userCanWriteAdditionalText ? 'text.primary' : 'text.disabled'}
                >
                  {t('SHOPPING_CART.CHECKOUT.ORDER_DECLARATIONS_INFO')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 3, md: 2 }} textAlign="end">
                <Typography
                  data-testid="order-declarations-other-charCount"
                  variant={'smallText'}
                  color={userCanWriteAdditionalText ? 'text.primary' : 'text.disabled'}
                >
                  {(inputValueForAdditionalText as string).length}{' '}
                  {t('SHOPPING_CART.CHECKOUT.ORDER_DECLARATIONS_INFO_MAX_LENGTH')}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <LightGrayDivider sx={{ width: '100%' }} />
        </Grid>
      </form>
    </div>
  )
}
