import React, { CSSProperties, FC, ReactNode, useRef } from 'react'
import clsx from 'clsx'
import { FormControlLabel, Radio, Tooltip, Typography } from '@mui/material'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { Select } from '@obeta/components/lib/select/Select'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import styles from './form.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import {
  isDefaultValue,
  useCreateUserContext,
} from '../../userOverviewPage/create-user/useCreateUserContext'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'

type RadioButtonLineProps = {
  value: string
  label: string
  tooltip?: string
  subLine?: ReactNode
  disabled?: boolean
}

type RadioButtonDropdownLineProps = {
  value: string
  title: string
  options: Array<{ title: string; id: string }>
  onSelect: (option: { title: string; id: string }) => void
  subLine?: ReactNode
  className?: string
  style?: CSSProperties | undefined
  disabled?: boolean
  tooltipText?: string
  openSelectViaRadiobutton?: boolean
}

export const RadioButtonLine: FC<RadioButtonLineProps> = ({
  label,
  value,
  tooltip,
  subLine,
  disabled,
}) => {
  const { desktop } = useBreakpoints()
  return (
    <div>
      <FormControlLabel
        value={value}
        control={<Radio className={styles.radioButton} />}
        disabled={disabled}
        label={
          <div className={styles.radioLine}>
            <Typography variant="bodyBold">{label}</Typography>
            {tooltip && desktop && (
              <Tooltip title={tooltip}>
                <InfoIcon className={styles.tooltipIcon} color={scssVariables.$darkGray} />
              </Tooltip>
            )}
          </div>
        }
      />
      {subLine}
    </div>
  )
}

export const RadioButtonDropdownLine: FC<RadioButtonDropdownLineProps> = ({
  value,
  title,
  options,
  onSelect,
  subLine,
  className,
  style,
  disabled,
  tooltipText,
  openSelectViaRadiobutton,
}) => {
  const createUserContext = useCreateUserContext()
  const { desktop } = useBreakpoints()
  const selectRef = useRef<HTMLDivElement | null>(null)
  const { open, onClose, handleClick, anchorEl } = usePopoverState(selectRef)

  return (
    <>
      <FormControlLabel
        style={{ marginRight: 0, ...style }}
        value={value}
        control={
          <Radio
            disabled={disabled}
            className={clsx(
              styles.radioButton,
              !desktop && styles.touch,
              !isDefaultValue(createUserContext) && styles.createUserContextRadioDropdown
            )}
            onClick={(event) => {
              if (openSelectViaRadiobutton) {
                handleClick(event)
              }
            }}
          />
        }
        classes={{
          root: className,
        }}
        label={
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Select
              ref={selectRef}
              controlledPopoverState={{ open, onClose, handleClick, anchorEl }}
              disabled={disabled}
              selectedOption={{
                title,
                id: 'placeholderLabelOnly',
              }}
              options={options}
              onOptionSelected={onSelect}
              rootClassName={styles.cartSelect}
              selectedOptionClassName={styles.fullWidth}
              menuClasses={{ menuList: clsx(styles.menuList) }}
              paperClassname={clsx(styles.dropdown, styles.dropdownPaper)}
            />
            {desktop && tooltipText && tooltipText.length > 0 && (
              <Tooltip title={tooltipText}>
                <InfoIcon className={styles.tooltipIcon} color={scssVariables.$darkGray} />
              </Tooltip>
            )}
          </div>
        }
      />
      {subLine}
    </>
  )
}
