import { PageBackgroundWrapper } from '@obeta/components/lib/page-background-wrapper/PageBackgroundWrapper'
import { PageElementsProvider } from '@obeta/components/lib/page-elements/PageElements'
import { PageMarginWrapper } from '@obeta/components/lib/page-margin-wrapper/PageMarginWrapper'
import { PullToRefreshContent, PullToRefreshProvider } from '@obeta/components/lib/pull-to-refresh'
import { ResponsiveHeader } from '@obeta/components/lib/responsive-header'
import { ReactRouterParamsProvider, useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useRef, useState } from 'react'
import { PageContent } from '../../page-content/PageContent'
import styles from './Content.module.scss'

const LEGACY_PAGES = ['/carts/import', '/message-center']

const checkLegacyPage = (path: string) => LEGACY_PAGES.indexOf(path) !== -1

const getRoutePathname = (path: string) => (path === '/start' ? ShopRoutes.Root : path)

export const WrappedContentRoutes = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const { location } = useHistory()

  const ROUTE_PATHNAME = getRoutePathname(location.pathname)

  const isLegacyPage = checkLegacyPage(ROUTE_PATHNAME)

  const [ptrContainerElement, setPtrContainerElement] = useState<HTMLElement | null>(null)

  return (
    <ReactRouterParamsProvider>
      {/* The responsive header uses the useParams hook, so it needs to be wrapped in the ReactRouterParamsProvider as well. */}
      <ResponsiveHeader headerRef={headerRef} />
      <div className={styles.contentWrapper}>
        <div ref={setPtrContainerElement} className={styles.pageWrapper}>
          {!isLegacyPage ? (
            <PullToRefreshProvider containerElement={ptrContainerElement}>
              <PageBackgroundWrapper path={ROUTE_PATHNAME}>
                <div className={styles.root}>
                  <PageMarginWrapper pageRoute={ROUTE_PATHNAME}>
                    <PageContent>
                      <PullToRefreshContent />
                      <PageElementsProvider pageRoute={ROUTE_PATHNAME} headerElementRef={headerRef}>
                        {children}
                      </PageElementsProvider>
                    </PageContent>
                  </PageMarginWrapper>
                </div>
              </PageBackgroundWrapper>
            </PullToRefreshProvider>
          ) : (
            children
          )}
        </div>
      </div>
    </ReactRouterParamsProvider>
  )
}
