import { GetUserSubusersQuery } from '@obeta/schema'

export const PERMISSION_STATUS_ALL = 'GRANT_ALL_PERMISSIONS'
export const PERMISSION_STATUS_NONE = 'GRANT_NO_PERMISSIONS'
export const PERMISSION_STATUS_CUSTOM = 'USER_CUSTOMIZED'
export const PERMISSION_STATUS_COPIED = 'USER_COPIED'

export type PERMISSION_STATUS =
  | typeof PERMISSION_STATUS_ALL
  | typeof PERMISSION_STATUS_NONE
  | typeof PERMISSION_STATUS_CUSTOM
  | typeof PERMISSION_STATUS_COPIED

type Permissions = GetUserSubusersQuery['getUserSubusers'][number]['permissions']
export const getPermissionsStatus = (
  permissions: Permissions,
  forcedCustomStatus: boolean,
  copiedCustomStatus: boolean
): PERMISSION_STATUS => {
  if (forcedCustomStatus) return PERMISSION_STATUS_CUSTOM
  if (copiedCustomStatus) return PERMISSION_STATUS_COPIED
  const permissionKeys = Object.keys(permissions)
  if (
    permissionKeys
      .filter((k) => k !== 'Cart_requiredCommission')
      .every((key) => typeof permissions[key] !== 'boolean' || Boolean(permissions[key]))
  )
    return PERMISSION_STATUS_ALL
  if (permissionKeys.some((key) => permissions[key] === true)) return PERMISSION_STATUS_CUSTOM
  return PERMISSION_STATUS_NONE
}
