import React, { useContext, useState } from 'react'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import styles from './cartTemplateDetailsPage.module.scss'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { CartTemplateDetailsContext } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'
import { LazyList } from '@obeta/components/lib/templates/details/LazyList'
import { MaybeCompleteCartTemplateDetailsItem } from '@obeta/models/lib/schema-models/cart-template-details'
import {
  ShoppingCartForDropdown,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { CartTemplateDetailsSelectionBar } from '@obeta/components/lib/templates/CartTemplateDetailsSelectionBar'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useDispatch } from 'react-redux'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import {
  deleteCartTemplateItemsGraphQLBatch,
  updateCartTemplateItemAmountByIdGraphQL,
} from '@obeta/data/lib/actions/template-actions'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { OfferV2 } from '@obeta/models/lib/models'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import { CartTemplateDetailsHeader } from '@obeta/components/lib/templates/CartTemplateDetailsHeader'

interface Props {
  hasEditingRights: boolean
  onDeleteTemplateItems: (itemIds: string[]) => void
  onRenameTemplate: (newName: string) => void
}

export const CartTemplateDetailsContent: React.FC<Props> = ({
  hasEditingRights,
  onDeleteTemplateItems,
  onRenameTemplate,
}) => {
  const { mobile, tabletAll } = useBreakpoints()
  const {
    state,
    limit,
    isInitiallyLoading,
    cartTemplate,
    isLoadingItems,
    searchTerm,
    selectItem,
    resetSelectedItems,
  } = useContext(CartTemplateDetailsContext)

  const history = useHistory()
  const { t } = useTranslation()
  const { user: userV2 } = useUserDataV2()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const dispatch = useDispatch()
  const defaultCartId = userV2?.settings?.defaultCartId || ''
  const defaultCart = carts.find((cart) => cart.id === defaultCartId)
  const [showEmptyCartTemplateConfirm, setShowEmptyCartTemplateConfirm] = useState<boolean>(false)

  const shoppingCartsToMoveCartItemsTo: ShoppingCartForDropdown[] = []
  const offersV2 = useEntities<OfferV2>('offersv2')
  const cartsV2 = useCartsv2WithPricesAndStock()
  cartsV2.forEach((cartV2) => {
    let offerName = ''
    if (cartV2.offerId !== '') {
      offerName = offersV2.find((offer) => offer.id === cartV2.offerId)?.offerName ?? ''
    }
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: cartV2.id,
      name: cartV2.name,
      articleCount: cartV2.items.length,
      offerId: cartV2.offerId,
      offerName: offerName,
      count: cartV2.items.length,
    }
    shoppingCartsToMoveCartItemsTo.push(shoppingCartForDropdown)
  })

  const skeletonItems = (
    <Grid container>
      {new Array(limit).fill(null).map((_, index) => {
        /* insert cart item here - current solution is temporary => 204 height is just for desktop */
        return <TextSkeleton height={204} className={styles.skeletonItem} key={index} />
      })}
    </Grid>
  )
  if (isInitiallyLoading) {
    return (
      <>
        <Grid className={styles.appBarWrapper}>
          <AppBar color={'transparent'} elevation={0} className={styles.header} position={'static'}>
            <Toolbar variant={'dense'} className={styles.toolbar}>
              <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
                {cartTemplate.name} {`(${cartTemplate.itemCount})`}
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        <Box className={styles.skeletonSearchBar}>
          <TextSkeleton />
        </Box>
        <Box className={styles.skeletonFilters}>
          <TextSkeleton />
        </Box>
        {skeletonItems}
      </>
    )
  }
  const changeProductAmount = (id: string, amount: number) => {
    dispatch(updateCartTemplateItemAmountByIdGraphQL(cartTemplate.id, id, amount))
  }

  const deleteSingleCartTemplateItem = (cartTemplateItem: MaybeCompleteCartTemplateDetailsItem) => {
    onDeleteTemplateItems([cartTemplateItem.id])
  }

  const deleteViaSelectionBar = () => {
    onDeleteTemplateItems([])
    resetSelectedItems()
  }

  const emptyCartTemplateAndRedirect = () => {
    resetSelectedItems()
    dispatch(
      deleteCartTemplateItemsGraphQLBatch(cartTemplate.id, cartTemplate.name, [], [], {
        searchTerm: '',
        filter: '',
        orderBy: '',
        orderDir: '',
      })
    )
    // Redirect to list page
    history.push('/cart-template-list')
  }

  const handleConfirmEmptyCartTemplate = () => {
    emptyCartTemplateAndRedirect()
    setShowEmptyCartTemplateConfirm(false)
  }

  const handleCancelEmptyCartTemplate = () => {
    setShowEmptyCartTemplateConfirm(false)
  }

  const updateIndividualSelectedArticles = (
    cartTemplateItem: MaybeCompleteCartTemplateDetailsItem
  ) => {
    selectItem(cartTemplateItem.id)
  }

  return (
    <Grid className={styles.appBarWrapper}>
      <CartTemplateDetailsHeader
        onRenameTemplate={onRenameTemplate}
        hasEditingRights={hasEditingRights}
      />
      {!isLoadingItems &&
        cartTemplate.cartTemplateItems?.resultsCount === 0 &&
        searchTerm.length > 0 && <NoResults searchTerm={searchTerm} />}
      <LazyList
        key={state.searchTerm}
        changeProductAmount={changeProductAmount}
        deleteSingleCartTemplateItem={deleteSingleCartTemplateItem}
        updateIndividualSelectedArticles={updateIndividualSelectedArticles}
        mobile={mobile}
        tabletAll={tabletAll}
        carts={carts}
        selectedCart={defaultCart}
      />
      <CartTemplateDetailsSelectionBar
        carts={carts}
        deleteCartTemplateItems={deleteViaSelectionBar}
        dropDownCarts={shoppingCartsToMoveCartItemsTo}
        cartTemplateId={cartTemplate.id}
        cartTemplateName={cartTemplate.name}
      />
      <Confirm
        heading={t('TEMPLATES.DETAILS_ACTIONS.EMPTY_TEMPLATE')}
        body={t('TEMPLATES.DETAILS_ACTIONS.BODY_CONFIRM')}
        handleConfirm={handleConfirmEmptyCartTemplate}
        handleCancel={handleCancelEmptyCartTemplate}
        openConfirmDialog={showEmptyCartTemplateConfirm}
        changesInCart={false}
      />
    </Grid>
  )
}
