import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SaveChangesBar.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { DarkButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { SelectionBar } from '@obeta/components/lib/selection-bar/SelectionBar'

interface ISaveChangesBar {
  portalKey: string
  onSaveChanges: () => void
  restoreOriginalSettings: () => void
  error?: boolean
  hasChanges?: boolean
  disabled?: boolean
}

export const SaveChangesBar: FC<ISaveChangesBar> = (props) => {
  const { desktop } = useBreakpoints()
  const { t } = useTranslation()
  const { portalKey, onSaveChanges, error, hasChanges, restoreOriginalSettings, disabled } = props

  return (
    <SelectionBar portalKey={portalKey} className={styles.selectionBar}>
      <div className={styles.container}>
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          disabled={!hasChanges || disabled}
          onClick={restoreOriginalSettings}
        >
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CANCEL')}
        </TertiaryButton>
        <DarkButton onClick={onSaveChanges} disabled={error || !hasChanges || disabled}>
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.SAVE_CHANGES')}
        </DarkButton>
      </div>
    </SelectionBar>
  )
}
