import React from 'react'
import styles from './ShoppingCartCheckoutHeader.module.scss'
import {
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as LocationIcon } from 'assets/icon/designsystem/location_on.svg'
import { ReactComponent as TruckIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { useTranslation } from 'react-i18next'
import { ShoppingCartContextEnum } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { clsx } from 'clsx'
import { ContentMask } from './ContentMask'

interface Props {
  context: ShoppingCartContextEnum
  setCheckoutContext: (context: ShoppingCartContextEnum) => void
  isDeactivated?: boolean
}

export const ShoppingCartCheckoutHeader: React.FC<Props> = (props) => {
  const { context, setCheckoutContext, isDeactivated } = props
  const { t } = useTranslation()

  const isDelivery = context === ShoppingCartContextEnum.CHECKOUT_DELIVERY

  const handleToggle = (e) => {
    setCheckoutContext(parseInt(e.currentTarget.value))
  }

  return (
    <div>
      <FormControl className={styles.formControl}>
        <RadioGroup value={context}>
          <Grid container direction={'row'} spacing={{ xs: 0.75, lg: 2 }}>
            <Grid size={{ xs: 6 }}>
              <CardActionArea>
                {isDeactivated && <ContentMask />}
                <Card
                  elevation={!isDelivery ? 2 : 0}
                  className={clsx(!isDelivery ? styles.selected : styles.unselected)}
                  sx={{
                    pb: 0,
                    '& .MuiCardContent-root:last-child': {
                      pb: 0,
                      pt: 0,
                      pr: 0,
                    },
                  }}
                >
                  <CardContent>
                    <FormControlLabel
                      data-testid="checkout-pickup-button-label"
                      className={styles.formControlLabel}
                      value={ShoppingCartContextEnum.CHECKOUT_PICKUP}
                      control={<Radio />}
                      label={
                        <Typography
                          variant={'bodyBold'}
                          color={'text.primary'}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <LocationIcon style={{ paddingRight: '0.25rem' }} />
                          {t('SHOPPING_CART.CHECKOUT.PICKUP')}
                        </Typography>
                      }
                      onChange={handleToggle}
                    />
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <CardActionArea>
                {isDeactivated && <ContentMask />}
                <Card
                  elevation={isDelivery ? 2 : 0}
                  className={clsx(isDelivery ? styles.selected : styles.unselected)}
                  sx={{
                    pb: 0,
                    '& .MuiCardContent-root:last-child': {
                      pb: 0,
                      pt: 0,
                      pr: 0,
                    },
                  }}
                >
                  <CardContent>
                    <FormControlLabel
                      data-testid="checkout-delivery-button-label"
                      className={styles.formControlLabel}
                      value={ShoppingCartContextEnum.CHECKOUT_DELIVERY}
                      control={<Radio />}
                      label={
                        <Typography
                          variant={'bodyBold'}
                          color={'text.primary'}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <TruckIcon style={{ marginRight: '0.25rem' }} />
                          {t('SHOPPING_CART.CHECKOUT.DELIVERY')}
                        </Typography>
                      }
                      onChange={handleToggle}
                    />
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
