import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Typography,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { useTranslation } from 'react-i18next'
import confirmStyles from './Confirm.module.scss'
import styles from './ConfirmWithOfferCancellationReasonSelect.module.scss'
import { SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'
import clsx from 'clsx'

export type AcceptedOfferCancellationReasonStrings = '' | '13' | '20' | '21' | '32'

type OfferCancellationReason = {
  id: AcceptedOfferCancellationReasonStrings
  reason: string
}

interface Props {
  handleConfirm: (reason: AcceptedOfferCancellationReasonStrings) => void
  handleClose: () => void
  handleAbort: () => void
  openDialog: boolean
}
export const ConfirmWithOfferCancellationReasonSelect: React.FC<Props> = (props) => {
  const { handleConfirm, handleAbort, handleClose, openDialog } = props
  const { t } = useTranslation()

  const offerCancellationReasonsForSelect: OfferCancellationReason[] = [
    { id: '13', reason: t('OFFERS.CANCELLATION.REASONS.NON_PARTICIPATION') },
    { id: '20', reason: t('OFFERS.CANCELLATION.REASONS.JOB_DENIED') },
    { id: '21', reason: t('OFFERS.CANCELLATION.REASONS.CHANGE_OF_MIND') },
    { id: '32', reason: t('OFFERS.CANCELLATION.REASONS.PROJECT_NOT_REALISED') },
  ]

  const [chosenOfferCancellationReason, setChosenOfferCancellationReason] =
    useState<OfferCancellationReason>({
      id: '',
      reason: t('OFFERS.CANCELLATION.CHOOSE_REASON'),
    })
  // affects text and layout of dialog box
  const [isSubmitted, setIsSubmitted] = useState(false)
  const handleOfferCancellationReasonChosen = (event: SelectChangeEvent) => {
    const currentReason = offerCancellationReasonsForSelect.find(
      (cancellationReason) => cancellationReason.id === event.target.value
    )
    setChosenOfferCancellationReason(currentReason ?? offerCancellationReasonsForSelect[0])
  }

  const resetOfferCancellationReason = () => {
    setChosenOfferCancellationReason({
      id: '',
      reason: t('OFFERS.CANCELLATION.CHOOSE_REASON'),
    })
  }

  const handleConfirmButtonClick = () => {
    if (!isSubmitted) {
      setIsSubmitted(true)
      handleConfirm(chosenOfferCancellationReason.id)
      resetOfferCancellationReason()
    } else {
      handleClose() // closes dialog, trigger refetching of offers (and redirect to list page)
      setIsSubmitted(false) // changes text and layout
      resetOfferCancellationReason()
    }
  }

  const handleAbortButtonClick = () => {
    handleAbort()
    setIsSubmitted(false)
    resetOfferCancellationReason()
  }

  return (
    <Dialog
      className={clsx(isSubmitted ? styles.dialogSubmitted : styles.dialog)}
      open={openDialog}
      onClose={handleAbortButtonClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={confirmStyles.dialogTitle}>
        {isSubmitted ? (
          <>
            <Typography variant={'bodyBold'}>
              {t('OFFERS.CANCELLATION.SUCCESSFULLY_CANCELLED')}
            </Typography>
            <br />
            <Typography variant={'bodyBold'}>
              {t('OFFERS.CANCELLATION.THANKS_FOR_YOUR_FEEDBACK')}
            </Typography>
          </>
        ) : (
          <Typography variant={'bodyBold'}> {t('OFFERS.CANCELLATION.CANCEL_OFFER')}</Typography>
        )}
      </DialogTitle>
      {!isSubmitted && (
        <DialogContent className={confirmStyles.dialogContent}>
          <Typography variant={'body'} color={'secondary'}>
            {t('OFFERS.CANCELLATION.ARE_YOU_SURE')}
          </Typography>
        </DialogContent>
      )}
      {!isSubmitted && (
        <Select
          onChange={handleOfferCancellationReasonChosen}
          displayEmpty={true}
          value={chosenOfferCancellationReason.id}
          renderValue={
            chosenOfferCancellationReason.id !== ''
              ? undefined
              : () => (
                  <Typography variant="body">{t('OFFERS.CANCELLATION.CHOOSE_REASON')}</Typography>
                )
          }
          className={styles.select}
        >
          {offerCancellationReasonsForSelect.map((offerCancellationReason) => {
            return (
              <MenuItem
                key={offerCancellationReason.id}
                value={offerCancellationReason.id}
                selected={offerCancellationReason.id === chosenOfferCancellationReason?.id}
              >
                <Typography variant={'body'} noWrap>
                  {offerCancellationReason.reason}
                </Typography>
              </MenuItem>
            )
          })}
        </Select>
      )}
      <Divider variant="middle" className={confirmStyles.divider} />
      {isSubmitted ? (
        <DialogActions
          className={clsx(confirmStyles.dialogActions, isSubmitted && styles.submitted)}
        >
          <SecondaryButton
            fullWidth={false}
            leftIcon={<SvgIcon component={CheckIcon} color={'success'} />}
            iconColor={'success'}
            onClick={handleConfirmButtonClick}
            autoFocus
          >
            {t('OFFERS.CANCELLATION.CONFIRM')}
          </SecondaryButton>
        </DialogActions>
      ) : (
        <DialogActions className={confirmStyles.dialogActions}>
          <TertiaryButton
            fullWidth={false}
            leftIcon={<SvgIcon component={CloseIcon} />}
            iconColor={'primary'}
            onClick={handleAbortButtonClick}
          >
            {t('OFFERS.CANCELLATION.ABORT')}
          </TertiaryButton>
          <SecondaryButton
            disabled={chosenOfferCancellationReason.id === ''}
            fullWidth={false}
            leftIcon={<SvgIcon component={CheckIcon} />}
            iconColor={'success'}
            onClick={handleConfirmButtonClick}
            autoFocus
          >
            {t('OFFERS.CANCELLATION.CANCEL')}
          </SecondaryButton>
        </DialogActions>
      )}
    </Dialog>
  )
}
