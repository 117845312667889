import React, { useState } from 'react'
import { Button, PopoverOrigin, SvgIcon, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as TrashIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { useTranslation } from 'react-i18next'
import {
  ShoppingCartActionTypeEnum,
  ShoppingCartForDropdown,
  MoveCartItemsOfferIdUpdateEnum,
} from '@obeta/models/lib/models/ShoppingCart'
import { ConfirmWithOfferSelect } from '../alert-and-confirm/ConfirmWithOfferSelect'
import { OfferForDropdown } from '@obeta/models/lib/models/Offer/OfferV2'
import styles from './ShoppingCartDetailsSelectionBarEdit.module.scss'
import {
  DropdownTemplates,
  IDropdownTemplateBaseProps,
} from '../dropdown-templates/DropdownTemplates'
import { DropdownCarts } from '../dropdown-carts/DropdownCarts'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { SelectionBar } from '../selection-bar/SelectionBar'

interface Props {
  productsToAdd: IDropdownTemplateBaseProps['productsToAdd']
  cartData: ShoppingCartForDropdown[]
  deleteCartItems: () => void
  disableEditingMode: () => void
  handleMoveItems: (targetCartId: string, MoveCartItemOfferIdUpdateEnum, offerId?: string) => void
  currentCartOfferId: string
  currentCartOfferName: string
  selectedArticleCount: number
  setActionType: (ShoppingCartActionTypeEnum) => void
  undoHandler: () => void
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

export const ShoppingCartDetailsSelectionBarEdit: React.FC<Props> = (props) => {
  const [showCartItemMoveConfirmationBox, setShowCartItemMoveConfirmationBox] = useState(false)
  const theme = useTheme()

  const { mobile, tabletAll, tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()

  const {
    productsToAdd,
    cartData,
    deleteCartItems,
    disableEditingMode,
    handleMoveItems,
    currentCartOfferId,
    currentCartOfferName,
    selectedArticleCount,
    setActionType,
  } = props

  const [targetCart, setTargetCart] = useState<ShoppingCartForDropdown>()
  const [offersForDropdown, setOffersForDropdown] = useState<OfferForDropdown[]>([])

  const handleCartMoveDialogBoxConfirmed = (offer: OfferForDropdown) => {
    // targetCart.id could also be undefined before.
    const targetCartId = targetCart?.id ?? ''
    setActionType(ShoppingCartActionTypeEnum.MOVE_TO_ANOTHER_CART)
    if (offer.id === currentCartOfferId) {
      // Angebot aus Ursprungswarenkorb gewählt
      handleMoveItems(
        targetCartId,
        MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIdsAndUpdateTargetOfferId,
        offer.id
      )
    } else {
      // Angebot aus Zielwarenkorb gewählt
      handleMoveItems(targetCartId, MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIds, offer.id)
    }
    setShowCartItemMoveConfirmationBox(false)
  }
  const handleCartMoveDialogBoxCancelled = () => {
    setShowCartItemMoveConfirmationBox(false)
  }

  const disabled = selectedArticleCount === 0

  return (
    <SelectionBar portalKey="shopping-cart-details-selection-bar-edit">
      <div className={styles.content}>
        <Grid container size={{ xs: 8, md: 9 }}>
          <Grid className={styles.actionButtonsGrid} size={{ xs: 12 }}>
            <DropdownTemplates
              anchorOrigin={anchorOrigin}
              desktop={desktop}
              disabled={disabled}
              mobile={mobile}
              tablet={tabletAll}
              productsToAdd={productsToAdd}
              selectedArticleCount={selectedArticleCount}
              templatesType={DropdownTemplatesType.ADD_ARTICLES}
              transformOrigin={transformOrigin}
              withZeroBasedArticleCount={true}
            />
            <DropdownCarts
              desktop={desktop}
              tablet={tabletAll}
              mobile={mobile}
              carts={cartData}
              disabled={disabled}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              selectedArticleCount={selectedArticleCount}
              /*TODO Insert required prop dropdown*/
              onOptionSelected={(targetCart) => {
                setTargetCart(targetCart)

                //Start WK kein Angebot - Ziel WK kein Angebot - passiert nichts, keine Info
                if (currentCartOfferId === '' && targetCart.offerId === '') {
                  handleMoveItems(targetCart.id, MoveCartItemsOfferIdUpdateEnum.NoUpdates)
                }
                // Start WK hat ein Angebot - Ziel WK kein  Angebot - Angebot von Start WK wird übernommen, es gibt keinen extra Hinweis
                else if (currentCartOfferId !== '' && targetCart.offerId === '') {
                  handleMoveItems(
                    targetCart.id,
                    MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIdsAndUpdateTargetOfferId,
                    currentCartOfferId
                  )
                }
                // Start WK kein  Angebot -  Ziel WK hat ein  Angebot - Angebot von Ziel WK wird übernommen, es gibt keinen extra Hinweis
                else if (currentCartOfferId === '' && targetCart.offerId !== '') {
                  handleMoveItems(
                    targetCart.id,
                    MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIds,
                    targetCart.offerId
                  )
                } else {
                  // beide Warenkoerbe haben dasselbe Angebot, es gibt keinen extra Hinweis
                  if (currentCartOfferId === targetCart.offerId) {
                    handleMoveItems(targetCart.id, MoveCartItemsOfferIdUpdateEnum.NoUpdates)
                  }
                  // Start WK ein Angebot - Ziel WK ein ANDERES Angebot - Kunde entscheidet welches Angebot er will
                  else {
                    setOffersForDropdown([
                      { id: currentCartOfferId, name: currentCartOfferName },
                      { id: targetCart.offerId ?? '', name: targetCart.offerName ?? '' },
                    ])
                    setShowCartItemMoveConfirmationBox(true)
                  }
                }
              }}
            />

            <Button
              variant={'tertiary'}
              fullWidth={false}
              size={'small'}
              startIcon={<SvgIcon component={TrashIcon} />}
              onClick={deleteCartItems}
              className={styles.deleteButton}
              disabled={selectedArticleCount === 0}
            >
              {(desktop || tabletAll) &&
                t('SHOPPING_CART.ACTIONS.DELETE_desktop', { selectedArticleCount })}
              {mobile && `(${selectedArticleCount})`}
            </Button>
            <ConfirmWithOfferSelect
              handleConfirm={handleCartMoveDialogBoxConfirmed}
              handleCancel={handleCartMoveDialogBoxCancelled}
              offersForSelect={offersForDropdown}
              openDialog={showCartItemMoveConfirmationBox}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" size={{ xs: 4, md: 3 }}>
          <Button
            type="submit"
            fullWidth={false}
            variant="contained"
            color="secondary"
            startIcon={<SvgIcon htmlColor={theme.palette.white.main} component={CloseIcon} />}
            onClick={() => disableEditingMode()}
            className={styles.submitButton}
          >
            {(tabletWide || desktop) && t('SHOPPING_CART.COMPLETE_CART_EDIT')}
          </Button>
        </Grid>
      </div>
    </SelectionBar>
  )
}
