import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ReactComponent as PersonAdd } from 'assets/icon/designsystem/person_add.svg'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import { UserOverviewProvider } from '@obeta/data/lib/stores/useUserOverviewContext'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import { withProvider } from '@obeta/data/lib/providers/withProvider'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { Feedback } from '@obeta/components/lib/feedback/Feedback'
import { CreateUserDialog } from './create-user/CreateUserDialog'
import { CreateUserProvider } from './create-user/useCreateUserContext'
import { UserOverviewTable } from './UserOverviewTable'
import { UserManagementSkeleton } from '../userManagementPage/UserManagementSkeleton'
import styles from './UserOverviewPage.module.scss'

export const UserOverviewPage: FC = withProvider(() => {
  const { t } = useTranslation()
  const { desktop, mobile } = useBreakpoints()
  const {
    sortedItems: users,
    setSearchText,
    searchText,
    loading,
    refetchUsers,
    unfilteredUsers,
  } = useUserOverviewTableData()
  const [isNewUserDialogShown, setIsNewUserDialogShown] = useState(false)
  const { user } = useUserDataV2()
  const loggedInUser = unfilteredUsers.find(
    (subuser) => subuser.customerNumber === user?.customerNumber
  )

  if (loading) return <UserManagementSkeleton />

  return (
    <>
      {isNewUserDialogShown && (
        <CreateUserProvider>
          <CreateUserDialog
            open={isNewUserDialogShown}
            onClose={() => setIsNewUserDialogShown(false)}
            refetchUsers={refetchUsers}
          />
        </CreateUserProvider>
      )}
      <div>
        <div className={styles.simpleFlexContainer}>
          <Typography className={styles.marBottomOneRem} variant="h3" color="text.primary">
            {t('USER_MANAGEMENT.USER_OVERVIEW')}
          </Typography>

          <TertiaryButton
            leftIcon={<PersonAdd />}
            onClick={() => setIsNewUserDialogShown(true)}
            size={desktop ? 'small' : 'large'}
          >
            {!mobile && t('USER_MANAGEMENT.CREATE_NEW_USER')}
          </TertiaryButton>
        </div>

        <div className={styles.searchWrapper}>
          <SearchField
            id="searchBox"
            placeholder={t('USER_MANAGEMENT.SEARCH_FIELD_PLACE_HOLDER')}
            value={searchText}
            onChange={setSearchText}
            onReset={() => setSearchText('')}
          />
        </div>

        <UserOverviewTable users={users} loggedInUser={loggedInUser} />
      </div>
      <Feedback id="user-management-feedback" title={t('USER_MANAGEMENT.FEEDBACK')} />
    </>
  )
}, UserOverviewProvider)
