import React, { forwardRef, useCallback } from 'react'
import { Box, ClickAwayListener, Paper, Popper, SxProps, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as KeyboardArrowDown } from '@obeta/assets/icon/designsystem/keyboard_arrow_down.svg'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useWindowScroll } from '@obeta/data/lib/hooks/useWindowEvent'
import styles from './Select.module.scss'
import { DropdownMenu, IMenuClasses, IRefProps, PopoverDropdownMenu } from '../dropdown-button'
import { SelectPopover as SelectPopoverMobile } from './SelectPopover.mobile'

export interface BaseOption {
  id: string | number
  title: string | number
}

interface ISelectProps<Option extends BaseOption> {
  label?: string
  selectedOption?: Option
  options: Option[]
  onOptionSelected: (option: Option) => void
  keepMounted?: boolean
  paperSx?: SxProps<Theme>
  rootClassName?: string
  sortBy?: boolean
  showPopoverFromBottom?: boolean
  usePopperOverPopover?: boolean
  closeOnSelect?: boolean
  borderless?: boolean
  menuClasses?: IMenuClasses & { paper?: string }
  paperClassname?: string
  selectedOptionClassName?: string
  disabled?: boolean
  controlledPopoverState?: {
    open: boolean
    onClose: () => void
    handleClick: (event: React.MouseEvent<HTMLElement>) => void
    anchorEl: Element | null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Select = forwardRef<HTMLDivElement, ISelectProps<any> & IRefProps>(
  <Option extends BaseOption>(
    props: ISelectProps<Option> & IRefProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {
      label,
      selectedOption,
      options,
      onOptionSelected,
      paperSx,
      rootClassName,
      sortBy,
      borderless,
      showPopoverFromBottom,
      usePopperOverPopover,
      closeOnSelect = true,
      menuClasses,
      paperClassname,
      selectedOptionClassName,
      disabled,
      controlledPopoverState,
      ...restProps
    } = props
    const defaultPopoverState = usePopoverState()
    const { open, onClose, handleClick, anchorEl } = controlledPopoverState ?? defaultPopoverState
    // Close select on window scroll
    useWindowScroll(() => {
      open && !showPopoverFromBottom && onClose()
    }, [open, showPopoverFromBottom])

    const onSelected = useCallback(
      (option) => {
        onOptionSelected(option)
        if (closeOnSelect) {
          onClose()
        }
      },
      [closeOnSelect, onClose, onOptionSelected]
    )

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={clsx(styles.root, rootClassName)}
      >
        {label && (
          <Typography noWrap typography="body">
            {label}
          </Typography>
        )}
        <Paper
          sx={paperSx}
          className={clsx(
            styles.paper,
            paperClassname,
            sortBy && styles.paperSortBy,
            borderless && styles.borderless
          )}
          ref={ref}
          onClick={(event) => {
            if (!disabled) {
              handleClick(event)
            }
          }}
        >
          <Typography
            className={clsx(!sortBy && styles.selected, selectedOptionClassName)}
            noWrap
            variant="bodyBold"
          >
            {selectedOption?.title || ''}
          </Typography>
          <KeyboardArrowDown className={styles.icon} height="1.25rem" width="1.25rem" />
        </Paper>
        {showPopoverFromBottom ? (
          <SelectPopoverMobile
            onOptionSelected={onSelected}
            onClose={onClose}
            open={open}
            options={options}
            selectedOption={selectedOption}
          />
        ) : usePopperOverPopover ? (
          <Popper sx={{ zIndex: 10 }} open={open} anchorEl={anchorEl} placement={'bottom-start'}>
            <ClickAwayListener onClickAway={onClose}>
              <Paper>
                <DropdownMenu
                  options={options}
                  formatOption={(opt) => {
                    return (
                      <Typography variant={selectedOption?.id === opt.id ? 'bodyBold' : 'body'}>
                        {opt.title}
                      </Typography>
                    )
                  }}
                  onOptionSelected={onSelected}
                  {...restProps}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : (
          <PopoverDropdownMenu
            {...restProps}
            paperRef={restProps.paperRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            menuClasses={menuClasses}
            options={options}
            formatOption={(opt) => {
              return (
                <Typography variant={selectedOption?.id === opt.id ? 'bodyBold' : 'body'}>
                  {opt.title}
                </Typography>
              )
            }}
            onOptionSelected={(option) => {
              onOptionSelected(option)
              if (closeOnSelect) {
                onClose()
              }
            }}
          />
        )}
      </Box>
    )
  }
)
