import { useEventCallback } from '@mui/material/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PullToRefreshContext, PullToRefreshContextValue } from './context'
import { PullToRefreshContainer } from './PullToRefreshContainer'
import { usePullToRefreshStore } from './use-pull-to-refresh-store'
import { PullToRefreshStoreValue } from './store'

export interface PullToRefreshProviderProps {
  containerElement: HTMLElement | null
  scrollElement?: HTMLElement | undefined
  children: React.ReactNode
}

export function PullToRefreshProvider(props: PullToRefreshProviderProps) {
  const { containerElement, children } = props

  const getScrollElement = useEventCallback(() => {
    return props.scrollElement ?? document.scrollingElement
  })

  const [refreshHandler, setRefreshHandler] = useState<(() => Promise<void>) | null>(null)

  const latestRefreshHandlerRef = useRef(refreshHandler)

  useEffect(() => {
    latestRefreshHandlerRef.current = refreshHandler
  }, [refreshHandler])

  const enabled = refreshHandler !== null

  const store = usePullToRefreshStore()

  const registerRefreshHandler = useCallback(
    (handler: () => Promise<void>) => {
      function assignHandler(handler: (() => Promise<void>) | null) {
        setRefreshHandler(() => handler)
        latestRefreshHandlerRef.current = handler
        store.reset()
      }

      if (latestRefreshHandlerRef.current) {
        if (process.env.NODE_ENV !== 'production') {
          throw new Error(
            'Cannot register multiple refresh handlers for PullToRefresh at the same time'
          )
        }
      } else {
        assignHandler(handler)
      }

      return () => {
        assignHandler(null)
      }
    },
    [store]
  )

  useEffect(() => {
    if (!enabled || !containerElement) {
      return
    }

    function findTouch(touchList: TouchList, identifier: number): Touch | null {
      for (let i = 0; i < touchList.length; i++) {
        const touch = touchList.item(i)
        if (touch?.identifier === identifier) {
          return touch
        }
      }

      return null
    }

    function applyContainerStyles() {
      if (containerElement) {
        containerElement.style.pointerEvents = 'none'
        containerElement.style.userSelect = 'none'
      }
    }

    function resetContainerStyles() {
      if (containerElement) {
        containerElement.style.removeProperty('pointer-events')
        containerElement.style.removeProperty('user-select')
      }
    }

    function handleTouchStart(event: TouchEvent) {
      if (event.touches.length > 1) {
        if (canInterruptPull(store.getState())) {
          store.reset()
        }
        return
      }

      if (store.getState().state !== 'idle') {
        return
      }

      const scrollingElement = getScrollElement()

      if (!scrollingElement) return
      if (scrollingElement.scrollTop !== 0) return

      const touch = event.touches[0]
      const touchId = touch.identifier

      store.setState({ state: 'listening', touchId: touchId, initialY: touch.clientY, progress: 0 })

      applyContainerStyles()
    }

    function handleTouchMove(event: TouchEvent) {
      const state = store.getState()

      const touch = findTouch(event.changedTouches, state.touchId)

      if (!touch) {
        return
      }

      const threshold = 100
      const distance = touch.clientY - state.initialY
      const progress = calculateProgress(distance, threshold)

      switch (state.state) {
        case 'listening': {
          // If starting to pull down, initialize the pull-to-refresh and
          // prevent default scroll behavior
          if (distance < 0) {
            store.reset()

            resetContainerStyles()
          } else {
            event.preventDefault()

            store.setState({
              ...store.getState(),
              state: 'pulling',
              progress: progress,
            })
          }

          break
        }

        case 'pulling': {
          event.preventDefault()

          const pullState = progress < 1 ? 'pulling' : 'pulled'

          store.setState({
            ...store.getState(),
            state: pullState,
            progress: progress,
          })

          break
        }

        case 'pulled': {
          event.preventDefault()
          break
        }
      }
    }

    function handleTouchEnd(event: TouchEvent) {
      const state = store.getState()

      if (state.state === 'pulled') {
        if (latestRefreshHandlerRef.current) {
          store.setState({ ...store.getState(), state: 'refreshing' })

          // Wait for the refresh handler to finish before resetting the store.
          // (Wait for minimum of 200ms even if the refresh handler resolves
          // sooner.)

          const promises = [latestRefreshHandlerRef.current(), sleep(200)]

          Promise.allSettled(promises).then(async () => {
            store.reset()
          })
        }
      } else {
        if (canInterruptPull(store.getState())) {
          store.reset()
        }
      }

      resetContainerStyles()
    }

    containerElement.addEventListener('touchstart', handleTouchStart)
    containerElement.addEventListener('touchend', handleTouchEnd)
    containerElement.addEventListener('touchmove', handleTouchMove)

    return () => {
      resetContainerStyles()

      containerElement.removeEventListener('touchstart', handleTouchStart)
      containerElement.removeEventListener('touchend', handleTouchEnd)
      containerElement.removeEventListener('touchmove', handleTouchMove)
    }
  }, [enabled, containerElement, getScrollElement, store])

  const context = useMemo<PullToRefreshContextValue>(() => {
    return {
      enabled: enabled,
      register: registerRefreshHandler,
      store: store,
    }
  }, [enabled, registerRefreshHandler, store])

  return (
    <PullToRefreshContext.Provider value={context}>
      <PullToRefreshContainer store={store}>{children}</PullToRefreshContainer>
    </PullToRefreshContext.Provider>
  )
}

function calculateProgress(distance: number, threshold: number): number {
  const progress = distance / threshold

  return Math.min(1, Math.max(0, progress))
}

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function canInterruptPull(state: PullToRefreshStoreValue) {
  return state.state === 'idle' || state.state === 'pulling' || state.state === 'pulled'
}
