export const CART_PERMISSION_STATUS_OWN_CART = 'Own' as const
export const CART_PERMISSION_STATUS_ALL_CARTS = 'All' as const
export const CART_PERMISSION_STATUS_PROJECT_CARTS = 'Projects' as const
export type CartPermissionStatus =
  | typeof CART_PERMISSION_STATUS_OWN_CART
  | typeof CART_PERMISSION_STATUS_ALL_CARTS
  | typeof CART_PERMISSION_STATUS_PROJECT_CARTS

export const CART_ORDERABLE_COMPLETE_RANGE = 'COMPLETE_RANGE' as const
export const CART_ORDERABLE_PRODUCT_RANGE_RESTRICTION = 'PRODUCT_RANGE_RESTRICTION' as const
export const CART_ORDERABLE_SELECTED_CART_TEMPLATES_ONLY = 'SELECTED_CART_TEMPLATES_ONLY' as const
export const CART_ORDERABLE_SELECTED_OFFERS_ONLY = 'SELECTED_CART_TEMPLAETS_ONLY' as const
export type CartOrderableStatus =
  | typeof CART_ORDERABLE_COMPLETE_RANGE
  | typeof CART_ORDERABLE_PRODUCT_RANGE_RESTRICTION
  | typeof CART_ORDERABLE_SELECTED_CART_TEMPLATES_ONLY
  | typeof CART_ORDERABLE_SELECTED_OFFERS_ONLY
