import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOpenPositionsPageData } from '@obeta/data/lib/hooks/my-accounting/useOpenPositionsPageData'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import styles from './MyAccountingPage.module.scss'
import { OpenPositionsTable } from './OpenPositionsTable'
import { OpenPositionsLoading } from './OpenPositionsLoading'
import { EmptyCard } from '@obeta/components/lib/empty-card/EmptyCard'
import { AccountInfo } from '@obeta/components/lib/account-information/AccountInfo'
import { gqlUserCreditInfo } from '@obeta/data/lib/queries/userV2CreditInfo'
import { CreditInfo } from '@obeta/models/lib/schema-models/userV2-credit-info'
import { useQuery } from '@apollo/client'
import { GetCreditInfoQuery, GetCreditInfoQueryVariables } from '@obeta/schema'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { MyAccountingProvider } from '@obeta/data/lib/stores/useMyAccountingContext'
import { OpenPositionSearch } from './OpenPositionSearch'
import { OpenPositionFilterBar } from './OpenPositionFilterBar'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { MyAccountingSelectionBar } from '@obeta/components/lib/my-accounting/MyAccountingSelectionBar'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as DescriptionIcon } from 'assets/icon/designsystem/description.svg'
import { RequestInvoicesDialog } from './RequestInvoicesDialog'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { withProvider } from '@obeta/data/lib/providers/withProvider'

export const MyAccountingPage: FC = withProvider(() => {
  const { t } = useTranslation()
  useAuthenticatedRoute()
  const {
    openPositions,
    sortedAndFilteredOpenPositions,
    status,
    onFilterItemsBySearchTerm,
    searchResults,
  } = useOpenPositionsPageData()

  const [showRequestInvoices, setShowRequestInvoices] = useState(false)
  const user = useUserDataV2()
  const { desktop, mobile } = useBreakpoints()

  const [creditInfo, setCreditInfo] = useState<CreditInfo | undefined>()
  useQuery<GetCreditInfoQuery, GetCreditInfoQueryVariables>(gqlUserCreditInfo, {
    skip: creditInfo !== undefined,
    onCompleted: (data) => {
      setCreditInfo(data.getUser.creditInfo)
    },
  })

  if (status === 'loading') return <OpenPositionsLoading />
  return (
    <>
      <div>
        <Typography className={styles.title} variant="h3" color="text.primary">
          {t('MY_ACCOUNTING.TITLE')}
        </Typography>
        <div className={styles.subTitleLine}>
          <Typography className={styles.accountId} variant="h4" color="text.primary">
            {t('MY_ACCOUNTING.ACCOUNT_ID', {
              id: user.userId?.substring(
                0,
                user.userId.indexOf('-') !== -1 ? user.userId.indexOf('-') : user.userId?.length
              ),
            })}
          </Typography>
          <TertiaryButton
            leftIcon={<DescriptionIcon />}
            onClick={() => setShowRequestInvoices(true)}
            size={desktop ? 'small' : 'large'}
          >
            {mobile ? '' : t('MY_ACCOUNTING.REQUEST_COPY_OF_INVOICE')}
          </TertiaryButton>
        </div>
        {creditInfo && <AccountInfo creditInfo={creditInfo} />}
        <LightGrayDivider className={styles.divider} />
        {status === 'dataAvailable' && (
          <div className={styles.openPositionsSection}>
            <Typography className={styles.openPositionsTitle} variant="h4" color="text.primary">
              {`${t('MY_ACCOUNTING.OPEN_POSITIONS.TITLE')} (${openPositions.length})`}
            </Typography>
            <OpenPositionSearch onSearch={onFilterItemsBySearchTerm} />
            <OpenPositionFilterBar items={searchResults} />
            <OpenPositionsTable items={sortedAndFilteredOpenPositions} />
          </div>
        )}
        {status === 'noDataAvailable' && (
          <Grid container direction={'column'} alignItems={'center'}>
            <Grid>
              <EmptyCard title={t('MY_ACCOUNTING.NO_CURRENT_OPEN_POSITIONS')} />
            </Grid>
          </Grid>
        )}
      </div>
      {status === 'dataAvailable' && (
        <MyAccountingSelectionBar
          openPositions={creditInfo?.openPositions as number}
          items={searchResults}
        />
      )}
      {showRequestInvoices && (
        <RequestInvoicesDialog
          open={showRequestInvoices}
          onClose={() => setShowRequestInvoices(false)}
        />
      )}
    </>
  )
}, MyAccountingProvider)
