import { FC } from 'react'
import { UserPermission } from '../../userManagementPage/permission/UserPermission'
import { useCreateUserContext } from './useCreateUserContext'
import { CreateUserStepContainer } from './CreateUserStepContainer'

export const CreateUserFormStepTwo: FC = () => {
  const { userToCreate, setUserToCreate, setPermissionsByStatus } = useCreateUserContext()
  return (
    <CreateUserStepContainer>
      <UserPermission
        compact
        user={userToCreate}
        updateUser={setUserToCreate}
        setAllPermissions={(value) => setPermissionsByStatus(userToCreate, value)}
        forceColumnLayout
      />
    </CreateUserStepContainer>
  )
}
