import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import { CartTemplateDetailsContent } from './CartTemplateDetailsContent'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useCartTemplateDetailsState } from '@obeta/data/lib/hooks/cart-templates/details/reducer/useCartTemplateDetailsState'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import {
  CartTemplateForDetailsPage,
  MaybeCompleteCartTemplateDetailsItem,
} from '@obeta/models/lib/schema-models/cart-template-details'
import {
  deleteCartTemplateGraphQL,
  DeleteCartTemplateGraphQLResultAction,
  deleteCartTemplateItemsGraphQLBatch,
  DeleteCartTemplateItemsGraphQLResultActionBatch,
  getCartTemplateItemProductsGraphQL,
  getCartTemplateItemsGraphQL,
  TemplateActionTypes,
  updateCartTemplateGraphQL,
} from '@obeta/data/lib/actions/template-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { CartTemplateDetailsProvider } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import styles from './cartTemplateDetailsPage.module.scss'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useUserData } from '@obeta/data/lib/hooks/useUserData'
import { isSubuser } from '@obeta/utils/lib/isSubuser'
import { buildUserId } from '@obeta/utils/lib/buildUserId'

const CartTemplateDetailsPage: React.FC = () => {
  useAuthenticatedRoute()
  const cartTemplates = useEntities<CartTemplateForDetailsPage>('carttemplates')
  const { selectedStore } = useUserSelectedStore()
  const params = useParams()

  if (!selectedStore) {
    return null
  }

  return (
    <CartTemplateDetailsContentWrapper
      cartTemplates={cartTemplates}
      templateId={params.templateId as string}
      selectedStore={selectedStore}
    />
  )
}

const CartTemplateDetailsContentWrapper: React.FC<{
  cartTemplates: CartTemplateForDetailsPage[]
  templateId: string
  selectedStore: StoreV2
}> = (props) => {
  const { templateId, cartTemplates, selectedStore } = props
  const [cartTemplate] = cartTemplates.filter((template) => template.id === templateId)
  const dispatch = useDispatch()
  const history = useHistory()
  const pageState = useCartTemplateDetailsState()
  const { t } = useTranslation()
  const {
    state,
    offset,
    limit,
    paginatedItems,
    receiveItems,
    receiveProducts,
    selectItem,
    updateCartTemplateState,
    updateCartTemplateItemsState,
    resetSelectedItems,
  } = pageState
  const didInitialProductsFetch = useRef(false)
  const userV2 = useUserV2()
  const { companyId, userId } = useUserData()

  // Component state
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showDeleteCartTemplate, setShowDeleteCartTemplate] = useState<boolean>(false)

  useEffect(() => {
    if (
      cartTemplate?.cartTemplateItems?.items &&
      cartTemplate.cartTemplateItems.items !== state.items
    ) {
      updateCartTemplateItemsState(cartTemplate.cartTemplateItems.items)
    }

    if (cartTemplate) {
      updateCartTemplateState(cartTemplate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTemplate])

  useEffect(() => {
    if (cartTemplate) {
      dispatch(
        getCartTemplateItemsGraphQL(
          cartTemplate,
          { limit, offset, searchTerm: state.searchTerm },
          state.shouldResetItems,
          receiveItems
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTemplate?.updatedAt])

  useEffect(() => {
    if (state.shouldFetchItems && cartTemplate) {
      dispatch(
        getCartTemplateItemsGraphQL(
          cartTemplate,
          { limit, offset, searchTerm: state.searchTerm },
          state.shouldResetItems,
          receiveItems
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchItems])

  useEffect(() => {
    if (state.shouldFetchProductData) {
      const storeIds = ['10', userV2?.settings?.defaultStoreId].filter(Boolean)
      // only fetch prices for items that don't have prices
      const itemsToFetch: MaybeCompleteCartTemplateDetailsItem[] = paginatedItems.filter(
        (item: MaybeCompleteCartTemplateDetailsItem) => {
          return !('prices' in item.product)
        }
      )
      dispatch(
        getCartTemplateItemProductsGraphQL(cartTemplate, itemsToFetch, storeIds, receiveProducts)
      )
      didInitialProductsFetch.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchProductData])

  const waitForDeleteResultAction = useActionNotification(
    TemplateActionTypes.DeleteCartTemplateGraphQLResult
  )

  const waitForItemsDeleteResultBatchAction = useActionNotification(
    TemplateActionTypes.DeleteCartTemplateItemsGraphQLResultBatch
  )

  if (!cartTemplate) return null

  const fullyQualifiedUserId: string =
    (userId && companyId && buildUserId({ userId, companyId, includeSubuser: true })) ?? ''
  const isOwnerOfCartTemplate = fullyQualifiedUserId === cartTemplate.ownerId
  const isMainUser = !!(userId && companyId && !isSubuser({ userId, companyId }))

  /**
   * Handler to close cart template delete confirm dialog.
   */
  const onDeleteTemplateClose = () => {
    setShowDeleteCartTemplate(false)
  }

  /**
   * Handler to submit cart template delete.
   */
  const onDeleteTemplateSubmit = () => {
    if (showDeleteCartTemplate) {
      dispatch(deleteCartTemplateGraphQL({ templateId: cartTemplate.id }, cartTemplate.name))

      // Navigates back to list after delete action was successful
      waitForDeleteResultAction((action: DeleteCartTemplateGraphQLResultAction) => {
        if (action.success) {
          history.push(ShopRoutes.TemplateList, {})
        }
        onDeleteTemplateClose()
      }, -1)
    }
  }

  const onRenameTemplate = (newName: string) => {
    dispatch(
      updateCartTemplateGraphQL(
        {
          cartTemplateId: templateId,
          patch: {
            name: newName,
          },
        },
        cartTemplate.name
      )
    )
  }

  const deleteCartTemplateItems = (ids: string[]) => {
    let productTitle = ''
    const singleItemOperation = pageState.state.selectedCartTemplateItems.include.length === 1
    const itemsInCartTemplateBeforeDeleteAction = pageState.state.cartTemplate?.itemCount

    const singleDeleteViaCard = ids.length === 1

    // via button on cart template item
    if (singleDeleteViaCard) {
      const cartTemplateItem = pageState.state.items.find((item) => {
        return item.id === ids[0]
      })

      if (cartTemplateItem) {
        productTitle = cartTemplateItem.product.title
      }
    }

    // via checkbox + selection bar
    if (singleItemOperation) {
      const cartTemplateItem = pageState.state.items.find((item) => {
        return item.id === pageState.state.selectedCartTemplateItems.include[0].toString()
      })

      if (cartTemplateItem) {
        productTitle = cartTemplateItem.product.title
      }
    }

    dispatch(
      deleteCartTemplateItemsGraphQLBatch(
        cartTemplate.id,
        cartTemplate.name,
        singleDeleteViaCard ? ids : pageState.state.selectedCartTemplateItems.include,
        singleDeleteViaCard ? [] : pageState.state.selectedCartTemplateItems.exclude,
        singleDeleteViaCard
          ? {
              filter: '',
              orderBy: '',
              orderDir: '',
              searchTerm: '',
            }
          : pageState.state.selectedCartTemplateItems.search,
        singleItemOperation || singleDeleteViaCard ? productTitle : undefined
      )
    )

    waitForItemsDeleteResultBatchAction(
      (action: DeleteCartTemplateItemsGraphQLResultActionBatch) => {
        // In case item got deleted via delete button on card, we need to ensure that it wasn't part of any include or
        // exclude lists, respectively
        if (singleDeleteViaCard) {
          const cartTemplateItemOnIncludeList =
            pageState.state.selectedCartTemplateItems.include.find(
              (includedItem) => includedItem === ids[0]
            )
          const cartTemplateItemOnExcludeList =
            pageState.state.selectedCartTemplateItems.exclude.find(
              (excludedItem) => excludedItem === ids[0]
            )

          if (cartTemplateItemOnIncludeList || cartTemplateItemOnExcludeList) {
            // selectItem = toggle, therefore remove from include or exclude list and apply any relevant business logic
            selectItem(ids[0])
          }
        }

        if (!singleDeleteViaCard) {
          if (action.success) {
            resetSelectedItems()
          }
        }

        const cartTemplateEmpty = itemsInCartTemplateBeforeDeleteAction === action.successItemCount
        // re-route to list page if last item(s) of cart deleted
        if (cartTemplateEmpty) {
          history.push(ShopRoutes.TemplateList)
        }

        const timestamp = new Date().getTime()

        if (action.success) {
          // Notification for a single cart template item --> productTitle included
          if (
            action.successItemCount === 1 &&
            (singleDeleteViaCard || singleItemOperation) &&
            productTitle
          ) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteCartTemplateItemSingle,
              id: `${state.cartTemplate?.id}-${NotificationType.DeleteCartTemplateSingle}`,
              options: {
                cartTemplateEmpty: cartTemplateEmpty,
                cartTemplateTitle: state.cartTemplate?.name ?? '',
                productTitle: productTitle,
              },
            })
          } else {
            // Notification for multiple cart template items
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteCartTemplateItems,
              id: `${NotificationType.DeleteCartTemplateItems}-${state.cartTemplate?.id}-${timestamp}`,
              options: {
                itemCount: action.successItemCount,
                cartTemplateName: state.cartTemplate?.name ?? '',
                cartTemplateEmpty: cartTemplateEmpty,
              },
            })
          }
        }
      }
    )
  }

  return (
    <CartTemplateDetailsProvider
      value={{
        ...pageState,
        isInitiallyLoading: !didInitialProductsFetch.current,
        isLoadingItems: state.shouldFetchItems,
        isLoadingProducts: state.shouldFetchProductData,
        selectedStore,
        cartTemplate,
        searchTerm,
        setSearchTerm,
      }}
    >
      <div className={styles.templateDetails}>
        <Grid container direction={'column'}>
          <CartTemplateDetailsContent
            hasEditingRights={isOwnerOfCartTemplate || isMainUser}
            onDeleteTemplateItems={deleteCartTemplateItems}
            onRenameTemplate={onRenameTemplate}
          />
          <Confirm
            body={t('TEMPLATES.DETAILS_ACTIONS.BODY_CONFIRM')}
            heading={t('TEMPLATES.DETAILS_ACTIONS.DELETE_TEMPLATE')}
            openConfirmDialog={!!showDeleteCartTemplate}
            handleCancel={onDeleteTemplateClose}
            handleConfirm={onDeleteTemplateSubmit}
          />
        </Grid>
      </div>
    </CartTemplateDetailsProvider>
  )
}

export default CartTemplateDetailsPage
