import React, { FC } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SimpleInfoBlock } from '@obeta/components/lib/notifications/SimpleInfoBlock'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { useTranslation } from 'react-i18next'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'
import { contentRoutes } from '@obeta/utils/lib/variables'
import styles from './UserManagementInfoForTouchDevices.module.scss'
import { Typography } from '@mui/material'

export const UserManagementInfoForTouchDevices: FC = () => {
  const { desktop } = useBreakpoints()
  const { t } = useTranslation()

  if (desktop) return null

  return (
    <div className={styles.container}>
      <ShopLink href={contentRoutes.userManagementInfoPage} target="_blank">
        <SimpleInfoBlock
          body={
            <Typography>
              {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.INFOS_PERMISSION_ORDER_PERMISSIONS')}
            </Typography>
          }
          Icon={InfoIcon}
          variant="light"
        />
      </ShopLink>
    </div>
  )
}
