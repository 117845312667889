import { useRef } from 'react'
import { PullToRefreshStore } from './store'

export function usePullToRefreshStore(): PullToRefreshStore {
  const storeRef = useRef<PullToRefreshStore | null>(null)

  if (!storeRef.current) {
    storeRef.current = new PullToRefreshStore()
  }

  return storeRef.current
}
