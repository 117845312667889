import { createContext, useCallback, useContext, useState, Dispatch, SetStateAction } from 'react'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import { UserPermissions, UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import {
  PERMISSION_STATUS,
  PERMISSION_STATUS_ALL,
  PERMISSION_STATUS_COPIED,
  PERMISSION_STATUS_CUSTOM,
} from '@obeta/utils/lib/permissions'
import {
  CART_ORDERABLE_COMPLETE_RANGE,
  CART_PERMISSION_STATUS_OWN_CART,
  CartOrderableStatus,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { useUserCreate } from '@obeta/data/lib/hooks/useUserUpdate'
import { useUserOrderPermissionsErrorCount } from '@obeta/data/lib/hooks/useUserOrderPermissionsErrorCount'

type Setter<T> = Dispatch<SetStateAction<T>>

const createEmptyUser = (): UserSubuser => {
  return {
    customerNumber: '',
    companyId: '',
    type: '',
    isTecselectUser: false,
    tecselectLoginUrl: '',
    algoliaUserToken: '',
    hasFreeShipping: false,
    isActive: false,
    emailData: {
      isConfirmed: false,
      email: '',
    },
    email: '',
    permissions: {
      Global_canReadPrices: '',
      Address_canWrite: false,
      Address_canRead: false,
      Cart_canWriteAddress: false,
      Cart_canWriteCommission: false,
      Cart_canWriteAdditionalText: false,
      Cart_requiredCommission: false,
      Cart_allowedSubmitAction: '',
      Cart_canChangePayment: false,
      Offers_canRead: false,
      Orders_canRead: false,
      User_canChangeDefaultAddress: false,
      Global_canUseUxsService: false,
      Global_canReadBackorderItems: false,
      Global_canReadAccountInfo: false,
      Global_canUseInterfaces: false,
      Global_canSeeUserManagement: false,
      Global_canUseCustomDataSheet: false,
      Global_canChangeDataSheetPrice: false,
      Global_showCreditWarning: false,
      Projects_canManage: false,
      User_shouldReceiveOrderConfirmations: false,
      User_canReadAccounting: false,
      Cart_canSee: 'Own',
      Cart_canOrder: false,
      Cart_canNotify: false,
      Cart_maxCartValue: 0,
      Cart_canSeeProjectIds: [],
    },
    settings: { name: '' },
    notificationEmail: '',
  }
}

type CreateUserValue = {
  name: string | null
  setName: Setter<string>
  email: string | null
  setEmail: Setter<string>
  emailConfirmation: string | null
  setEmailConfirmation: Setter<string>
  emailError: string | null
  setEmailError: Setter<string | null>
  emailConfirmationError: string | null
  setEmailConfirmationError: Setter<string | null>
  emailConfirmationNotValidError: string | null
  setEmailConfirmationNotValidError: Setter<string | null>
  currentStepIndex: number
  setCurrentStepIndex: Setter<number>
  canProceed: () => boolean
  userToCreate: UserSubuser
  setUserToCreate: Setter<UserSubuser>
  setPermissionsByStatus: (user: UserSubuser, value: PERMISSION_STATUS) => void
  cartPermissionStatus: CartPermissionStatus
  setCartPermissionStatus: Dispatch<SetStateAction<CartPermissionStatus>>
  cartOrderableStatus?: CartOrderableStatus
  setCartOrderableStatus: Dispatch<SetStateAction<CartOrderableStatus | undefined>>
  usersToNotify: Array<string>
  setUsersToNotify: Dispatch<SetStateAction<Array<string>>>
  allowedToOrder: boolean
  setAllowedToOrder: Dispatch<SetStateAction<boolean>>
  createUser: () => Promise<boolean>
  inCreateUserDialog: boolean
  maxValueFullRange?: number | null
  setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
  maxValueFullRangeActive: boolean
  setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
  maxValueOrders?: number | null
  setMaxValueOrders: Dispatch<SetStateAction<number | null | undefined>>
  maxValueOrdersActive: boolean
  setMaxValueOrdersActive: Dispatch<SetStateAction<boolean>>
  projectCartIds: Array<string>
  setProjectCartIds: Dispatch<SetStateAction<Array<string>>>
}

const noop = () => {
  //
}
const defaultValue = {
  currentStepIndex: 0,
  setCurrentStepIndex: noop,
  name: null,
  email: null,
  emailConfirmation: null,
  setName: noop,
  setEmail: noop,
  setEmailConfirmation: noop,
  emailError: null,
  emailConfirmationError: null,
  emailConfirmationNotValidError: null,
  setEmailError: noop,
  setEmailConfirmationError: noop,
  setEmailConfirmationNotValidError: noop,
  canProceed: () => false,
  userToCreate: createEmptyUser(),
  setUserToCreate: noop,
  setPermissionsByStatus: noop,
  cartPermissionStatus: CART_PERMISSION_STATUS_OWN_CART,
  setCartPermissionStatus: noop,
  cartOrderableStatus: CART_ORDERABLE_COMPLETE_RANGE,
  setCartOrderableStatus: noop,
  usersToNotify: [],
  setUsersToNotify: noop,
  allowedToOrder: false,
  setAllowedToOrder: noop,
  createUser: () => Promise.resolve(false),
  inCreateUserDialog: false,
  maxValueFullRange: undefined,
  setMaxValueFullRange: noop,
  maxValueOrders: undefined,
  maxValueOrdersActive: false,
  setMaxValueOrders: noop,
  maxValueFullRangeActive: false,
  setMaxValueFullRangeActive: noop,
  setMaxValueOrdersActive: noop,
  projectCartIds: [],
  setProjectCartIds: noop,
}
const CreateUserContext = createContext<CreateUserValue>(defaultValue)

export const CreateUserProvider = ({ children }) => {
  const [name, setName] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [emailConfirmation, setEmailConfirmation] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [emailConfirmationError, setEmailConfirmationError] = useState<string | null>(null)
  const [emailConfirmationNotValidError, setEmailConfirmationNotValidError] = useState<
    string | null
  >(null)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [userToCreate, setUserToCreate] = useState(createEmptyUser())
  const [cartOrderableStatus, setCartOrderableStatus] = useState<CartOrderableStatus | undefined>(
    CART_ORDERABLE_COMPLETE_RANGE
  )
  const [cartPermissionStatus, setCartPermissionStatus] = useState<CartPermissionStatus>(
    CART_PERMISSION_STATUS_OWN_CART
  )
  const [usersToNotify, setUsersToNotify] = useState<Array<string>>([])
  const [allowedToOrder, setAllowedToOrder] = useState(false)
  const [maxValueFullRange, setMaxValueFullRange] = useState<number | null | undefined>()
  const [maxValueFullRangeActive, setMaxValueFullRangeActive] = useState<boolean>(false)
  const [maxValueOrders, setMaxValueOrders] = useState<number | null | undefined>()
  const [maxValueOrdersActive, setMaxValueOrdersActive] = useState<boolean>(false)
  const [projectCartIds, setProjectCartIds] = useState<Array<string>>([])
  const dispatchUserCreate = useUserCreate()

  const canProceed = useCallback(() => {
    if (currentStepIndex === 0)
      return Boolean(
        name &&
          email &&
          emailConfirmation &&
          name.length > 0 &&
          isValidEmail(email) &&
          email === emailConfirmation
      )
    if (currentStepIndex === 1) return true
    if (currentStepIndex === 2) return true
    return false
  }, [currentStepIndex, email, emailConfirmation, name])

  const setPermissionsByStatus = useCallback(
    (user: UserSubuser, value: PERMISSION_STATUS) => {
      if (value === PERMISSION_STATUS_CUSTOM || value === PERMISSION_STATUS_COPIED) return
      for (const permission of Object.keys(user.permissions)) {
        if (
          typeof user.permissions[permission] === 'boolean' &&
          permission !== 'Cart_requiredCommission'
        ) {
          user.permissions[permission] = value === PERMISSION_STATUS_ALL
        }
        if (permission === 'Global_canReadPrices') {
          user.permissions[permission] =
            value === PERMISSION_STATUS_ALL ? 'PurchasePrice' : 'NoPrice'
        }
      }
      setUserToCreate({ ...user })
    },
    [setUserToCreate]
  )

  const mapPermissionsToRequestObject = useCallback(
    (permissions: UserPermissions) => {
      const requestPermissionsObject = { ...permissions }
      delete requestPermissionsObject.__typename

      if (requestPermissionsObject.Global_canReadPrices === '') {
        requestPermissionsObject.Global_canReadPrices = 'NoPrice'
      }
      if (requestPermissionsObject.Cart_allowedSubmitAction === '') {
        requestPermissionsObject.Cart_allowedSubmitAction = 'None'
      }
      if (maxValueFullRangeActive) {
        requestPermissionsObject.Cart_maxCartValue = maxValueFullRange
      }
      requestPermissionsObject.Cart_canOrder = allowedToOrder
      requestPermissionsObject.Cart_canSee = cartPermissionStatus
      requestPermissionsObject.Cart_canSeeProjectIds = projectCartIds

      return requestPermissionsObject
    },
    [
      allowedToOrder,
      cartPermissionStatus,
      maxValueFullRange,
      maxValueFullRangeActive,
      projectCartIds,
    ]
  )

  const getUserOrderPermissionsErrorCount = useUserOrderPermissionsErrorCount()

  const createUser = useCallback(async () => {
    const permissions = mapPermissionsToRequestObject(userToCreate.permissions)
    const errorCount = getUserOrderPermissionsErrorCount(
      permissions,
      maxValueFullRangeActive,
      maxValueFullRange
    )
    if (errorCount > 0) return false

    if (name && email) {
      await dispatchUserCreate({
        name,
        email,
        permissions,
        notificationEmail: usersToNotify.length > 0 ? usersToNotify[0] : undefined, // at the moment only one email is allowed
      })
      return true
    }
    return false
  }, [
    dispatchUserCreate,
    email,
    getUserOrderPermissionsErrorCount,
    mapPermissionsToRequestObject,
    maxValueFullRange,
    maxValueFullRangeActive,
    name,
    userToCreate.permissions,
    usersToNotify,
  ])

  return (
    <CreateUserContext.Provider
      value={{
        currentStepIndex,
        setCurrentStepIndex,
        name,
        setName,
        email,
        setEmail,
        emailConfirmation,
        setEmailConfirmation,
        emailError,
        setEmailError,
        emailConfirmationError,
        setEmailConfirmationError,
        emailConfirmationNotValidError,
        setEmailConfirmationNotValidError,
        canProceed,
        userToCreate,
        setUserToCreate,
        setPermissionsByStatus,
        cartOrderableStatus,
        setCartOrderableStatus,
        cartPermissionStatus,
        setCartPermissionStatus,
        usersToNotify,
        setUsersToNotify,
        allowedToOrder,
        setAllowedToOrder,
        createUser,
        inCreateUserDialog: true,
        maxValueFullRange,
        maxValueFullRangeActive,
        maxValueOrders,
        maxValueOrdersActive,
        setMaxValueFullRange,
        setMaxValueFullRangeActive,
        setMaxValueOrders,
        setMaxValueOrdersActive,
        projectCartIds,
        setProjectCartIds,
      }}
    >
      {children}
    </CreateUserContext.Provider>
  )
}

export const useCreateUserContext = () => {
  return useContext(CreateUserContext)
}

export const isDefaultValue = (context: CreateUserValue) => {
  return context === defaultValue
}
