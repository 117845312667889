import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { ProgressBar, Step } from 'react-step-progress-bar'
import 'react-step-progress-bar/styles.css'
import styles from './promotionProgressbar.module.scss'
import { IBenefit } from '@obeta/models/lib/models/Promotion/Promotion'
import { PromotionProgressStatus } from './promotionProgressStatus'
import { usePromotionProgress } from './hooks/usePromotionProgress'
import { useTranslation } from 'react-i18next'

interface IPromotionProgressbarProps {
  cartSum: number
  benefits: IBenefit[]
  criteriaType: string
  hideTopStatus?: boolean
  criteriaUnit?: string
}

export const PromotionProgressbar: React.FC<IPromotionProgressbarProps> = (props) => {
  const { cartSum, benefits, criteriaType, hideTopStatus, criteriaUnit } = props

  const { progress, stepPositions, nextBenefitIndex } = usePromotionProgress(cartSum, benefits)
  const firstBenefitGoal = benefits[0].goalToReach
  const sumDifference = firstBenefitGoal - cartSum
  const isSingleAndSuccess = !stepPositions.length && progress === 100

  const lastAchievedBenefit =
    // Note that the nextBenefitIndex may be the lastAchievedBenefit
    benefits[nextBenefitIndex].goalToReach <= cartSum
      ? benefits[nextBenefitIndex]
      : nextBenefitIndex - 1 > 0
      ? benefits[nextBenefitIndex - 1]
      : benefits[0]

  const successState = isSingleAndSuccess || stepPositions.some((pos) => pos <= progress)

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.success]: successState,
      })}
    >
      {!hideTopStatus && stepPositions.length > 1 && (
        <div className={styles.status}>
          <PromotionProgressStatus
            isAchieved={sumDifference <= 0}
            name={lastAchievedBenefit?.name}
            sum={sumDifference}
            criteriaType={criteriaType}
            criteriaUnit={criteriaUnit}
          />
        </div>
      )}
      <div className={styles.progressbarContainer}>
        <ProgressBar percent={progress} height={'0.625rem'} stepPositions={stepPositions}>
          {stepPositions.length >= 1
            ? benefits.map(({ name, goalToReach }) => (
                <Step transition="scale" key={`${name}-${goalToReach}`}>
                  {({ accomplished }) => <StepPoint accomplished={accomplished} />}
                </Step>
              ))
            : null}
        </ProgressBar>
      </div>
      {stepPositions.length > 1 && (
        <div className={styles.benefits}>
          {benefits.map((item) => (
            <Reward
              key={`${item.name}-${item.goalToReach}-benefit-image`}
              benefit={item}
              accomplished={cartSum >= item.goalToReach}
              criteriaType={criteriaType}
              criteriaUnit={criteriaUnit}
            />
          ))}
        </div>
      )}
    </div>
  )
}

interface IStepPointProps {
  accomplished: boolean
}
const StepPoint: React.FC<IStepPointProps> = ({ accomplished }) => {
  return (
    <div
      className={clsx(styles['step-item'], {
        [styles['step-item-not-accomplished']]: !accomplished,
      })}
    />
  )
}

interface IRewardProps {
  accomplished: boolean
  benefit: IBenefit
  criteriaType: string
  criteriaUnit?: string
}
const Reward: React.FC<IRewardProps> = ({ benefit, accomplished, criteriaType, criteriaUnit }) => {
  const { t } = useTranslation()
  const amount = criteriaUnit || 'Artikel'
  return (
    <div
      className={clsx(styles.reward, {
        [styles['reward-not-accomplished']]: !accomplished,
      })}
    >
      <img
        alt={benefit.name}
        width={benefit.image.data?.attributes.width}
        height={benefit.image.data?.attributes.height}
        className={styles['reward-image']}
        src={benefit.image.data?.attributes.url}
      />
      <Typography variant="bodyBold">{benefit.name}</Typography>
      <Typography variant="body">
        {t('PROMOTIONS.LEFT_TO_RECEIVE_GOAL', {
          amount: benefit.amount,
          goalToReach: benefit.goalToReach,
          units: criteriaType === 'sum' ? '€ Warenwert' : amount,
        })}
      </Typography>
    </div>
  )
}
