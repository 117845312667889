import styles from './ShoppingCartDetailsSelectionBarMain.module.scss'
import React, { useEffect } from 'react'
import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as CartWhiteIcon } from 'assets/icon/designsystem/cart.svg'
import { ReactComponent as EditCartIcon } from 'assets/icon/designsystem/add_shopping_cart_edit.svg'
import { ReactComponent as TransferIcon } from 'assets/icon/designsystem/swap_horiz.svg'
import { ReactComponent as StrikeThroughCartIcon } from 'assets/icon/designsystem/remove_shopping_cart.svg'

import { useTranslation } from 'react-i18next'
import {
  ShoppingCartContextEnum,
  ShoppingCartItem,
  ShoppingCartPrices,
} from '@obeta/models/lib/models/ShoppingCart'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OrderButton } from '../orders/OrderButton'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ContentMask } from './ContentMask'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { SecondaryButton } from '../custom-button/CustomButton'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { SelectionBar } from '../selection-bar/SelectionBar'

interface Props {
  cartId: string
  context: number
  enableEditingMode: () => void
  orderButtonEnabled: boolean
  shoppingCartPrices: ShoppingCartPrices
  notifyShoppingCart: () => void
  submitShoppingCart: () => void
  userCanSubmitCart: boolean
  userCanReadPrices: boolean
  isDeactivated: boolean
  deliveryDisabled?: boolean
  deadProductsInCart?: boolean
  optimisticNetSum?: number
  setUtilizeOptimisticPrices?: (boolean) => void
  utilizeOptimisticPrices?: boolean
  itemsDiscontinuedWithPartialStock?: ShoppingCartItem[]
  transferOciCartAndSubmit?: () => void
  transferIdsCartAndSubmit?: () => void
}

export const ShoppingCartDetailsSelectionBarMain: React.FC<Props> = (props) => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const {
    cartId,
    context,
    enableEditingMode,
    orderButtonEnabled,
    shoppingCartPrices,
    notifyShoppingCart,
    submitShoppingCart,
    userCanSubmitCart,
    userCanReadPrices,
    isDeactivated,
    deliveryDisabled,
    deadProductsInCart,
    optimisticNetSum,
    utilizeOptimisticPrices,
    setUtilizeOptimisticPrices,
    itemsDiscontinuedWithPartialStock,
    transferOciCartAndSubmit,
    transferIdsCartAndSubmit,
  } = props

  const sessionContext = getSessionContext()
  const userIsOciUser = sessionContext?.type === 'oci'
  const userIsIdsUser = useIsIdsTransferBackPossible()

  useEffect(() => {
    setUtilizeOptimisticPrices && setUtilizeOptimisticPrices(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCartPrices?.netSum])

  let idsButtonTranslationKey = 'SHOPPING_CART.CART_SUBMIT_IDS'
  if (mobile) {
    idsButtonTranslationKey = 'SHOPPING_CART.CART_SUBMIT_IDS_MOBILE'
  } else if (tablet) {
    idsButtonTranslationKey = 'SHOPPING_CART.CART_SUBMIT_IDS_TABLET'
  }

  return (
    <SelectionBar portalKey="shopping-cart-details-selection-bar-main">
      {isDeactivated && <ContentMask />}
      <div className={styles.content}>
        <Box className={styles.editOrNavigationButtonWrapper}>
          {context === ShoppingCartContextEnum.DETAILS && (
            <Button
              className={styles.actionButton}
              variant={'outlined'}
              fullWidth={false}
              size={'small'}
              startIcon={<SvgIcon component={EditCartIcon} />}
              onClick={() => enableEditingMode()}
            >
              {(desktop || tabletWide) && t('SHOPPING_CART.EDIT_CART')}
              {tablet && t('SHOPPING_CART.EDIT')}
            </Button>
          )}
          {(context === ShoppingCartContextEnum.CHECKOUT_DELIVERY ||
            context === ShoppingCartContextEnum.CHECKOUT_PICKUP) && (
            <Button
              className={styles.actionButton}
              variant={'outlined'}
              fullWidth={false}
              size={'small'}
              startIcon={<SvgIcon component={ArrowBackIcon} />}
              href={'/shopping-cart-details/' + cartId}
            >
              {(desktop || tabletWide) && t('SHOPPING_CART.NAVIGATION.BACK')}
            </Button>
          )}
        </Box>
        {userCanReadPrices && (
          <Typography variant={'boldText'}>
            {(desktop || tabletWide || tablet) && t('SHOPPING_CART.OVERALL_VALUE')}
            {mobile && t('SHOPPING_CART.OVERALL_VALUE_MOBILE')}:{' '}
            {utilizeOptimisticPrices && optimisticNetSum
              ? normalizePrice(optimisticNetSum)
              : normalizePrice(shoppingCartPrices.netSum)}
          </Typography>
        )}
        {context === ShoppingCartContextEnum.DETAILS && (
          <>
            {userIsOciUser && (
              <Box className={styles.idsButtonBox}>
                <Button
                  className={`${styles.fullWidth} ${styles.submitIDSButton}`}
                  type="submit"
                  variant={'contained'}
                  color={'primary'}
                  startIcon={<SvgIcon component={TransferIcon} />}
                  onClick={transferOciCartAndSubmit}
                >
                  {t('SHOPPING_CART.CART_SUBMIT_OCI')}
                </Button>
              </Box>
            )}
            {!userIsOciUser && (
              <div className={styles.actionButtons}>
                <Button
                  type="submit"
                  fullWidth={false}
                  variant="contained"
                  color="primary"
                  startIcon={<SvgIcon component={CartWhiteIcon} className={styles.whiteHover} />}
                  onClick={() => history.push('/shopping-cart-checkout/' + cartId)}
                  sx={{ '.MuiButton-startIcon': { ml: 0 } }}
                  disabled={
                    deadProductsInCart ||
                    (itemsDiscontinuedWithPartialStock &&
                      itemsDiscontinuedWithPartialStock.length > 0)
                  }
                >
                  {(tabletWide || desktop) &&
                    t('SHOPPING_CART.NAVIGATION.CONTINUE_TO_ORDER_DETAILS')}
                  {(mobile || tablet) && t('SHOPPING_CART.NAVIGATION.CONTINUE')}
                </Button>
                {userIsIdsUser && (
                  <SecondaryButton
                    fullWidth
                    type="submit"
                    leftIcon={
                      <SvgIcon
                        htmlColor={theme.palette.white.main}
                        component={StrikeThroughCartIcon}
                      />
                    }
                    onClick={transferIdsCartAndSubmit}
                  >
                    {t(idsButtonTranslationKey)}
                  </SecondaryButton>
                )}
              </div>
            )}
          </>
        )}
        {(context === ShoppingCartContextEnum.CHECKOUT_DELIVERY ||
          context === ShoppingCartContextEnum.CHECKOUT_PICKUP) &&
          notifyShoppingCart &&
          submitShoppingCart && (
            <div className={styles.actionButtons}>
              <OrderButton
                orderButtonEnabled={orderButtonEnabled ?? true}
                userCanSubmitCart={userCanSubmitCart}
                notifyShoppingCart={notifyShoppingCart}
                submitShoppingCart={submitShoppingCart}
                deliveryDisabled={deliveryDisabled}
                showShortButtonText
              />
              {userIsIdsUser && (
                <SecondaryButton
                  fullWidth
                  type="submit"
                  leftIcon={
                    <SvgIcon
                      htmlColor={theme.palette.white.main}
                      component={StrikeThroughCartIcon}
                    />
                  }
                  onClick={transferIdsCartAndSubmit}
                >
                  {!mobile && !tablet
                    ? t('SHOPPING_CART.CART_SUBMIT_IDS')
                    : tablet
                    ? t('SHOPPING_CART.CART_SUBMIT_IDS_TABLET')
                    : t('SHOPPING_CART.CART_SUBMIT_IDS_MOBILE')}
                </SecondaryButton>
              )}
            </div>
          )}
      </div>
    </SelectionBar>
  )
}
