import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { TextSkeleton } from '../../text-skeleton/TextSkeleton'
import { TemplateListItemV2Skeleton } from '../../templates/TemplateListItemV2'
import styles from './styles.scss'
import moduleStyles from './CartTemplatesListLoading.module.scss'

import React from 'react'

interface Props {
  title: string
}

export const CartTemplatesListLoading: React.FC<Props> = ({ title }) => {
  return (
    <>
      <Grid className={styles.appBarWrapper}>
        <AppBar color={'transparent'} elevation={0} position={'static'}>
          <Toolbar variant={'dense'} className={styles.toolbar}>
            <Typography variant={'h3'} color={'text.primary'}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Box className={moduleStyles.skeletonSearchBar}>
        <TextSkeleton />
      </Box>
      <Box className={moduleStyles.skeletonFilters}>
        <TextSkeleton />
      </Box>
      <Grid container spacing={2}>
        {new Array(5).fill(null).map((_, index) => {
          return (
            <Grid key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TemplateListItemV2Skeleton />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
