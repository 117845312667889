import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { SecondaryButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import styles from './SaveChangesDialog.module.scss'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as CancelIcon } from 'assets/icon/designsystem/close.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onDestroy: () => void
}

export const SaveChangesDialog: FC<Props> = ({ open, onClose, onSubmit, onDestroy }) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      onDestroy()
    }
  }, [onDestroy])

  return (
    <Dialog open={open} className={styles.dialog}>
      <DialogTitle>{t('USER_MANAGEMENT.SAVE_CHANGES_DIALOG.TITLE')}</DialogTitle>
      <DialogContent sx={{ padding: '2rem' }}>
        <Typography>{t('USER_MANAGEMENT.SAVE_CHANGES_DIALOG.BODY')}</Typography>
        <div className={styles.divider}>
          <LightGrayDivider />
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <TertiaryButton
          onClick={onClose}
          leftIcon={<CancelIcon className={clsx(styles.icon, styles.cancelIcon)} />}
        >
          <Typography>{t('USER_MANAGEMENT.SAVE_CHANGES_DIALOG.CANCEL')}</Typography>
        </TertiaryButton>
        <SecondaryButton
          leftIcon={<CheckIcon className={clsx(styles.icon, styles.checkIcon)} />}
          onClick={onSubmit}
        >
          <Typography>{t('USER_MANAGEMENT.SAVE_CHANGES_DIALOG.SUBMIT')}</Typography>
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}
