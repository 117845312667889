import { useBreakpoints } from './useBreakpoints'
import { useFeatureToggle } from './feature-toggles'
import { ProductSearchUserData } from '@obeta/schema'

export type SearchPromotionBannerData = {
  imageUrl: string
  target: string
  actionName: string
}

export const useSearchPromotionBanner = (
  searchUserData: Array<ProductSearchUserData>
): SearchPromotionBannerData | null => {
  const isAlgoliaDrivenBannerEnabled = useFeatureToggle('UseAlgoliaDrivenPromotionBanner')
  const { desktop, mobile, tablet, tabletWide } = useBreakpoints()

  if (!isAlgoliaDrivenBannerEnabled || searchUserData.length === 0) return null

  const [firstUserDataEntry] = searchUserData
  const { promotion } = firstUserDataEntry

  const searchPromotionBanner = {
    target: promotion.target,
    actionName: promotion.actionName,
  }

  if (desktop) {
    return { ...searchPromotionBanner, imageUrl: promotion.images.desktop }
  }
  if (tabletWide) {
    return { ...searchPromotionBanner, imageUrl: promotion.images.tabletWide }
  }
  if (tablet) {
    return { ...searchPromotionBanner, imageUrl: promotion.images.tabletSmall }
  }
  if (mobile) {
    return { ...searchPromotionBanner, imageUrl: promotion.images.mobile }
  }
  // unreachable code
  return null
}
