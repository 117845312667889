import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useCallback,
} from 'react'
import {
  OpenPositionSearchAttribute,
  OpenPositionSearchOperator,
} from '../hooks/my-accounting/useSortedAndFilteredOpenPositions'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'

export type OpenPositionFilter = 'all' | 'overdue' | 'discountable' | 'credits'
export type OpenPositionSearchAttributeOption = { id: OpenPositionSearchAttribute; title: string }
export type OpenPositionSearchOperatorOption = { id: OpenPositionSearchOperator; title: string }
type OpenPositionSortingOrder = 'asc' | 'desc'
export type OpenPositionSortingOrderBy = keyof Pick<
  OpenPosition,
  | 'receipt'
  | 'invoiceFromDate'
  | 'invoiceDueDate'
  | 'invoiceAmount'
  | 'openAmount'
  | 'dunningLevel'
  | 'discountDate'
>
export type OpenPositionSorting = {
  orderBy: OpenPositionSortingOrderBy
  order: OpenPositionSortingOrder
}

interface MyAccountingContextValue {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  filter: OpenPositionFilter
  setFilter: (filter: OpenPositionFilter) => void
  searchOperator: OpenPositionSearchOperatorOption
  searchAttribute: OpenPositionSearchAttributeOption
  setSearchOperator: Dispatch<SetStateAction<OpenPositionSearchOperatorOption>>
  setSearchAttribute: Dispatch<SetStateAction<OpenPositionSearchAttributeOption>>
  selectedItems: OpenPosition[]
  setSelectedItems: (
    items: ((prevItems: OpenPosition[]) => OpenPosition[]) | OpenPosition[]
  ) => void
  masterCheckboxChecked: boolean
  setMasterCheckboxChecked: (checked: boolean) => void
  sorting: OpenPositionSorting
  setSorting: (sorting: OpenPositionSorting) => void
}
export const OPEN_POSITION_SEARCH_ATTRIBUTES: Array<OpenPositionSearchAttributeOption> = [
  { id: 'receipt', title: 'MY_ACCOUNTING.SEARCH_ATTRIBUTE_RECEIPT' },
  { id: 'invoiceDueDate', title: 'MY_ACCOUNTING.SEARCH_ATTRIBUTE_INVOICE_DUE_DATE' },
  { id: 'invoiceFromDate', title: 'MY_ACCOUNTING.SEARCH_ATTRIBUTE_INVOICE_FROM_DATE' },
]

export const OPEN_POSITION_SEARCH_OPERATORS: Array<{
  id: OpenPositionSearchOperator
  title: string
}> = [
  { id: 'eq', title: '=' },
  { id: 'lt', title: '<' },
  { id: 'gt', title: '>' },
]

const DEFAULT_SORTING: OpenPositionSorting = { orderBy: 'invoiceDueDate', order: 'asc' }

const noop = () => {
  //
}
const MyAccountingContext = createContext<MyAccountingContextValue>({
  searchTerm: '',
  filter: 'all',
  searchOperator: OPEN_POSITION_SEARCH_OPERATORS[0],
  searchAttribute: OPEN_POSITION_SEARCH_ATTRIBUTES[0],
  selectedItems: [],
  masterCheckboxChecked: false,
  sorting: DEFAULT_SORTING,
  setSearchTerm: noop,
  setFilter: noop,
  setSearchOperator: noop,
  setSearchAttribute: noop,
  setSelectedItems: noop,
  setMasterCheckboxChecked: noop,
  setSorting: noop,
})

export const MyAccountingProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<OpenPosition[]>([])
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState<boolean>(false)
  const [filter, setFilter] = useState<OpenPositionFilter>('all')
  const [sorting, setSorting] = useState<OpenPositionSorting>(DEFAULT_SORTING)
  const [searchOperator, setSearchOperator] = useState<OpenPositionSearchOperatorOption>(
    OPEN_POSITION_SEARCH_OPERATORS[0]
  )
  const [searchAttribute, setSearchAttribute] = useState<OpenPositionSearchAttributeOption>(
    OPEN_POSITION_SEARCH_ATTRIBUTES[0]
  )

  const setFilterWithSelectionReset = useCallback(
    (filter: OpenPositionFilter) => {
      setMasterCheckboxChecked(false)
      setSelectedItems([])
      setFilter(filter)
      if (
        // dunningLevel is only shown when overdue filter is active
        (filter !== 'overdue' && sorting.orderBy === 'dunningLevel') ||
        // discountDate is only shown when credits filter is active
        (filter !== 'credits' && sorting.orderBy === 'discountDate')
      ) {
        setSorting(DEFAULT_SORTING)
      }
    },
    [sorting.orderBy]
  )

  return (
    <MyAccountingContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        filter,
        setFilter: setFilterWithSelectionReset,
        searchOperator,
        setSearchOperator,
        searchAttribute,
        setSearchAttribute,
        selectedItems,
        setSelectedItems,
        masterCheckboxChecked,
        setMasterCheckboxChecked,
        sorting,
        setSorting,
      }}
    >
      {children}
    </MyAccountingContext.Provider>
  )
}

export const useMyAccountingContext = () => {
  return useContext(MyAccountingContext)
}
