import { SearchList } from './SearchList'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Sidebar } from './filters/facets/Sidebar'
import { Filters } from './Filters'
import { CategoryHeader } from './CategoryHeader'
import { useSearchPageMeta } from '@obeta/data/lib/hooks/useSearchPageMeta'
import styles from './SearchPage.module.scss'
import { FiltersStateProvider, useFiltersStateContext } from './filters/hooks/useFiltersReducer'
import { SearchNoResults } from './SearchNoResults'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useCallback, useEffect, useState } from 'react'
import { SearchTrackingProvider } from '@obeta/data/lib/hooks/useSearchTrackingContext'
import {
  SearchFeedback,
  SearchFeedbackValue,
} from '@obeta/components/lib/search-feedback/SearchFeedback'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { SearchFeedbackConsentOverlay } from '@obeta/components/lib/search-feedback/SearchFeedbackConsentOverlay'
import { useCookiebotAcceptAndDeclineHandler } from '@obeta/data/lib/hooks/useCookiebotAcceptAndDeclineHandler'
import { ThankYouThumbUp } from '@obeta/components/lib/search-feedback/ThankYouThumbUp'
import { OverlayThumbDownFeedback } from '@obeta/components/lib/search-feedback/OverlayThumbDownFeedback'
import { OverlayThankYouThumbDown } from '@obeta/components/lib/search-feedback/OverlayThankYouThumbDown'
import { trackCustom } from '@obeta/utils/lib/tracking'
import dayjs from 'dayjs'

const Body: React.FC = () => {
  const [showSearchFeedback, setShowSearchFeedback] = useState(true)
  const [showSearchFeedbackConsentOverlay, setShowSearchFeedbackConsentOverlay] = useState(false)
  const [searchFeedbackValue, setSearchFeedbackValue] = useState<SearchFeedbackValue>({})
  const [feedbackButtonClicked, setFeedbackButtonClicked] = useState(false)
  const [showThankYouThumbUp, setShowThankYouThumbUp] = useState(false)
  const [showOverlayThumbDownFeedback, setShowOverlayThumbDownFeedback] = useState(false)
  const [showOverlayThankYouThumbDown, setShowOverlayThankYouThumbDown] = useState(false)
  const [sendFeedbackToDataDog, setSendFeedbackToDataDog] = useState(false)

  const { mobile, tablet, tabletAll, desktop } = useBreakpoints()
  const { title, hitCount, loading, rootId } = useSearchPageMeta()
  const { activeFilters } = useFiltersStateContext()
  const { searchParams, queryId, itemsPerPage, currentPage } = useArticlesSearchProvider()
  const useSearchFeedback = useFeatureToggle('UseSearchFeedback')
  const { statisticsCookiesAllowed } = useCookiebotAcceptAndDeclineHandler()
  const isAvailableDefaultEnabled = useFeatureToggle('UseAvailableDefault')

  useEffect(() => {
    return () => {
      window?.scrollTo({ top: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.id])

  useEffect(
    () => {
      const storedSearchFeedbackTime = localStorage.getItem('storedSearchFeedbackTime') as string
      const feedbackTimePlusTwoDays = dayjs(new Date(storedSearchFeedbackTime)).add(48, 'h')
      const currentDateTime = dayjs(new Date())
      if (feedbackTimePlusTwoDays > currentDateTime) setShowSearchFeedback(false)
      if (!showSearchFeedback) {
        if (currentDateTime > feedbackTimePlusTwoDays) setShowSearchFeedback(true)
        setShowThankYouThumbUp(false)
        setSendFeedbackToDataDog(false)
        setFeedbackButtonClicked(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  const saveSearchFeedbackTime = () => {
    const currentDateTime = new Date()
    localStorage.setItem('storedSearchFeedbackTime', currentDateTime.toString())
  }

  const onCloseSearchFeedback = useCallback(
    () => {
      saveSearchFeedbackTime()
      setShowSearchFeedback(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSearchFeedback]
  )

  useEffect(
    () => {
      if (showOverlayThankYouThumbDown) setShowSearchFeedback(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showOverlayThankYouThumbDown]
  )
  useEffect(
    () => {
      if (sendFeedbackToDataDog) {
        try {
          trackCustom('product-search-feedback', {
            searchParams: searchParams,
            searchFeedback: searchFeedbackValue,
          })
          saveSearchFeedbackTime()
        } catch (error) {
          localStorage.removeItem('storedSearchFeedbackTime')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendFeedbackToDataDog]
  )

  const showList = loading || hitCount > 0
  const showActions = showList || activeFilters.length > 0
  let hasNoResultsWithImmediatelyAvailableFilter = false
  if (isAvailableDefaultEnabled && activeFilters.length === 1) {
    const [onlyFilter] = activeFilters
    hasNoResultsWithImmediatelyAvailableFilter = onlyFilter.id === 'search-area' && hitCount === 0
  }
  return (
    <SearchTrackingProvider
      value={{ queryId, searchParams, filters: activeFilters, itemsPerPage, currentPage }}
    >
      {!(mobile || tablet) && showActions && !hasNoResultsWithImmediatelyAvailableFilter && (
        <Sidebar />
      )}
      <div className={styles.content}>
        {showActions && !hasNoResultsWithImmediatelyAvailableFilter && (
          <CategoryHeader
            mobile={mobile}
            catId={rootId}
            title={title}
            size={hitCount}
            // rightAdornment={<SortBy opts={opts} />} TODO: Uncomment when API will for sorting be ready
            rightAdornment={null}
            bottomAdornment={<Filters mobile={mobile} tablet={tablet} />}
          />
        )}
        {showList ? (
          <SearchList mobile={mobile} tabletAll={tabletAll} desktop={desktop} />
        ) : (
          <SearchNoResults search={title} />
        )}
      </div>
      {showSearchFeedback && useSearchFeedback && !mobile ? (
        <SearchFeedback
          setShowSearchFeedback={setShowSearchFeedback}
          setShowSearchFeedbackConsentOverlay={setShowSearchFeedbackConsentOverlay}
          searchFeedbackValue={searchFeedbackValue}
          setSearchFeedbackValue={setSearchFeedbackValue}
          statisticsCookiesAllowed={statisticsCookiesAllowed}
          feedbackButtonClicked={feedbackButtonClicked}
          setFeedbackButtonClicked={setFeedbackButtonClicked}
          setShowThankYouThumbUp={setShowThankYouThumbUp}
          setShowOverlayThumbDownFeedback={setShowOverlayThumbDownFeedback}
          setSendFeedbackToDataDog={setSendFeedbackToDataDog}
          onCloseSearchFeedback={onCloseSearchFeedback}
        />
      ) : null}
      {showSearchFeedbackConsentOverlay && useSearchFeedback ? (
        <SearchFeedbackConsentOverlay
          setShowSearchFeedbackConsentOverlay={setShowSearchFeedbackConsentOverlay}
        />
      ) : null}
      {showThankYouThumbUp ? (
        <ThankYouThumbUp setShowThankYouThumbUp={setShowThankYouThumbUp} />
      ) : null}
      {showOverlayThumbDownFeedback ? (
        <OverlayThumbDownFeedback
          showOverlayThumbDownFeedback={showOverlayThumbDownFeedback}
          setShowOverlayThumbDownFeedback={setShowOverlayThumbDownFeedback}
          setShowOverlayThankYouThumbDown={setShowOverlayThankYouThumbDown}
          setSearchFeedbackValue={setSearchFeedbackValue}
          searchFeedbackValue={searchFeedbackValue}
          setSendFeedbackToDataDog={setSendFeedbackToDataDog}
        />
      ) : null}
      {showOverlayThankYouThumbDown ? (
        <OverlayThankYouThumbDown
          showOverlayThankYouThumbDown={showOverlayThankYouThumbDown}
          setShowOverlayThankYouThumbDown={setShowOverlayThankYouThumbDown}
        />
      ) : null}
    </SearchTrackingProvider>
  )
}

export const SearchPage: React.FC = () => {
  return (
    <>
      <div className={styles.background} />
      <div className={styles.root}>
        <div className={styles.body}>
          <FiltersStateProvider>
            <Body />
          </FiltersStateProvider>
        </div>
      </div>
    </>
  )
}
