import React, { ComponentProps, FC, useEffect, useRef, useState } from 'react'
import { Tab as MuiTab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { AccountDetailsAdminOnSubuser } from './components/AccountDetailsAdminOnSubuser'
import { PermissionsTabHeader } from './components/PermissionsTabHeader'
import { SaveChangesBar } from './components/SaveChangesBar'
import { useUserPermissionUpdate, useUserUpdate } from '@obeta/data/lib/hooks/useUserUpdate'
import { getCurrentUser } from '@obeta/utils/lib/getCurrentUser'
import { UserPermission } from './permission/UserPermission'
import { UserOrderPermission } from './order-permission/UserOrderPermission'
import {
  CART_ORDERABLE_COMPLETE_RANGE,
  CART_PERMISSION_STATUS_PROJECT_CARTS,
  CartOrderableStatus,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'
import { UserManagementSkeleton } from './UserManagementSkeleton'
import {
  PERMISSION_STATUS,
  PERMISSION_STATUS_ALL,
  PERMISSION_STATUS_COPIED,
  PERMISSION_STATUS_CUSTOM,
  PERMISSION_STATUS_NONE,
} from '@obeta/utils/lib/permissions'
import {
  UserManagementChangeHandler,
  useUserManagementChangeHandler,
} from './save-changes-dialog/useUserManagementChangeHandler'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import {
  UserManagementReadOnlyProvider,
  useUserManagementReadOnly,
} from './form/useUserManagementReadOnly'
import { UserManagementInfoForTouchDevices } from './form/UserManagementInfoForTouchDevices'
import { buildUserIdByUser } from '@obeta/utils/lib/buildUserId'
import { useUserOrderPermissionsErrorCount } from '@obeta/data/lib/hooks/useUserOrderPermissionsErrorCount'

const TabPanel: FC<{ index: number; value: number; withBackground?: boolean }> = (props) => {
  const { index, value, withBackground, children } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-management-${index}`}
      aria-labelledby={`user-management-tab-${index}`}
      style={withBackground ? { backgroundColor: 'white', padding: '1rem' } : {}}
    >
      {value === index && children}
    </div>
  )
}

const Tab: FC<{ index: number } & ComponentProps<typeof MuiTab>> = (props) => {
  const { index, label, ...rest } = props
  return (
    <MuiTab
      label={label}
      sx={{ marginBottom: '-4rem' }}
      id={`user-management-tab-${index}`}
      aria-controls={`user-management-tabpanel-${index}`}
      {...rest}
    />
  )
}

type AccountDetailsValues = { email: string; name: string }

const checkChangesInAccountDetails = ({
  left,
  right,
}: {
  left?: AccountDetailsValues
  right?: AccountDetailsValues
}) => {
  if (!left || !right) return false
  return left.email !== right.email || left.name !== right.name
}

const UserManagementPageContent: FC<{
  currentUser: UserSubuser
  updateUser: (user: UserSubuser) => void
}> = (props) => {
  const { currentUser, updateUser } = props
  const { tab } = useParams<{ tab: string }>()
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState<number>(isNaN(Number(tab)) ? 0 : parseInt(tab))
  const dispatchPermissionUpdate = useUserPermissionUpdate()
  const { hasUnsavedChanges, setHasUnsavedChanges, setSaveChangesDialog } =
    useUserManagementChangeHandler()

  const [cartOrderableStatus, setCartOrderableStatus] = useState<CartOrderableStatus | undefined>(
    CART_ORDERABLE_COMPLETE_RANGE
  )
  const [cartPermissionStatus, setCartPermissionStatus] = useState<CartPermissionStatus>(
    currentUser.permissions.Cart_canSee as CartPermissionStatus
  )
  const [projectCartIds, setProjectCartIds] = useState<Array<string>>(
    currentUser.permissions.Cart_canSee === CART_PERMISSION_STATUS_PROJECT_CARTS
      ? currentUser.permissions.Cart_canSeeProjectIds
      : []
  )

  const [allowedToOrder, setAllowedToOrder] = useState<boolean>(
    currentUser.permissions.Cart_canOrder
  )
  const [usersToNotify, setUsersToNotify] = useState<Array<string>>(
    currentUser.notificationEmail.length > 0 ? [currentUser.notificationEmail] : []
  )
  const [maxValueFullRange, setMaxValueFullRange] = useState<number | null | undefined>(
    currentUser.permissions.Cart_maxCartValue
  )
  const [maxValueFullRangeActive, setMaxValueFullRangeActive] = useState<boolean>(
    typeof currentUser?.permissions.Cart_maxCartValue === 'number'
  )
  const [maxValueOrders, setMaxValueOrders] = useState<number | null | undefined>(
    currentUser.permissions.Cart_maxCartValue
  )
  // TODO: initialize with value from user response - feature not implemented yet
  const [maxValueOrdersActive, setMaxValueOrdersActive] = useState<boolean>(false)
  const [hasAccountDetailsChanges, setHasAccountDetailsChanges] = useState(false)
  const [hasUserPermissionChanges, setHasUserPermissionChanges] = useState(false)
  const [hasUserOrderPermissionChanges, setHasUserOrderPermissionChanges] = useState(false)
  const [user, setUser] = useState(currentUser)
  const originalUserPermissions = useRef<string>(JSON.stringify(user.permissions))
  const originalUserOrderPermissions = useRef(
    JSON.stringify({
      usersToNotify,
      cartOrderableStatus,
      cartPermissionStatus,
      projectCartIds,
      allowedToOrder,
      maxValueFullRange,
      maxValueFullRangeActive,
      maxValueOrders,
      maxValueOrdersActive,
    })
  )
  const { fullUserId } = useUserDataV2()
  const customerId = buildUserIdByUser(currentUser, true)
  const isActiveUser = fullUserId === customerId

  const [name, setName] = useState(
    currentUser.settings.name.substring(currentUser.settings.name.indexOf(' '))
  )
  const [email, setEmail] = useState(currentUser.email)
  const [emailError, setEmailError] = useState('')

  const dispatchUserUpdate = useUserUpdate()
  const originalAccountDetails = useRef<AccountDetailsValues>({
    email,
    name,
  })

  const isReadOnlyUser = useUserManagementReadOnly()

  const setCartPermissionStatusAndUserPermissions = (value: CartPermissionStatus) => {
    setCartPermissionStatus(value)
    const newUser = { ...user }
    newUser.permissions.Cart_canSee = value
    setUser(newUser)
  }

  const setVisibleProjectIds = (ids: string[]) => {
    const newUser = { ...user }
    setProjectCartIds(ids)
    newUser.permissions.Cart_canSeeProjectIds = ids
    setUser(newUser)
  }

  const setNotificationEmailsAndUser = (emails: string[]) => {
    if (emails.length === 0) return
    const newUser = { ...user }
    setUsersToNotify(emails)
    newUser.notificationEmail = emails[0]
    setUser(newUser)
  }

  const setAllowedToOrderAndUser = (value: boolean) => {
    const newUser = { ...user }
    setAllowedToOrder(value)
    newUser.permissions.Cart_canOrder = value
    setUser(newUser)
  }
  const handleAccountDetailsSave = async () => {
    await dispatchUserUpdate({
      subUserId: customerId,
      email,
      name,
    })
    currentUser.settings.name = `${customerId} ${name}`
    updateUser({ ...currentUser })
    originalAccountDetails.current = { email, name }
    setHasAccountDetailsChanges(false)
  }

  useEffect(() => {
    setHasAccountDetailsChanges(
      checkChangesInAccountDetails({
        left: originalAccountDetails.current,
        right: {
          email,
          name,
        },
      })
    )
  }, [email, name])

  useEffect(() => {
    setHasUnsavedChanges(
      hasUserPermissionChanges || hasUserOrderPermissionChanges || hasAccountDetailsChanges
    )
  }, [
    hasUserPermissionChanges,
    hasUserOrderPermissionChanges,
    hasAccountDetailsChanges,
    setHasUnsavedChanges,
  ])

  const userPermissionsAsString = JSON.stringify(user.permissions)
  useEffect(() => {
    setHasUserPermissionChanges(
      originalUserPermissions.current !== JSON.stringify(user.permissions)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsAsString])

  const userOrderPermissionsAsString = JSON.stringify({
    usersToNotify,
    cartOrderableStatus,
    cartPermissionStatus,
    projectCartIds,
    allowedToOrder,
    maxValueFullRange,
    maxValueFullRangeActive,
    maxValueOrders,
    maxValueOrdersActive,
  })
  useEffect(() => {
    setHasUserOrderPermissionChanges(
      originalUserOrderPermissions.current !== userOrderPermissionsAsString
    )
  }, [userOrderPermissionsAsString])

  const getUserOrderPermissionsErrorCount = useUserOrderPermissionsErrorCount()

  const handlePermissionSave = () => {
    const permissions = {
      ...user.permissions,
      Cart_maxCartValue: maxValueFullRangeActive ? maxValueFullRange : null,
    }

    if (tabIndex === 2) {
      const errorCount = getUserOrderPermissionsErrorCount(
        permissions,
        maxValueFullRangeActive,
        maxValueFullRange
      )
      if (errorCount > 0) return
    }

    dispatchPermissionUpdate({
      subUserId: user.companyId + '-' + user.customerNumber,
      notificationEmail: user.notificationEmail,
      permissions: permissions,
    })

    originalUserPermissions.current = JSON.stringify(permissions)
    setHasUserPermissionChanges(false)
    setHasUserOrderPermissionChanges(false)
  }
  const hasChanges = tabIndex === 2 ? hasUserOrderPermissionChanges : hasUserPermissionChanges
  const setAllPermissions = (value: PERMISSION_STATUS) => {
    const newUser = { ...user }
    if (value === PERMISSION_STATUS_CUSTOM || value === PERMISSION_STATUS_COPIED) return
    for (const permission of Object.keys(newUser.permissions)) {
      if (
        typeof newUser.permissions[permission] === 'boolean' &&
        permission !== 'Cart_requiredCommission'
      ) {
        newUser.permissions[permission] = value === PERMISSION_STATUS_ALL
      }
      if (permission === 'Global_canReadPrices') {
        newUser.permissions[permission] =
          value === PERMISSION_STATUS_ALL ? 'PurchasePrice' : 'NoPrice'
      }
      if (
        permission === 'Cart_requiredCommission' &&
        (value === PERMISSION_STATUS_ALL || value === PERMISSION_STATUS_NONE)
      ) {
        newUser.permissions[permission] = false
      }
    }
    setUser((prevState) => ({ ...prevState, newUser }))
  }
  return (
    <div>
      <PermissionsTabHeader currentUser={currentUser} isActiveUser={isActiveUser} />
      {currentUser.type === UserType.main ? (
        <Tabs
          value={tabIndex}
          onChange={(event, value) => setTabIndex(value)}
          style={{ marginBottom: '1rem' }}
          aria-label="user management page tabs"
        >
          <Tab label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAIL')} index={0} />
          {/* TODO
          <Tab label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CONFIGURATIONS')} index={1} /> */}
        </Tabs>
      ) : (
        <Tabs
          value={tabIndex}
          onChange={(event, value) => {
            if (hasUnsavedChanges) {
              setSaveChangesDialog({
                open: true,
                onDialogSubmit: async () => {
                  if (tabIndex === 0) {
                    await handleAccountDetailsSave()
                  }
                  if (tabIndex === 1 || tabIndex === 2) {
                    handlePermissionSave()
                  }
                  setTabIndex(value)
                },
              })
            } else {
              setTabIndex(value)
            }
          }}
          style={{ marginBottom: '1rem' }}
          aria-label="user management page tabs"
        >
          <Tab label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAIL')} index={0} />
          <Tab label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PERMISSION')} index={1} />
          <Tab label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSIONS')} index={2} />
          {/* TODO
          <Tab
            label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.EXTENDED_CONFIGURATIONS')}
            index={3}
          /> */}
        </Tabs>
      )}

      <TabPanel index={0} value={tabIndex} withBackground>
        <AccountDetailsAdminOnSubuser
          currentUser={currentUser}
          email={email}
          name={name}
          setEmail={setEmail}
          setName={setName}
          emailError={emailError}
          setEmailError={setEmailError}
          isLoggedInUser={isActiveUser}
        />
        <SaveChangesBar
          portalKey={'account-details-selection-bar'}
          onSaveChanges={handleAccountDetailsSave}
          error={emailError.length > 0}
          restoreOriginalSettings={() => {
            setEmail(originalAccountDetails.current.email)
            setName(originalAccountDetails.current.name)
          }}
          hasChanges={hasAccountDetailsChanges}
        />
      </TabPanel>

      <TabPanel index={currentUser.type === UserType.main ? 3 : 1} value={tabIndex}>
        <UserPermission
          user={user}
          updateUser={setUser}
          setAllPermissions={setAllPermissions}
          forceMinWidth
        />
        {!isReadOnlyUser && (
          <SaveChangesBar
            portalKey="my-account-selection-bar"
            onSaveChanges={handlePermissionSave}
            hasChanges={hasUserPermissionChanges}
            disabled={isReadOnlyUser}
            restoreOriginalSettings={() => {
              setUser({
                ...user,
                permissions: JSON.parse(originalUserPermissions.current),
              } as UserSubuser)
            }}
          />
        )}
      </TabPanel>
      <TabPanel index={2} value={tabIndex}>
        <UserOrderPermission
          currentUser={currentUser}
          updateUser={setUser}
          usersToNotify={usersToNotify}
          cartOrderableStatus={cartOrderableStatus}
          cartPermissionStatus={cartPermissionStatus}
          setUsersToNotify={setNotificationEmailsAndUser}
          setCartPermissionStatus={setCartPermissionStatusAndUserPermissions}
          setCartOrderableStatus={setCartOrderableStatus}
          allowedToOrder={allowedToOrder}
          setAllowedToOrder={setAllowedToOrderAndUser}
          maxValueFullRange={maxValueFullRange}
          setMaxValueFullRange={setMaxValueFullRange}
          maxValueFullRangeActive={maxValueFullRangeActive}
          setMaxValueFullRangeActive={setMaxValueFullRangeActive}
          maxValueOrders={maxValueOrders}
          setMaxValueOrders={setMaxValueOrders}
          maxValueOrdersActive={maxValueOrdersActive}
          setMaxValueOrdersActive={setMaxValueOrdersActive}
          projectCartIds={projectCartIds}
          setProjectCartIds={setVisibleProjectIds}
        />
        {!isReadOnlyUser && (
          <SaveChangesBar
            portalKey="my-account-selection-bar"
            onSaveChanges={handlePermissionSave}
            hasChanges={hasChanges}
            disabled={isReadOnlyUser}
            restoreOriginalSettings={() => {
              const userOrderPermissionsBackup = JSON.parse(originalUserOrderPermissions.current)
              setUsersToNotify(userOrderPermissionsBackup.usersToNotify)
              setCartPermissionStatus(userOrderPermissionsBackup.cartPermissionStatus)
              setCartOrderableStatus(userOrderPermissionsBackup.cartOrderableStatus)
              setAllowedToOrder(userOrderPermissionsBackup.allowedToOrder)
              setMaxValueFullRange(userOrderPermissionsBackup.maxValueFullRange)
              setMaxValueFullRangeActive(userOrderPermissionsBackup.maxValueFullRangeActive)
              setMaxValueOrders(userOrderPermissionsBackup.maxValueOrders)
              setMaxValueOrdersActive(userOrderPermissionsBackup.maxValueOrdersActive)
              setProjectCartIds(userOrderPermissionsBackup.projectCartIds)
            }}
          />
        )}
      </TabPanel>
      <TabPanel index={currentUser.type === UserType.main ? 1 : 3} value={tabIndex}>
        {/* TODO  implement settings */}
      </TabPanel>
      <UserManagementInfoForTouchDevices />
    </div>
  )
}

export const UserManagementPage: FC = (props) => {
  const { customerNumber } = useParams()

  const { sortedItems: users, updateUser } = useUserOverviewTableData()
  const currentUser = getCurrentUser(users, customerNumber)
  if (!currentUser) return <UserManagementSkeleton />
  return (
    <UserManagementReadOnlyProvider>
      <UserManagementChangeHandler>
        <UserManagementPageContent currentUser={currentUser} updateUser={updateUser} />
      </UserManagementChangeHandler>
    </UserManagementReadOnlyProvider>
  )
}
