import React, { Dispatch, FC, SetStateAction } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { RadioGroup, TextField, Tooltip } from '@mui/material'
import {
  CART_ORDERABLE_COMPLETE_RANGE,
  CartOrderableStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { CollapseComponent } from '../../searchPage/filters/CollapseComponent'
import { CollapsibleContentContainer } from '../form/CollapsibleContentContainer'
import { RadioButtonLine } from '../form/FormComponents'
import styles from './OrderPermissionOrderableCartGroup.module.scss'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as ShoppingCartIcon } from 'assets/icon/designsystem/cart.svg'
//import { CartTemplate } from '@obeta/schema'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import formStyles from '../form/form.module.scss'

type MaxValueLineProps = {
  active: boolean
  value: number | undefined | null
  setActive: Dispatch<SetStateAction<boolean>>
  setValue: Dispatch<SetStateAction<number | null>>
  alignGap?: boolean
  currentUser?: UserSubuser
  updateUser?: (value: UserSubuser) => void
  disabled?: boolean
  tooltipText?: string
}
const MaxValueLine: FC<MaxValueLineProps> = ({
  active,
  setActive,
  value,
  setValue,
  alignGap,
  currentUser,
  updateUser,
  disabled,
  tooltipText,
}) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  // TODO: move into UserManagementPage similar to other state modifications
  const handleMaxValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (updateUser && currentUser) {
      const { value } = event.target
      const updatedUser = {
        ...currentUser,
        permissions: {
          ...currentUser?.permissions,
          Cart_maxCartValue: parseInt(value),
        },
      }
      updateUser(updatedUser)
    }
  }
  return (
    <div className={clsx(styles.maxValueLine, alignGap && styles.alignGap)}>
      <Checkbox
        checked={active}
        onChange={(e) =>
          setActive((prev) => {
            if (prev) {
              setValue(null)
              handleMaxValueChange(e)
              return false
            }
            return !prev
          })
        }
        disabled={disabled}
      />
      <div className={styles.inputWithTooltip}>
        <TextField
          className={styles.maxValueInput}
          value={value ?? ''}
          disabled={disabled}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '')

            if (onlyNums.length > 0) {
              setValue(parseInt(onlyNums, 10))
              setActive(true)
            } else {
              setValue(null)
              setActive(false)
            }

            handleMaxValueChange(e)
          }}
          placeholder={t(
            'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_COMPLETE_RANGE_MAX_VALUE'
          )}
        />
        {desktop && tooltipText && tooltipText?.length > 0 && (
          <Tooltip title={tooltipText}>
            <InfoIcon className={formStyles.tooltipIcon} color={scssVariables.$darkGray} />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

type OrderPermissionOrderableCartGroupProps = {
  currentUser?: UserSubuser
  updateUser?: (value: UserSubuser) => void
  status?: CartOrderableStatus
  setStatus: Dispatch<SetStateAction<CartOrderableStatus | undefined>>
  allowedToOrder: boolean
  setAllowedToOrder: Dispatch<SetStateAction<boolean | undefined>>
  maxValueFullRange?: number | null
  setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
  maxValueFullRangeActive: boolean
  setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
  maxValueOrders?: number | null
  setMaxValueOrders: Dispatch<SetStateAction<number | null | undefined>>
  maxValueOrdersActive: boolean
  setMaxValueOrdersActive: Dispatch<SetStateAction<boolean>>
}

export const OrderPermissionOrderableCartGroup: FC<OrderPermissionOrderableCartGroupProps> = ({
  currentUser,
  updateUser,
  status,
  setStatus,
  allowedToOrder,
  setAllowedToOrder,
  maxValueFullRange,
  setMaxValueFullRange,
  maxValueFullRangeActive,
  setMaxValueFullRangeActive,
  maxValueOrders,
  setMaxValueOrders,
  maxValueOrdersActive,
  setMaxValueOrdersActive,
}) => {
  const { t } = useTranslation()
  //const cartTemplates = useEntities<CartTemplate>('carttemplates')
  // const offers = useEntities<OfferV2>('offersv2')
  // const [selectedEntities, setSelectedEntities] = useState<Array<string>>([])
  const { desktop } = useBreakpoints()
  const isReadOnlyUser = useUserManagementReadOnly()

  return (
    <CollapseComponent
      header={
        <div className={styles.titleWithTooltip}>
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CART_ORDERABLE')}
          {desktop && (
            <Tooltip
              title={t(
                'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_ORDERABLE'
              )}
            >
              <InfoIcon className={formStyles.tooltipIcon} color={scssVariables.$darkGray} />
            </Tooltip>
          )}
        </div>
      }
      withCheckbox
      parentCheckboxValue={allowedToOrder}
      ClassificationIcon={ShoppingCartIcon}
      onCheckboxChange={(value) => {
        setAllowedToOrder(value)
        setStatus(value ? CART_ORDERABLE_COMPLETE_RANGE : undefined)
        if (!value) {
          setMaxValueFullRange(undefined)
          setMaxValueFullRangeActive(false)
        }
      }}
      disabledCheckbox={isReadOnlyUser}
    >
      <CollapsibleContentContainer>
        <RadioGroup
          className={styles.radioContainer}
          value={allowedToOrder ? status : null}
          onChange={(event, value) => {
            setStatus(value as CartOrderableStatus)
            setMaxValueFullRange(currentUser?.permissions.Cart_maxCartValue)
            setMaxValueFullRangeActive(
              typeof currentUser?.permissions.Cart_maxCartValue === 'number'
            )
            setAllowedToOrder(true)
            // setSelectedEntities([])
          }}
        >
          <RadioButtonLine
            value={status ?? ''}
            disabled={isReadOnlyUser}
            label={t(
              'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_COMPLETE_RANGE'
            )}
            subLine={
              allowedToOrder &&
              status === CART_ORDERABLE_COMPLETE_RANGE && (
                <MaxValueLine
                  value={maxValueFullRange}
                  setValue={setMaxValueFullRange}
                  active={maxValueFullRangeActive}
                  setActive={setMaxValueFullRangeActive}
                  currentUser={currentUser}
                  updateUser={updateUser}
                  disabled={isReadOnlyUser}
                  tooltipText={t(
                    'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.ORDERABLE_COMPLETE_RANGE'
                  )}
                />
              )
            }
          />
          {/* TODO
           <RadioButtonDropdownLine
            value={CART_ORDERABLE_SELECTED_CART_TEMPLATES_ONLY}
            title={t(
              'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_SELECTED_CART_TEMPLATES_ONLY'
            )}
            options={cartTemplates.map(({ id, name }) => ({ id, title: name }))}
            onSelect={(option) => setSelectedEntities((previous) => [...previous, option.id])}
            subLine={
              status === CART_ORDERABLE_SELECTED_CART_TEMPLATES_ONLY && (
                <>
                  <MaxValueLine
                    value={maxValue}
                    setValue={setMaxValue}
                    active={maxValueActive}
                    setActive={setMaxValueActive}
                    alignGap
                    currentUser={currentUser}
                    updateUser={updateUser}
                  />
                  <BadgeContainer className={styles.selectedEntities}>
                    {selectedEntities.map((id) => {
                      const entity = cartTemplates.find((cartTemplate) => cartTemplate.id === id)
                      if (entity) return <Badge>{entity.name}</Badge>
                      return null
                    })}
                  </BadgeContainer>
                </>
              )
            }
          />*/}

          {/*{offers.length > 0 && (*/}
          {/*  <RadioButtonDropdownLine*/}
          {/*    value={CART_ORDERABLE_SELECTED_OFFERS_ONLY}*/}
          {/*    title={t(*/}
          {/*      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_SELECTED_OFFERS_ONLY'*/}
          {/*    )}*/}
          {/*    options={offers.map(({ id, offerName }) => ({ id, title: offerName || id }))}*/}
          {/*    onSelect={(option) => setSelectedEntities((previous) => [...previous, option.id])}*/}
          {/*    subLine={*/}
          {/*      status === CART_ORDERABLE_SELECTED_OFFERS_ONLY && (*/}
          {/*        <>*/}
          {/*          <MaxValueLine*/}
          {/*            value={maxValueOrders}*/}
          {/*            setValue={setMaxValueOrders}*/}
          {/*            active={maxValueOrdersActive}*/}
          {/*            setActive={setMaxValueOrdersActive}*/}
          {/*            alignGap*/}
          {/*            currentUser={currentUser}*/}
          {/*            updateUser={updateUser}*/}
          {/*          />*/}
          {/*          <BadgeContainer className={styles.selectedEntities}>*/}
          {/*            {selectedEntities.map((id) => {*/}
          {/*              const entity = offers.find((offer) => offer.id === id)*/}
          {/*              if (entity) return <Badge>{entity.offerName || entity.id}</Badge>*/}
          {/*              return null*/}
          {/*            })}*/}
          {/*          </BadgeContainer>*/}
          {/*        </>*/}
          {/*      )*/}
          {/*    }*/}
          {/*  />*/}
          {/*)}*/}
        </RadioGroup>
      </CollapsibleContentContainer>
    </CollapseComponent>
  )
}
