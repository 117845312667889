import { Category } from '@obeta/models/lib/models/Category'
import {
  ArticleSearchArea,
  ArticleSearchParamsWithId,
  ArticleSearchSupplier,
  Facet,
  FacetElement,
  SelectedEtimValue,
} from '@obeta/models/lib/models/Search'
import { Dispatch } from 'react'
import { withMd5Id } from '@obeta/utils/lib/genMd5Id'

type FilterType =
  | 'search-area'
  | 'suppliers'
  | 'category'
  | 'online-category'
  | 'packaging'
  | 'fundings'

interface Filter {
  id: FilterType
  value: string
}

export interface IState {
  searchParams: ArticleSearchParamsWithId
  activeEtimFeatureName: string | null
  selectedEtimValueByFeatureName: Record<string, SelectedEtimValue>
  filters: {
    data: Partial<Record<FilterType, Filter>>
    list: FilterType[]
  }
}

// TODO: pushScreen and back can be reused
export interface Action {
  type:
    | 'applyListPriceRange'
    | 'resetListPriceRange'
    | 'setSearchParams'
    | 'selectEtimClass'
    | 'selectEtimValue'
    | 'removeEtimAttribute'
    | 'selectEtimAttribute'
    | 'resetEtims'
    | 'resetSelectedEtimValue'
    | 'setFilter'
    | 'removeFilter'
    | 'selectSearchArea'
    | 'toggleSupplier'
    | 'setSuppliers'
    | 'setChosenCategory'
    | 'setChosenOnlineCategory'
    | 'setPackagingFilter'
    | 'abortApplyFilters'
    | 'setFundings'
    | 'resetSupplier'
}

interface ApplyListPriceRange extends Action {
  type: 'applyListPriceRange'
  payload: {
    minListPrice: number
    maxListPrice: number
  }
}

interface AbortApplyFilters extends Action {
  type: 'abortApplyFilters'
  payload: IState
}

interface ResetListPriceRange extends Action {
  type: 'resetListPriceRange'
}

interface SetSearchParamsAction extends Action {
  type: 'setSearchParams'
  payload: {
    searchParams: ArticleSearchParamsWithId
  }
}

interface SelectEtimAction extends Action {
  type: 'selectEtimClass'
  payload: {
    etim: FacetElement
  }
}

interface SetSelectEtimValueAction extends Action {
  type: 'selectEtimValue'
  payload: {
    attribute: Facet
    attributeValue: FacetElement
  }
}

interface SelectEtimValue extends Action {
  type: 'selectEtimAttribute'
  payload: {
    etimAttribute: Facet
  }
}

interface RemoveEtimAttribute extends Action {
  type: 'removeEtimAttribute'
  payload: {
    etimAttribute: Facet
  }
}

interface ResetEtimsAction extends Action {
  type: 'resetEtims'
}

interface ResetSelectedEtimValueAction extends Action {
  type: 'resetSelectedEtimValue'
  payload: {
    selectedValue: string | number
  }
}

/**
 * TODO: setFilter can be used as an example of how filters should be stored
 * in searchParams (global state that used to make requests for etims, facets and new ProductCard items).
 * For now I will keep it local.
 *
 * TODO: Use deprecated actions instead of SetFilter.
 * Deprecated actions instead of setting data to searchParams will do the same SetFilter does now.
 * It's easier to migrate, more flexible.
 */
interface SetFilter extends Action {
  type: 'setFilter'
  payload: {
    filter: Filter
  }
}

interface RemoveFilter extends Action {
  type: 'removeFilter'
  payload: {
    id: FilterType
  }
}

interface SetFundings extends Action {
  type: 'setFundings'
  payload: {
    fundings: string[] | null
  }
}

/**
 * @deprecated
 */
interface SelectSearchAreaAction extends Action {
  type: 'selectSearchArea'
  payload: {
    searchArea: ArticleSearchArea | null
  }
}

/**
 * @deprecated
 */
interface ToggleSupplierAction extends Action {
  type: 'toggleSupplier'
  payload: {
    supplier: ArticleSearchSupplier
  }
}

/**
 * @deprecated
 */
interface SetSuppliersAction extends Action {
  type: 'setSuppliers'
  payload: {
    suppliers: Record<string, ArticleSearchSupplier>
  }
}

/**
 * @deprecated
 */
export interface SetChosenCategory extends Action {
  type: 'setChosenCategory'
  payload: {
    type: keyof Pick<ArticleSearchParamsWithId, 'dehaCategory' | 'obetaCategory'>
    category: Category | null
  }
}

/**
 * @deprecated
 */
interface SetPackagingFilterAction extends Action {
  type: 'setPackagingFilter'
  payload: {
    sendable?: boolean
  }
}

interface ResetSupplierAction extends Action {
  type: 'resetSupplier'
  payload: {
    supplier: string
  }
}

export type FiltersActions =
  | ApplyListPriceRange
  | ResetListPriceRange
  | SetSearchParamsAction
  | SelectEtimAction
  | SetSelectEtimValueAction
  | SelectEtimValue
  | RemoveEtimAttribute
  | ResetEtimsAction
  | ResetSelectedEtimValueAction
  | SetFilter
  | RemoveFilter
  | SelectSearchAreaAction
  | ToggleSupplierAction
  | SetChosenCategory
  | SetSuppliersAction
  | SetPackagingFilterAction
  | AbortApplyFilters
  | SetFundings
  | ResetSupplierAction

/**
 * TODO: store filters in filtersState like { filters: [{ type: CategoryFilter }] }
 * it will be easier to apply these filters.
 * and map them to views
 *
 * TODO: we use md5 to compare searchParams objects. We need easier way co apply filters
 *
 * @param state
 * @param action
 * @returns
 */
export const filtersReducer = (state: IState, action: FiltersActions): IState => {
  switch (action.type) {
    case 'abortApplyFilters': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'applyListPriceRange': {
      const searchParams = {
        ...state.searchParams,
        minListPrice: action.payload.minListPrice,
        maxListPrice: action.payload.maxListPrice,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }

    case 'resetListPriceRange': {
      const searchParams = {
        ...state.searchParams,
        minListPrice: undefined,
        maxListPrice: undefined,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'setSearchParams': {
      return {
        ...state,
        searchParams: withMd5Id(action.payload.searchParams),
        selectedEtimValueByFeatureName: action.payload.searchParams.etim
          ? action.payload.searchParams.etim.reduce((obj, item) => {
              const key = item.facet?.name
              if (key) {
                obj[key] = item
              }
              return obj
            }, {})
          : {},
      }
    }
    case 'selectEtimClass': {
      let searchParams = state.searchParams
      const etim = action.payload.etim

      searchParams = withMd5Id({
        ...searchParams,
        selectedEtim: { id: etim.value, name: etim.meta.name, count: etim.count },
        etim: searchParams?.selectedEtim?.id === etim.value ? searchParams.etim : undefined,
      })

      return {
        ...state,
        searchParams,
      }
    }
    case 'selectEtimValue': {
      let searchParams = state.searchParams
      const activeEtimFeatureName = state.activeEtimFeatureName

      const val: SelectedEtimValue = {
        facet: action.payload.attribute,
        value: action.payload.attributeValue.value,
        count: action.payload.attributeValue.count,
      }

      const newEtim: SelectedEtimValue[] = searchParams.etim?.slice() || []

      const isActive =
        activeEtimFeatureName &&
        newEtim.find((subEl) => subEl.facet?.name === activeEtimFeatureName)
      if (isActive) {
        newEtim.splice(newEtim.indexOf(isActive, 1))
      }
      newEtim.push(val)

      searchParams = {
        ...searchParams,
        etim: newEtim,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
        selectedEtimValueByFeatureName: {
          ...state.selectedEtimValueByFeatureName,
          [action.payload.attribute.name]: val,
        },
      }
    }

    case 'removeEtimAttribute': {
      const etimAttributeKeyToRemove = action.payload.etimAttribute.name
      const { [etimAttributeKeyToRemove]: removeProp, ...nextSelectedEtimValueByFeatureName } =
        state.selectedEtimValueByFeatureName
      const searchParamsEtim = state.searchParams.etim ?? []
      const nextSearchParamsEtim = searchParamsEtim.filter(
        (etimElement) => etimElement.facet?.name !== etimAttributeKeyToRemove
      )

      return {
        ...state,
        searchParams: withMd5Id({ ...state.searchParams, etim: nextSearchParamsEtim }),
        selectedEtimValueByFeatureName: nextSelectedEtimValueByFeatureName,
      }
    }

    case 'selectEtimAttribute': {
      return {
        ...state,
        activeEtimFeatureName: action.payload.etimAttribute.name,
      }
    }
    case 'resetEtims': {
      const searchParams = {
        ...state.searchParams,
        etim: undefined,
        selectedEtim: undefined,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
        selectedEtimValueByFeatureName: {},
        activeEtimFeatureName: null,
      }
    }
    case 'resetSelectedEtimValue': {
      let selectedEtimsValues = state.searchParams.etim?.slice()
      if (!selectedEtimsValues) {
        return state
      }

      let found: SelectedEtimValue | undefined

      selectedEtimsValues = selectedEtimsValues.filter((val) => {
        const f = val.value === action.payload.selectedValue

        if (f) {
          found = val
        }

        return !f
      })

      const searchParams = {
        ...state.searchParams,
        etim: selectedEtimsValues,
      }

      const selected = { ...state.selectedEtimValueByFeatureName }
      if (found?.facet) {
        delete selected[found.facet?.name]
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
        selectedEtimValueByFeatureName: selected,
      }
    }
    case 'setFilter': {
      const filters = {
        ...state.filters,
      }

      filters.data = {
        ...filters.data,
      }

      filters.data[action.payload.filter.id] = action.payload.filter

      // remove (to make sure it's not duplicated) and add filterType to list array again
      filters.list = filters.list.filter((fType) => {
        return fType !== action.payload.filter.id
      })
      filters.list.push(action.payload.filter.id)

      return {
        ...state,
        filters,
      }
    }
    case 'removeFilter': {
      if (!state.filters.data[action.payload.id]) {
        // no such filter
        return state
      }

      const filters = {
        ...state.filters,
      }

      filters.data = {
        ...filters.data,
      }
      delete filters.data[action.payload.id]

      filters.list = filters.list.filter((fType) => {
        return fType !== action.payload.id
      })

      return {
        ...state,
        filters,
      }
    }
    case 'selectSearchArea': {
      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        articleSearchArea: action.payload.searchArea || ArticleSearchArea.ALL,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'toggleSupplier': {
      const sup = action.payload.supplier

      const newSuppliers = { ...state.searchParams.suppliers }
      if (newSuppliers[sup.id]) {
        delete newSuppliers[sup.id]
      } else {
        newSuppliers[sup.id] = sup
      }

      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        suppliers: newSuppliers,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'setSuppliers': {
      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        suppliers: action.payload.suppliers,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'resetSupplier': {
      const suppliers = { ...state.searchParams.suppliers }
      if (!suppliers) return { ...state }
      delete suppliers[action.payload.supplier]
      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        suppliers,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'setChosenCategory': {
      const catProp = action.payload.type

      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        [catProp]: action.payload.category,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'setPackagingFilter': {
      const searchParams = {
        ...state.searchParams,
        sendable: action.payload.sendable,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    case 'setFundings': {
      const searchParams: ArticleSearchParamsWithId = {
        ...state.searchParams,
        fundings: action.payload.fundings ?? undefined,
      }

      return {
        ...state,
        searchParams: withMd5Id(searchParams),
      }
    }
    default: {
      return state
    }
  }
}

export type DispatchFiltersAction = Dispatch<FiltersActions>
