import { createContext, useContext, useState } from 'react'

type UserOverviewSortingOrder = 'asc' | 'desc'
/* eslint-disable @typescript-eslint/no-explicit-any */
export type UserOverviewSortingOrderBy = keyof Pick<Record<string, any>, 'name' | 'customerNumber'>
export type UserOverviewSorting = {
  orderBy?: UserOverviewSortingOrderBy
  order?: UserOverviewSortingOrder
}

interface UserOverviewProviderContextValue {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  sorting: UserOverviewSorting
  setSorting: (sorting: UserOverviewSorting) => void
}

const DEFAULT_SORTING: UserOverviewSorting = { orderBy: undefined, order: undefined }

const noop = () => {
  //
}
const UserOverviewContext = createContext<UserOverviewProviderContextValue>({
  searchTerm: '',
  sorting: DEFAULT_SORTING,
  setSearchTerm: noop,
  setSorting: noop,
})

export const UserOverviewProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sorting, setSorting] = useState<UserOverviewSorting>(DEFAULT_SORTING)

  return (
    <UserOverviewContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        sorting,
        setSorting,
      }}
    >
      {children}
    </UserOverviewContext.Provider>
  )
}

export const useUserOverviewContext = () => {
  return useContext(UserOverviewContext)
}
