import { useCallback, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'

// Models
import {
  OrderShippingDetail,
  GetOrderShippingDetailsInput,
  GetOrderShippingDetailsQuery,
  GetOrderShippingDetailsQueryVariables,
} from '@obeta/schema'

// Utils
import { trackCustom } from '@obeta/utils/lib/tracking'

export const GET_ORDER_SHIPPING_DETAILS_QUERY = gql`
  query getOrderShippingDetails($input: GetOrderShippingDetailsInput!) {
    getOrderShippingDetails(input: $input) {
      success
      errorMessage
      errorCode
      orderShippingDetails {
        orderState {
          type
          count
        }
        createdAt
        packages
        invoiceId
        invoiceUrl
        deliverySlipUrl
        deliverySlipId
        deliverySlipPrintable
        packageTrackingInfos {
          link
          provider
        }
        trackingInfos
        positionCount
      }
    }
  }
`

export const useGetOrderShippingDetails = () => {
  const client = useApolloClient()

  const [orderShippingDetails, setOrderShippingDetails] = useState<OrderShippingDetail[] | null>(
    null
  )

  /**
   * Handler to search orders by input variables.
   * @param input OrdersInput
   */
  const getOrderShippingDetails = useCallback(
    async (input: GetOrderShippingDetailsInput) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await client.query<
        GetOrderShippingDetailsQuery,
        GetOrderShippingDetailsQueryVariables
      >({
        query: GET_ORDER_SHIPPING_DETAILS_QUERY,
        variables: {
          input: input,
        },
        fetchPolicy: 'no-cache',
      })

      const data = response.data.getOrderShippingDetails

      if (data.success) {
        trackCustom('get-order-shipping-details', {
          request: input,
          response: data.orderShippingDetails,
        })

        if (data.orderShippingDetails) {
          setOrderShippingDetails(data.orderShippingDetails as OrderShippingDetail[])
        }
      } else {
        trackCustom('get-order-shipping-details-error', data)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  )

  return {
    orderShippingDetails,
    getOrderShippingDetails,
  }
}
