import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import styles from './ShoppingCartCheckoutBilling.module.scss'
import { useTranslation } from 'react-i18next'
import { ContentMask } from './ContentMask'
import { ShoppingCartPaymentMethods } from './ShoppingCartPaymentMethods'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { Control } from 'react-hook-form'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { PaymentMethod } from '@obeta/schema'
import { WarningSelectAnotherPayment } from './WarningSelectAnotherPayment'
import { IsPaymentMethodPermittedContext } from '@obeta/data/lib/hooks/useIsPaymentMethodPermittedProvider'

interface Props {
  userIsCashCustomer: boolean
  isDeactivated?: boolean
  hidePaymentMethods?: boolean
  control: Control
  shippingGroup: ShippingGroup
  activePaymentMethods: PaymentMethod[]
  selectedPaymentMethod: PaymentMethod
  updatePaymentMethodInCart: (paymentMethod: PaymentMethod) => void
  orderSubmitted?: boolean
}
export const ShoppingCartCheckoutBilling: React.FC<Props> = ({
  userIsCashCustomer,
  isDeactivated,
  hidePaymentMethods,
  control,
  shippingGroup,
  activePaymentMethods,
  selectedPaymentMethod,
  updatePaymentMethodInCart,
  orderSubmitted,
}) => {
  const { t } = useTranslation()
  const isPaymentProviderEnabled = useFeatureToggle('UsePaymentProvider')
  const { setIsSelectedPaymentMethodPermitted } = useContext(IsPaymentMethodPermittedContext)
  const selectedPaymentNotPermitted =
    activePaymentMethods.filter((paymentMethod) => paymentMethod === selectedPaymentMethod)
      .length === 0
  setIsSelectedPaymentMethodPermitted(!selectedPaymentNotPermitted)

  return (
    <div className={styles.billing}>
      {isDeactivated && <ContentMask />}
      {isPaymentProviderEnabled && hidePaymentMethods ? null : (
        <>
          <div className={styles.payments}>
            <Typography variant={'h4'}>{t('SHOPPING_CART.CHECKOUT.PAYMENTS')}</Typography>
            {isPaymentProviderEnabled && selectedPaymentNotPermitted && !orderSubmitted && (
              <WarningSelectAnotherPayment />
            )}
          </div>
          <div>
            {isPaymentProviderEnabled ? (
              <ShoppingCartPaymentMethods
                selectedPaymentMethod={selectedPaymentMethod}
                control={control}
                shippingGroup={shippingGroup}
                activeMethods={activePaymentMethods}
                updatePaymentMethod={updatePaymentMethodInCart}
                userIsCashCustomer={userIsCashCustomer}
              />
            ) : (
              <Typography variant={'body'}>
                {userIsCashCustomer
                  ? t('SHOPPING_CART.CHECKOUT.CASH')
                  : t('SHOPPING_CART.CHECKOUT.INVOICE')}
              </Typography>
            )}
          </div>
        </>
      )}
      <div className={styles.requiredText}>
        <Typography variant="smallText">*{t('SHOPPING_CART.CHECKOUT.REQUIRED_FIELD')}</Typography>
      </div>
    </div>
  )
}
