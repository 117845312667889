import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { FormProgress } from '@obeta/components/lib/form-progress/FormProgress'
import { DarkButton, TertiaryIconButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as ChevronLeft } from 'assets/icon/designsystem/chevron_left.svg'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { useCreateUserContext } from './useCreateUserContext'
import { CreateUserFormStepOne } from './CreateUserFormStepOne'
import { CreateUserFormStepTwo } from './CreateUserFormStepTwo'
import { CreateUserFormStepThree } from './CreateUserFormStepThree'
import styles from './CreateUserDialog.module.scss'
import { UserManagementInfoForTouchDevices } from '../../userManagementPage/form/UserManagementInfoForTouchDevices'

export const CreateUserForm: FC<{ onClose: () => void; refetchUsers: () => void }> = ({
  onClose,
  refetchUsers,
}) => {
  const { currentStepIndex, setCurrentStepIndex, canProceed, createUser } = useCreateUserContext()
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  const nextStep = useCallback(() => setCurrentStepIndex((prev) => prev + 1), [setCurrentStepIndex])
  const prevStep = useCallback(() => setCurrentStepIndex((prev) => prev - 1), [setCurrentStepIndex])
  const createUserAndCloseDialog = useCallback(async () => {
    const didCreateUser = await createUser()
    if (!didCreateUser) return
    await refetchUsers()
    onClose()
  }, [createUser, onClose, refetchUsers])

  const isFirstStep = currentStepIndex === 0
  const isSecondStep = currentStepIndex === 1
  const isLastStep = currentStepIndex === 2

  const submit = isLastStep ? createUserAndCloseDialog : nextStep

  return (
    <>
      <FormProgress
        steps={[
          t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_ONE.TITLE'),
          t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_TWO.TITLE'),
          t('USER_MANAGEMENT.CREATE_USER_DIALOG.STEP_THREE.TITLE'),
        ]}
        activeStep={currentStepIndex}
        className={clsx(styles.progressBar, mobile && styles.dynamicWidth)}
      />
      {isFirstStep && <CreateUserFormStepOne />}
      {isSecondStep && <CreateUserFormStepTwo />}
      {isLastStep && <CreateUserFormStepThree />}
      {!isFirstStep && <UserManagementInfoForTouchDevices />}
      <div className={clsx(styles.actions, isFirstStep && styles.firstStep)}>
        {!isFirstStep && (
          <TertiaryIconButton icon={<ChevronLeft />} onClick={prevStep}>
            {t('USER_MANAGEMENT.CREATE_USER_DIALOG.BACK')}
          </TertiaryIconButton>
        )}
        <DarkButton
          fullWidth={false}
          rightIcon={isLastStep ? <CheckIcon /> : <ChevronRight />}
          disabled={!canProceed()}
          onClick={submit}
        >
          {t(
            isLastStep
              ? 'USER_MANAGEMENT.CREATE_USER_DIALOG.SAVE'
              : 'USER_MANAGEMENT.CREATE_USER_DIALOG.NEXT'
          )}
        </DarkButton>
      </div>
    </>
  )
}
