import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PopoverOrigin, SvgIcon } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'
import { ReactComponent as PrintIcon } from 'assets/icon/designsystem/print.svg'
import { ReactComponent as TrashIcon } from 'assets/icon/designsystem/delete_outline.svg'
import clsx from 'clsx'

// Actions
import {
  addCartTemplateItemsToCartGraphQL,
  AddCartTemplateItemsToCartGraphQLActionResult,
  CartsActionTypes,
} from '@obeta/data/lib/actions'

// Components
import { IdsTransferActionButton } from '../ids/IdsTransferActionButton'

// Hooks
import { CartTemplateDetailsContext } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'
import { useActionNotification } from '@obeta/data/lib/hooks'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useIdsBulkTransferBack } from '@obeta/data/lib/hooks/ids/useIdsBulkTransferBack'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'

// Models
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { ScannerListType } from '@obeta/models/lib/models/PrintService/PrintInput'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'

// Styles
import styles from './CartTemplateDetailsSelectionBar.module.scss'

// UI
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import {
  DropdownTemplates,
  IDropdownTemplateBaseProps,
} from '../dropdown-templates/DropdownTemplates'
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'
import { ScannerList } from '../scannerlist/ScannerList'
import { SelectionBar } from '../selection-bar/SelectionBar'
import { AnimatedCartButton } from '../add-items-to-cart-button/AnimatedCartButton'
import { SplitButton } from '../split-button/SplitButton'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import {
  EButtonState,
  useAnimatedButtonState,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'

interface Props {
  carts: ShoppingCartV2[]
  deleteCartTemplateItems: () => void
  dropDownCarts
  cartTemplateId
  cartTemplateName
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

export const CartTemplateDetailsSelectionBar: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { mobile, tablet, tabletAll, tabletWide, desktop } = useBreakpoints()
  const splitButtonRef = useRef<HTMLDivElement>(null)
  const popoverState = usePopoverState(splitButtonRef)
  const { t } = useTranslation()
  const { state, getSelectedCartTemplateItemsLength, resetSelectedItems } = useContext(
    CartTemplateDetailsContext
  )
  const [isScannerlistOpen, setIsScannerListOpen] = useState(false)

  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')
  const { setAnimationState: setButtonState, animationState } = useAnimatedButtonState()

  const { carts, cartTemplateId, cartTemplateName, deleteCartTemplateItems, dropDownCarts } = props
  const productsToAdd: IDropdownTemplateBaseProps['productsToAdd'] = []

  const bulkTransferIds = useIdsBulkTransferBack('cartTemplate')

  const userV2 = useUserV2()
  const defaultCartId = userV2?.settings?.defaultCartId ?? ''
  const defaultCart = carts.find((cart) => cart.id === defaultCartId)

  const selectedCartTemplateItemsLength = getSelectedCartTemplateItemsLength()
  const disabled = selectedCartTemplateItemsLength === 0

  const showIdsTransferBackButton = useIsIdsTransferBackPossible()

  const getListOfCartTemplateIdsAsListOfProductIds = (
    cartTemplateItemList: (string | number)[]
  ): string[] => {
    const modifiedList: string[] = []
    if (cartTemplateItemList.length > 0) {
      cartTemplateItemList.forEach((cartTemplateItemId) => {
        const cartTemplateItem = state.items.find((item) => item.id === cartTemplateItemId)
        if (cartTemplateItem) {
          modifiedList.push(cartTemplateItem.productId)
        }
      })
    }
    return modifiedList
  }

  const waitForAddCartTemplateItemsToCartResult = useActionNotification(
    CartsActionTypes.AddCartTemplateItemsToCartGraphQLResult
  )
  const { getSessionCartName, isSessionCart } = useSessionCartName()

  const addMultipleCartTemplateItemsToCart = (cart) => {
    if (isAddToCartProcessIndicator) {
      setButtonState(EButtonState.Loading)
    }
    const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
    dispatch(
      addCartTemplateItemsToCartGraphQL(
        cart.id,
        cartName,
        cartTemplateId,
        cartTemplateName,
        // both include und exclude lists contain cartTemplateItem ids, we need sapIds for this operation however
        getListOfCartTemplateIdsAsListOfProductIds(state.selectedCartTemplateItems.include),
        getListOfCartTemplateIdsAsListOfProductIds(state.selectedCartTemplateItems.exclude),
        {
          searchTerm: state.searchTerm,
          orderBy: '',
          orderDir: '',
          filter: '',
        },
        selectedCartTemplateItemsLength
      )
    )
    waitForAddCartTemplateItemsToCartResult(
      (action: AddCartTemplateItemsToCartGraphQLActionResult) => {
        if (isAddToCartProcessIndicator) {
          setButtonState(action.success ? EButtonState.Success : EButtonState.Error)
        }
        if (action.success) {
          resetSelectedItems()
        }
      }
    )
  }
  let cartName = '-'
  if (defaultCart) {
    cartName = isSessionCart(defaultCart) ? getSessionCartName(defaultCart) : defaultCart.name
  }
  return (
    <SelectionBar portalKey="cart-template-details-selection-bar">
      <div className={styles.selectionBar}>
        <Grid className={styles.selectionBarItem} size={{ xs: 8 }}>
          <DropdownTemplates
            anchorOrigin={anchorOrigin}
            desktop={desktop}
            disabled={disabled}
            mobile={mobile}
            originCartTemplate={{
              cartTemplateId: cartTemplateId,
              cartTemplateName,
              selectedItemCount: selectedCartTemplateItemsLength,
              multiSelection: {
                ...state.selectedCartTemplateItems,
                // both include und exclude lists contain cartTemplateItem ids, we need sapIds for this operation however
                include: getListOfCartTemplateIdsAsListOfProductIds(
                  state.selectedCartTemplateItems.include
                ),
                exclude: getListOfCartTemplateIdsAsListOfProductIds(
                  state.selectedCartTemplateItems.exclude
                ),
              },
            }}
            productsToAdd={productsToAdd}
            selectedArticleCount={selectedCartTemplateItemsLength}
            templatesType={DropdownTemplatesType.ADD_TEMPLATE_ITEMS}
            transformOrigin={transformOrigin}
            withZeroBasedArticleCount={true}
          />
          {!mobile && (
            <SecondaryButton
              disabled={disabled}
              leftIcon={<PrintIcon />}
              onClick={() => {
                setIsScannerListOpen(true)
              }}
            >
              {`${t('TEMPLATES.PRINT_LABELS')} (${selectedCartTemplateItemsLength})`}
            </SecondaryButton>
          )}
          {isScannerlistOpen && (
            <ScannerList
              childIds={state.selectedCartTemplateItems?.include}
              childIdsExcluded={state.selectedCartTemplateItems?.exclude}
              type={ScannerListType.CartTemplate}
              parentId={cartTemplateId}
              isOpen={isScannerlistOpen}
              onClose={() => setIsScannerListOpen(false)}
            />
          )}
          <TertiaryButton
            fullWidth={false}
            size={'small'}
            leftIcon={<SvgIcon component={TrashIcon} />}
            onClick={deleteCartTemplateItems}
            disabled={disabled}
          >
            {(desktop || tabletWide || tablet) &&
              t('TEMPLATES.DETAILS_ACTIONS.DELETE', {
                selectedArticleCount: selectedCartTemplateItemsLength,
              })}
            {mobile && `(${selectedCartTemplateItemsLength})`}
          </TertiaryButton>
        </Grid>
        <Grid className={clsx(styles.selectionBarItem, styles.selectionBarCart)} size={{ xs: 4 }}>
          {mobile &&
            (isAddToCartProcessIndicator ? (
              <AnimatedCartButton
                disabled={!selectedCartTemplateItemsLength}
                buttonState={animationState}
                onClick={() => addMultipleCartTemplateItemsToCart(defaultCart)}
              />
            ) : (
              <PrimaryButton
                disabled={!selectedCartTemplateItemsLength}
                leftIcon={<AddShoppingCartIcon />}
                onClick={() => {
                  addMultipleCartTemplateItemsToCart(defaultCart)
                }}
              />
            ))}
          {(tabletAll || desktop) && (
            <>
              {showIdsTransferBackButton && (
                <IdsTransferActionButton
                  onClick={() =>
                    bulkTransferIds({
                      cartTemplateId,
                      include: getListOfCartTemplateIdsAsListOfProductIds(
                        state.selectedCartTemplateItems.include
                      ),
                      exclude: getListOfCartTemplateIdsAsListOfProductIds(
                        state.selectedCartTemplateItems.exclude
                      ),
                      search: {
                        searchTerm: state.searchTerm,
                        orderBy: '',
                        orderDir: '',
                        filter: '',
                      },
                    })
                  }
                  amountOfProducts={selectedCartTemplateItemsLength}
                  disabled={selectedCartTemplateItemsLength === 0}
                />
              )}
              {isAddToCartProcessIndicator ? (
                <AnimatedCartButton
                  ref={splitButtonRef}
                  disabled={!selectedCartTemplateItemsLength}
                  cartsLength={carts.length}
                  title={cartName}
                  buttonState={animationState}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => addMultipleCartTemplateItemsToCart(defaultCart)}
                  size={mobile ? 'large' : 'small'}
                />
              ) : carts.length > 1 ? (
                <SplitButton
                  disabled={!selectedCartTemplateItemsLength}
                  loading={false}
                  size={mobile ? 'large' : 'small'}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => addMultipleCartTemplateItemsToCart(defaultCart)}
                />
              ) : (
                <PrimaryButton
                  disabled={!selectedCartTemplateItemsLength}
                  leftIcon={<AddShoppingCartIcon />}
                  size={mobile ? 'large' : 'small'}
                  onClick={() => addMultipleCartTemplateItemsToCart(defaultCart)}
                >
                  {cartName}
                </PrimaryButton>
              )}
              <DropdownCartsBase
                carts={dropDownCarts}
                mobile={mobile}
                onOptionSelected={(cart) => {
                  addMultipleCartTemplateItemsToCart(cart)
                }}
                dropdown={{
                  anchorEl: mobile ? null : popoverState.anchorEl,
                  onClose: popoverState.onClose,
                  open: popoverState.open,
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  transformOrigin: { vertical: 'bottom', horizontal: 'right' },
                }}
              />
            </>
          )}
        </Grid>
      </div>
    </SelectionBar>
  )
}
