import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { SaveChangesDialog } from './SaveChangesDialog'

type Setter<T> = Dispatch<SetStateAction<T>>

type SaveChangesDialogType = {
  onDialogSubmit?: () => void
  open: boolean
}
type UserManagementChangeValue = {
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: Setter<boolean>
  setSaveChangesDialog: Setter<SaveChangesDialogType> // TODO update when it works
}
const noop = () => {
  //
}
const defaultValue = {
  hasUnsavedChanges: false,
  setHasUnsavedChanges: noop,
  setSaveChangesDialog: noop,
}

const UserManagementChangeContext = createContext<UserManagementChangeValue>(defaultValue)

export const UserManagementChangeHandler = ({ children }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [saveChangesDialog, setSaveChangesDialog] = useState<SaveChangesDialogType>({
    onDialogSubmit: undefined,
    open: false,
  })

  const resetSaveChangesDialog = () => {
    setSaveChangesDialog({
      onDialogSubmit: undefined,
      open: false,
    })
  }

  const showSaveChangesDialog = hasUnsavedChanges && saveChangesDialog.open
  return (
    <UserManagementChangeContext.Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setSaveChangesDialog,
      }}
    >
      {showSaveChangesDialog && (
        <SaveChangesDialog
          open={showSaveChangesDialog}
          onSubmit={() => {
            if (saveChangesDialog.onDialogSubmit) {
              saveChangesDialog.onDialogSubmit()
            }
            resetSaveChangesDialog()
          }}
          onClose={resetSaveChangesDialog}
          onDestroy={resetSaveChangesDialog}
        />
      )}
      {children}
    </UserManagementChangeContext.Provider>
  )
}

export const useUserManagementChangeHandler = () => {
  return useContext(UserManagementChangeContext)
}
