import React, { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogContent, DialogTitle, SvgIcon, Typography } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './CreateUserDialog.module.scss'
import { CreateUserForm } from './CreateUserForm'

export const CreateUserDialog: FC<{
  open: boolean
  onClose: () => void
  refetchUsers: () => void
}> = ({ open, onClose, refetchUsers }) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  return (
    <Dialog
      PaperProps={{ className: clsx(styles.paper, mobile && styles.paperMobile) }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={clsx(styles.dialog, mobile && styles.mobileDialog)}
      fullScreen={mobile}
      fullWidth={mobile}
    >
      <DialogTitle className={styles.title}>
        <Typography variant="boldText">{t('USER_MANAGEMENT.CREATE_USER_DIALOG.TITLE')}</Typography>
        <Button
          className={styles.close}
          variant="tertiary"
          startIcon={<SvgIcon component={CloseIcon} color="secondary" />}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent className={clsx(styles.content, styles.createUserDialogContent)}>
        <CreateUserForm onClose={onClose} refetchUsers={refetchUsers} />
      </DialogContent>
    </Dialog>
  )
}
