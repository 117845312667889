export type PullToRefreshStoreValue = {
  state: 'idle' | 'listening' | 'pulling' | 'pulled' | 'refreshing'
  touchId: number
  initialY: number
  progress: number
}

const INITIAL_STATE: PullToRefreshStoreValue = {
  state: 'idle',
  touchId: -1,
  initialY: 0,
  progress: 0,
}

export class PullToRefreshStore {
  private state: PullToRefreshStoreValue = INITIAL_STATE

  private listeners: (() => void)[] = []

  public getState() {
    return this.state
  }

  public setState(state: PullToRefreshStoreValue) {
    this.state = state
    this.listeners.forEach((listener) => listener())
  }

  public subscribe(listener: () => void) {
    this.listeners.push(listener)

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener)
    }
  }

  public reset() {
    this.setState(INITIAL_STATE)
  }
}
