import { useEffect, useState } from 'react'
import { PullToRefreshStore } from './store'

export function usePullToRefreshState({ store }: { store: PullToRefreshStore }) {
  const [state, setState] = useState(() => store.getState())

  useEffect(() => {
    return store.subscribe(() => setState(store.getState()))
  }, [store])

  return state
}
