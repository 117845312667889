import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import styles from './TitleSection.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

export const TitleSection = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useUserDataV2()
  const additionalText = isLoggedIn ? 'unsere aktuelle Monatswerbung sowie ' : ''

  return (
    <div className={styles.titleSectionWrapper}>
      <Typography variant="headline2Bold">{t('START.TEASERS')}</Typography>
      <Typography variant="body">
        Hier finden Sie {additionalText}weitere Informationen zu unseren Empfehlungen. Profitieren
        Sie von exklusiven Rabatten und Sonderangeboten. Durchstöbern Sie jetzt unsere Empfehlungen
        und sichern Sie sich unschlagbare Preise.
      </Typography>
    </div>
  )
}
