import { useContext, useEffect } from 'react'
import { PullToRefreshContext } from './context'
import { useEventCallback } from '@mui/material/utils'

export type UsePullToRefreshProps = {
  onRefresh: () => Promise<void>
}

export function usePullToRefresh(props: UsePullToRefreshProps) {
  const handleRefresh = useEventCallback(props.onRefresh)

  const ctx = useContext(PullToRefreshContext)

  const register = ctx?.register

  useEffect(() => {
    return register?.(handleRefresh)
  }, [register, handleRefresh])
}
