import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { VirtualizedList } from './VirtualizedList'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { PaginationList } from './PaginationList'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useArticlesAmounts } from '@obeta/data/lib/hooks/useArticlesAmounts'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import React, { useEffect, useRef } from 'react'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { sendClickedObjectIdAfterSearchEvent } from '@obeta/utils/lib/algoliaClickEvents'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'

type ISearchListProps = WithBreakpoints<'desktop' | 'mobile' | 'tabletAll'> & {
  onAddToCart?: (cart: ShoppingCartV2) => void
  focusFirstSearchResult?: boolean
}

export const SearchList: React.FC<ISearchListProps> = React.memo((props) => {
  const { desktop, mobile, tabletAll, onAddToCart, focusFirstSearchResult = true } = props
  const { selectedStore, user } = useUserSelectedStore()
  const { setAmount, getAmount } = useArticlesAmounts()
  const { searchParams } = useArticlesSearchProvider()
  const firstRender = useRef(true)

  useEffect(() => {
    //if the searchparams change, then we can assume the search changed and the user wants to start from the top
    if (!firstRender.current) {
      window.scrollTo({ top: 0 })
    }
    firstRender.current = false
  }, [searchParams?.id])

  const history = useHistory()
  /**
   * need to make sure that onItemClick always stays the same and doesn't break
   * PaginationList, VirtualizedList memos
   */
  const onItemClick = useRef(
    (product: ProductAggregate, queryId: string, index: number, algoliaUserToken: string) => {
      history.push(`${ShopRoutes.ArticleDetailPage}/${product.sapId}?amount=${getAmount(product)}`)

      if (algoliaUserToken) {
        sendClickedObjectIdAfterSearchEvent(algoliaUserToken, queryId, product.sapId, index)
      }
    }
  )

  // TODO: memo for Pagination List and Virtualized List ?
  if (desktop) {
    return (
      <PaginationList
        setProductAmount={setAmount}
        onItemClick={onItemClick.current}
        selectedStore={selectedStore}
        user={user}
        onAddToCart={onAddToCart}
        focusFirstSearchResult={focusFirstSearchResult}
      />
    )
  }

  return (
    <VirtualizedList
      setProductAmount={setAmount}
      mobile={mobile}
      tabletAll={tabletAll}
      selectedStore={selectedStore}
      user={user}
      onItemClick={onItemClick.current}
      onAddToCart={onAddToCart}
    />
  )
})
