import clsx from 'clsx'
import { useContext } from 'react'

import { ReactComponent as RefreshIcon } from '@obeta/assets/icon/designsystem/refresh.svg'

import { PullToRefreshContext } from './context'
import { PullToRefreshStore, PullToRefreshStoreValue } from './store'
import { usePullToRefreshState } from './use-pull-to-refresh-state'

import styles from './PullToRefreshContent.module.scss'

export function PullToRefreshContent() {
  const context = useContext(PullToRefreshContext)

  if (!context?.enabled) {
    return null
  }

  return <PullToRefreshContentInner store={context.store} />
}

function PullToRefreshContentInner({ store }: { store: PullToRefreshStore }) {
  const state = usePullToRefreshState({ store })

  return (
    <div className={clsx(styles.root, styles[state.state])} style={getRootStyle(state)}>
      <RefreshIcon className={styles.icon} style={getIconStyle(state)} />
    </div>
  )
}

function getRootStyle(state: PullToRefreshStoreValue) {
  return {
    transform: getRootTransform(state),
    opacity: getRootOpacity(state),
  }
}

function getIconStyle(state: PullToRefreshStoreValue) {
  return {
    transform: getIconTransform(state),
  }
}

function getRootTransform(state: PullToRefreshStoreValue) {
  switch (state.state) {
    case 'pulled':
    case 'refreshing':
      return 'translateY(-60px)'
    case 'pulling':
      return `translateY(${-state.progress * 30 - 30}px)`
    default:
      return 'translateY(-60px)'
  }
}

function getRootOpacity(state: PullToRefreshStoreValue) {
  switch (state.state) {
    case 'pulled':
    case 'refreshing':
      return 1
    case 'pulling':
      return Math.min(1, state.progress * 1.5)
    default:
      return 0
  }
}

function getIconTransform(state: PullToRefreshStoreValue) {
  switch (state.state) {
    case 'pulling':
      return `rotate(${180 + state.progress * 180}deg)`
    default:
      return undefined
  }
}
