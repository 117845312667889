import { FC, ReactNode, useState } from 'react'
import { MenuItem, Select, Typography, useTheme } from '@mui/material'

// Hooks
import { useWindowScroll } from '@obeta/data/lib/hooks/useWindowEvent'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'

// Styles
import styles from './Dropdown.module.scss'
import clsx from 'clsx'

// Constants
const DROPDOWN_WIDTH = '10rem'

type Dropdown2Props = {
  justifyBetween?: boolean
  label?: string
  options: DropdownOption[]
  renderValue?: () => ReactNode
  value?: number | string
  width?: string
  onChange: (value: number | string) => void
}

export const Dropdown2: FC<Dropdown2Props> = (props) => {
  const { justifyBetween, label, options, renderValue, value, width, onChange } = props

  const theme = useTheme()

  // Component state
  const [open, setOpen] = useState<boolean>(false)

  // Close dropdown on window scroll
  useWindowScroll(() => {
    open && setOpen(false)
  }, [open])

  return (
    <div className={clsx(styles.dropdown, justifyBetween && styles.dropdownJustifyBetween)}>
      {label && <Typography typography="body">{label}</Typography>}
      <Select
        displayEmpty={Boolean(renderValue)}
        renderValue={renderValue}
        sx={{
          // Not working with width attribute
          maxWidth: width ?? DROPDOWN_WIDTH,
          minWidth: width ?? DROPDOWN_WIDTH,
          '.MuiSelect-outlined': {
            width: '100%',
          },
          // Not working with css
          '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.grayVariant.light },
        }}
        MenuProps={{
          sx: {
            width: width ?? DROPDOWN_WIDTH,
          },
        }}
        open={open}
        SelectDisplayProps={{
          className: styles.dropdownElement,
        }}
        value={value}
        variant="outlined"
        onChange={(event) => onChange(event.target.value)}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            classes={{
              root: styles.menuItem,
            }}
            selected={option.value === value}
            value={option.value}
          >
            <Typography
              className={styles.menuItemTitle}
              variant={value === option.value ? 'bodyBold' : undefined}
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
