import { FC } from 'react'
import { Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './Badge.module.scss'
import clsx from 'clsx'

type BadgeProps = {
  onRemove?: () => void
  disabled?: boolean
}
type BadgeContainerProps = { className?: string }

export const BadgeContainer: FC<BadgeContainerProps> = ({ children, className }) => (
  <div className={clsx(styles.multipleBadges, className)}>{children}</div>
)

export const Badge: FC<BadgeProps> = ({ children, disabled, onRemove }) => {
  return (
    <div className={styles.badgeWrapper}>
      <div className={styles.badge}>
        <div>
          <Typography>{children}</Typography>
        </div>
        <CloseIcon onClick={!disabled ? onRemove : () => 0} className={styles.badgeCloseIcon} />
      </div>
    </div>
  )
}
