import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import styles from './AccountDetailsAdminOnSubuser.module.scss'
import { Box, Link, SvgIcon, TextField, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import {
  useTriggerEmailTokenRefresh,
  useTriggerPasswordReset,
} from '@obeta/data/lib/hooks/useUserUpdate'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { buildUserIdByUser } from '@obeta/utils/lib/buildUserId'

interface IAccountDetailsFields {
  handleLinkClick
  handlePasswordResetClick
  isLoggedInUser: boolean
}
type AccountDetailsAdminOnSubuserProps = {
  email: string
  name: string
  setEmail: Dispatch<SetStateAction<string>>
  setName: Dispatch<SetStateAction<string>>
  emailError: string
  setEmailError: Dispatch<SetStateAction<string>>
  isLoggedInUser: boolean
  currentUser: UserSubuser
}

export const AccountDetailsFields: FC<IAccountDetailsFields & AccountDetailsAdminOnSubuserProps> = (
  props
) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    handleLinkClick,
    handlePasswordResetClick,
    isLoggedInUser,
    name,
    setName,
    emailError,
    setEmailError,
    currentUser,
    email,
    setEmail,
  } = props
  const customerId = buildUserIdByUser(currentUser, true)

  const setEmailErrorInternal = (value) => {
    if (!value) {
      setEmailError('required')
      return
    } else if (!isValidEmail(value)) {
      setEmailError('validate')
      return
    }
    setEmailError('')
  }

  useEffect(() => {
    if (!email || email.length === 0) {
      setEmailError('required')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <form>
        <Box className={styles.fieldRow}>
          <div className={styles.formFieldWrapper}>
            <Typography variant={'body'}>
              {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.NAME')}
            </Typography>

            <TextField
              className={styles.textInput}
              variant="outlined"
              id="username"
              name="username"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <div className={styles.formFieldWrapper}>
            <Typography variant={'body'}>
              {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS')}
            </Typography>
            <TextField
              error={emailError.length > 0}
              className={styles.textInput}
              variant="outlined"
              id="email"
              name="email"
              value={email}
              onChange={(event) => {
                const value = event.target.value
                setEmail(value)
                setEmailErrorInternal(value)
              }}
              helperText={
                emailError ? (
                  <span className={styles.errorWrapper}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    {emailError === 'required' || emailError === 'pattern' ? (
                      <Typography variant={'smallText'} color={theme.palette.primary.main}>
                        {t(
                          'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS_ERROR_PROVIDE_EMAIL'
                        )}
                      </Typography>
                    ) : emailError === 'validate' ? (
                      <Typography variant={'smallText'} color={theme.palette.primary.main}>
                        <Typography variant={'smallText'} color={theme.palette.primary.main}>
                          {t(
                            'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS_VALIDATION_ERROR'
                          )}
                        </Typography>
                      </Typography>
                    ) : (
                      ''
                    )}
                  </span>
                ) : !currentUser.emailData.isConfirmed && currentUser.emailData.email.length > 0 ? (
                  <span className={styles.errorWrapper}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    <Typography variant={'smallText'} color={theme.palette.primary.main}>
                      {t(
                        'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS_NOT_CONFIRMED_ERROR'
                      )}
                      <Link
                        href="#"
                        onClick={(event) => {
                          event.preventDefault()
                          handleLinkClick(customerId)
                        }}
                      >
                        <strong>
                          <u>
                            {t(
                              'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS_NOT_CONFIRMED_ERROR_LINK'
                            )}
                          </u>
                        </strong>
                      </Link>
                    </Typography>
                  </span>
                ) : (
                  ''
                )
              }
            />
          </div>
        </Box>

        <Box className={styles.resetPasswordWrapper}>
          {!isLoggedInUser ? (
            <Typography variant={'body'}>
              {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.RESET_PASSWORD_FOR_SUBUSER')}
            </Typography>
          ) : null}
          <div>
            <SecondaryButton
              fullWidth={false}
              type="reset"
              disabled={
                (!isLoggedInUser && !currentUser.emailData.isConfirmed) || emailError.length > 0
              }
              onClick={() => {
                handlePasswordResetClick(customerId)
              }}
            >
              {isLoggedInUser
                ? t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.CHANGE_PASSWORD')
                : t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.RESET_PASSWORD')}
            </SecondaryButton>
          </div>
        </Box>
      </form>
    </div>
  )
}

export const AccountDetailsAdminOnSubuser: FC<AccountDetailsAdminOnSubuserProps> = (props) => {
  const { isLoggedInUser, currentUser, email, setEmail, name, setName, emailError, setEmailError } =
    props
  const history = useHistory()
  const dispatchTokenRefresh = useTriggerEmailTokenRefresh()
  const dispatchPasswordReset = useTriggerPasswordReset()
  const { fullUserId } = useUserDataV2()
  const handleLinkClick = (subUserId: string) => {
    dispatchTokenRefresh({ userId: fullUserId ?? subUserId, subUserId: subUserId })
  }
  const handlePasswordResetClick = (userId: string) => {
    isLoggedInUser
      ? history.push(
          `${process.env.NEXT_PUBLIC_LOGIN_APP_BASE_URL}/update-password?user_id=${userId}`
        )
      : dispatchPasswordReset(userId)
  }

  return (
    <AccountDetailsFields
      handleLinkClick={handleLinkClick}
      handlePasswordResetClick={handlePasswordResetClick}
      isLoggedInUser={isLoggedInUser}
      currentUser={currentUser}
      email={email}
      name={name}
      setEmail={setEmail}
      setName={setName}
      emailError={emailError}
      setEmailError={setEmailError}
    />
  )
}
