import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid2'
import { CartTemplateCreateDialog } from '@obeta/components/lib/templates/CartTemplateCreateDialog'
import { CartTemplatesEmptyBox } from '@obeta/components/lib/templates/CartTemplatesEmptyBox'
import { CartTemplatesListAppBar } from '@obeta/components/lib/templates/list/CartTemplatesListAppBar'
import { CartTemplatesListLoading } from '@obeta/components/lib/templates/list/CartTemplatesListLoading'
import { LazyList } from '@obeta/components/lib/templates/list/LazyList'
import { TemplateActionsBar } from '@obeta/components/lib/templates/TemplateActionsBar'
import {
  CartTemplatesListContextProvider,
  useCartTemplatesListContext,
} from '@obeta/data/lib/stores/useCartTemplatesListContext'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useEntityMetaData } from '@obeta/data/lib/hooks/useEntityMetaData'
import {
  createNewTemplateGql,
  CreateNewTemplateGqlResultAction,
  deleteCartTemplateGraphQL,
  duplicateCartTemplateGraphQL,
  getCartTemplateItemImagesGraphQL,
  TemplateActionTypes,
  updateCartTemplateGraphQL,
} from '@obeta/data/lib/actions/template-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { CartTemplate, CartTemplatePatch } from '@obeta/schema'
import { SortBy, Tab } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { TAB_VALUES_LABEL_MAPPING } from '@obeta/components/lib/templates/TemplateFilterBar'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { withProvider } from '@obeta/data/lib/providers/withProvider'
import { useCartTemplatesListState } from '@obeta/data/lib/hooks/cart-templates/list/reducer/useCartTemplatesListState'
import styles from './cartTemplatesListPage.module.scss'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'

const shouldFetchPreviewImages = (template: CartTemplate, imageLimit = 7) => {
  if (template.cartTemplateItems) {
    return template.cartTemplateItems.items
      ?.slice(0, imageLimit)
      .some(
        (item) =>
          !item?.product?.imageData?.images || !item.product.oxomiId || !item.product.supplierId
      )
  }
  return true
}

const CartTemplatesListPage: FC = withProvider(() => {
  const { tabletAll, mobile } = useBreakpoints()
  const { searchTerm } = useCartTemplatesListContext()
  const { t } = useTranslation()
  const title = t('TEMPLATES.TEMPLATES')

  // Component state
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false)
  const [showDeleteCartTemplate, setShowDeleteCartTemplate] = useState<CartTemplate | undefined>(
    undefined
  )

  const templates = useEntities<CartTemplate>('carttemplates')
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const {
    state,
    sort,
    search,
    changeTab,
    updateTemplates,
    filteredSortedTemplates,
    resetShouldFetchProducts,
  } = useCartTemplatesListState()

  const { isFetching: loading, isUpdatingItemImages } = useEntityMetaData('carttemplates')
  const dispatch = useDispatch()
  const [isCreatingNewTemplate, setIsCreatingNewTemplate] = useState<boolean>(false)

  useEffect(() => {
    if (state.shouldFetchProductData) {
      const templatesToFetch = filteredSortedTemplates.filter(shouldFetchPreviewImages)
      if (templatesToFetch.length === 0) {
        resetShouldFetchProducts()
      } else {
        dispatch(getCartTemplateItemImagesGraphQL(templatesToFetch))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchProductData])

  useEffect(() => {
    if (!isUpdatingItemImages) {
      resetShouldFetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingItemImages])

  useEffect(() => {
    updateTemplates(templates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates])

  useEffect(() => {
    setIsInitialLoading(loading && !templates.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, loading])

  const waitForCreateNewTemplateAction = useActionNotification(
    TemplateActionTypes.CreateNewTemplateGqlResult
  )

  const createTemplate = (name: string) => {
    dispatch(createNewTemplateGql(name))
    waitForCreateNewTemplateAction((action: CreateNewTemplateGqlResultAction) => {
      if (action.success) {
        onCloseCreateTemplateDialog()
      }
    })
  }

  const duplicateTemplate = (cartTemplate: CartTemplate) => {
    dispatch(duplicateCartTemplateGraphQL({ cartTemplateId: cartTemplate.id }, cartTemplate.name))
  }

  const updateCartTemplate = (cartTemplateId: string, name: string, patch: CartTemplatePatch) => {
    dispatch(updateCartTemplateGraphQL({ cartTemplateId, patch }, name))
  }
  const onRenameTemplateRequestedViaContextMenu = (cartTemplate: CartTemplate, newName: string) => {
    updateCartTemplate(cartTemplate.id, cartTemplate.name, { name: newName })
  }
  const toggleFavorite = (cartTemplate: CartTemplate, isFavorite: boolean) => {
    updateCartTemplate(cartTemplate.id, cartTemplate.name, { isFavourite: isFavorite })
  }
  const toggleVisibility = (cartTemplate: CartTemplate, isShared: boolean) => {
    updateCartTemplate(cartTemplate.id, cartTemplate.name, { isShared })
  }

  const onCreateTemplateButtonClicked = () => {
    setIsCreatingNewTemplate(true)
  }

  const onCloseCreateTemplateDialog = () => {
    setIsCreatingNewTemplate(false)
  }

  /**
   * Handler to close cart template delete confirm dialog.
   */
  const onDeleteTemplateClose = () => {
    setShowDeleteCartTemplate(undefined)
  }

  /**
   * Handler to initialize cart template deletion, opens confirm dialog.
   * @param cartTemplate CartTemplate
   */
  const onDeleteTemplateInit = (cartTemplate: CartTemplate) => {
    setShowDeleteCartTemplate(cartTemplate)
  }

  /**
   * Handler to submit cart template delete.
   */
  const onDeleteTemplateSubmit = () => {
    if (showDeleteCartTemplate) {
      dispatch(
        deleteCartTemplateGraphQL(
          { templateId: showDeleteCartTemplate.id },
          showDeleteCartTemplate.name
        )
      )
    }
    onDeleteTemplateClose()
  }

  const onTabChange = (tab: Tab) => changeTab(tab)
  const onSortByChange = (sortBy: SortBy) => sort(sortBy)
  const onSearchTermChange = (input) => search(input)

  const getPageContent = () => {
    if (isInitialLoading) {
      return <CartTemplatesListLoading title={title} />
    }

    return (
      <Grid className={styles.gridItem}>
        <CartTemplatesListAppBar
          title={title}
          onCreateTemplateButtonClicked={onCreateTemplateButtonClicked}
        />
        <TemplateActionsBar
          activeCartTemplatesFilter={state.tab}
          sortBy={state.sortBy}
          templates={templates}
          onSortByChange={onSortByChange}
          onSearch={onSearchTermChange}
          onToggleActiveCartTemplatesFilter={onTabChange}
        />
        {!loading && !templates.length && (
          <CartTemplatesEmptyBox onCreateTemplateButtonClicked={onCreateTemplateButtonClicked} />
        )}

        <LazyList
          deleteTemplate={onDeleteTemplateInit}
          duplicateTemplate={duplicateTemplate}
          toggleFavorite={toggleFavorite}
          onRenameTemplateRequestedViaContextMenu={onRenameTemplateRequestedViaContextMenu}
          toggleVisibility={toggleVisibility}
          isUpdatingItemImages={isUpdatingItemImages ?? false}
          amountOfTemplates={templates.length}
          mobile={mobile}
          tabletAll={tabletAll}
          carts={carts}
          filteredSortedTemplates={filteredSortedTemplates}
        />
        {isCreatingNewTemplate && (
          <CartTemplateCreateDialog
            open={isCreatingNewTemplate}
            onClose={onCloseCreateTemplateDialog}
            onSubmit={createTemplate}
          />
        )}
      </Grid>
    )
  }

  return (
    <div className={styles.templatesList}>
      <Grid container direction={'column'}>
        {getPageContent()}
        {!loading && filteredSortedTemplates.length === 0 && searchTerm.length > 0 && (
          <NoResults
            body={t('SEARCH.NO_RESULTS.BODY')}
            title={t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.TITLE', {
              searchTerm: searchTerm,
              tabName: t(TAB_VALUES_LABEL_MAPPING[state.tab]),
            })}
          />
        )}
        <Confirm
          body={t('TEMPLATES.DETAILS_ACTIONS.BODY_CONFIRM')}
          heading={t('TEMPLATES.DETAILS_ACTIONS.DELETE_TEMPLATE')}
          openConfirmDialog={!!showDeleteCartTemplate}
          handleCancel={onDeleteTemplateClose}
          handleConfirm={onDeleteTemplateSubmit}
        />
      </Grid>
    </div>
  )
}, CartTemplatesListContextProvider)

export default CartTemplatesListPage
