import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { trackCustom } from '@obeta/utils/lib/tracking'
import {
  RequestOfferCancellationInput,
  RequestOfferCancellationQuery,
  RequestOfferCancellationQueryVariables,
} from '@obeta/schema'

const QUERY = gql`
  query requestOfferCancellation($input: RequestOfferCancellationInput!) {
    requestOfferCancellation(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`

export const useRequestOfferCancellation = () => {
  const client = useApolloClient()

  const cancelOffer = useCallback(
    async (input: RequestOfferCancellationInput) => {
      const response = await client.query<
        RequestOfferCancellationQuery,
        RequestOfferCancellationQueryVariables
      >({
        query: QUERY,
        variables: {
          input: {
            offerId: input.offerId,
            reason: input.reason,
          },
        },
        fetchPolicy: 'no-cache',
      })

      const offerCancellationResponse = response.data.requestOfferCancellation

      if (offerCancellationResponse.success) {
        trackCustom('request-offer-cancellation-success', {
          request: input,
          response: offerCancellationResponse,
        })
      } else {
        trackCustom('request-offer-cancellation-error', response.data)
      }
    },
    // eslint-disable-next-line
    []
  )

  return {
    cancelOffer,
  }
}
