import React, { useEffect } from 'react'
import orderBeingProcessedStyles from './CheckoutOrderPending.module.scss'
import styles from './CheckoutOrderApproval.module.scss'
import { Button, SvgIcon, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as PrintIcon } from 'assets/icon/designsystem/print.svg'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ContinueShopping } from './ContinueShopping'

interface Props {
  emailAddress: string
  onPrintCart: () => void
  isSessionCartCheckout?: boolean
  onSuccess?: () => void
}

export const CheckoutOrderApproval: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { emailAddress, onPrintCart, isSessionCartCheckout, onSuccess } = props

  const handleClickOnPrint = () => {
    onPrintCart()
  }

  useEffect(() => {
    if (onSuccess) onSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      direction="row"
      className={clsx(
        orderBeingProcessedStyles.mainContainer,
        orderBeingProcessedStyles.mainContainerHeight
      )}
    >
      <Grid size={{ xs: 12 }}>
        <div className={orderBeingProcessedStyles.animationMainContainer}>
          <div className={styles.circleLoader}>
            <div className={styles.checkMark} />
          </div>
        </div>

        <Typography variant={'h3'} className={styles.headerText}>
          {t('SHOPPING_CART.THANKS')}
        </Typography>

        <div className={styles.bodyText}>
          <Typography variant={'boldText'}>
            {t('SHOPPING_CART.CHECKOUT.NOTIFIED_CART', { emailAddress })}
          </Typography>
          <Typography variant={'boldText'}>
            {t('SHOPPING_CART.CHECKOUT.NOTIFIED_CART_WARNING')}
          </Typography>
        </div>

        <div className={styles.saveButtonWrapper}>
          <Button
            fullWidth={false}
            variant="outlined"
            onClick={handleClickOnPrint}
            className={styles.saveButton}
            startIcon={<SvgIcon component={PrintIcon} />}
          >
            {t('SHOPPING_CART.ACTIONS.PRINT_APPROVAL_REQUESTED_CART')}
          </Button>
        </div>
        {!isSessionCartCheckout && <ContinueShopping />}
      </Grid>
    </Grid>
  )
}
