import React, { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as SortIcon } from 'assets/icon/designsystem/import_export.svg'
import { ReactComponent as OnIcon } from 'assets/icon/designsystem/on.svg'
import { ReactComponent as OffIcon } from 'assets/icon/designsystem/off.svg'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import stylesOpenPositionTable from '../openPositionsPage/OpenPositionsTable.module.scss'
import styles from './UserOverviewPage.module.scss'
import { TableCellRightAligned } from '../openPositionsPage/OpenPositionsTable'
import {
  useUserOverviewContext,
  UserOverviewSortingOrderBy,
  UserOverviewSorting,
} from '@obeta/data/lib/stores/useUserOverviewContext'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { OverviewTablePermissionsIcons } from '@obeta/components/lib/user-overview/OverviewTablePermissionsIcons'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'
import { useUserActiveState } from '@obeta/data/lib/hooks/useUserUpdate'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

export type CellProps = {
  right?: boolean
  iconRight?: boolean
  orderBy?: UserOverviewSortingOrderBy
  currentSorting?: UserOverviewSorting
  sortableClassName?: string
  className?: string
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}
export const Cell: FC<CellProps> = ({
  children,
  className,
  right,
  iconRight,
  variant,
  orderBy,
  sortableClassName,
}) => {
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { sorting, setSorting } = useUserOverviewContext()

  const TableCellComponent = right ? TableCellRightAligned : TableCell
  return (
    <TableCellComponent
      className={clsx(
        className,
        stylesOpenPositionTable.cell,
        mobile && stylesOpenPositionTable.condensedCell,
        mobile && stylesOpenPositionTable.mobileCellPaddingRightAndLeftOneRem,
        tabletAll && stylesOpenPositionTable.tabletCell
      )}
    >
      {!orderBy && <Typography variant={variant}>{children}</Typography>}
      {orderBy && (
        <div
          className={clsx(
            sortableClassName,
            stylesOpenPositionTable.sortableHead,
            right && stylesOpenPositionTable.rightAligned,
            !desktop && stylesOpenPositionTable.increasedTouchArea,
            !right && stylesOpenPositionTable.leftAligned
          )}
          onClick={() => {
            if (sorting?.orderBy === orderBy) {
              const order = sorting.order === 'asc' ? 'desc' : 'asc'
              setSorting({ orderBy, order })
            } else {
              setSorting({ orderBy, order: 'asc' })
            }
          }}
        >
          {!desktop && right && !iconRight && <SortIcon />}
          <Typography variant={variant}>{children}</Typography>
          {(desktop || !right || iconRight) && <SortIcon />}
        </div>
      )}
    </TableCellComponent>
  )
}

interface PropsTableHead {
  totalCountItems?: number
}

const UserOverviewTableHead: FC<PropsTableHead> = (props) => {
  const { t } = useTranslation()
  const { tablet, mobile } = useBreakpoints()

  return (
    <TableHead
      className={clsx(
        stylesOpenPositionTable.tableHead,
        styles.defaultPadding,
        mobile && stylesOpenPositionTable.mobileCell
      )}
    >
      <TableRow>
        <Cell
          orderBy="name"
          variant="bodyBold"
          className={stylesOpenPositionTable.paddingLeftOneRem}
        >
          {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.NAME')}
        </Cell>

        {!mobile && (
          <Cell
            orderBy="customerNumber"
            variant="bodyBold"
            className={stylesOpenPositionTable.desktopCellPadding}
          >
            {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.CUSTOMER_NUMBER')}
          </Cell>
        )}

        {!mobile && (
          <Cell
            variant="bodyBold"
            className={clsx(stylesOpenPositionTable.desktopCellPadding, styles.centered)}
          >
            {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.STATUS')}
          </Cell>
        )}

        {!mobile && !tablet && (
          <Cell variant="bodyBold" className={stylesOpenPositionTable.desktopCellPadding}>
            {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.PERMISSION')}
          </Cell>
        )}

        {!mobile ? (
          <Cell variant="bodyBold" className={stylesOpenPositionTable.desktopCellPadding}></Cell>
        ) : (
          <Cell variant="bodyBold" className={stylesOpenPositionTable.textCentered}>
            {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.MANAGE_USER')}
          </Cell>
        )}
      </TableRow>
    </TableHead>
  )
}

interface PropsUserOverviewTableRow {
  user: UserSubuser
  showManageButton: boolean
}
const UserOverviewTableRow: FC<PropsUserOverviewTableRow> = (props) => {
  const { tablet, mobile, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const { user, showManageButton } = props
  const { dispatchUserUpdate, userActive } = useUserActiveState(user.isActive)

  const toggleStatus = async (status: boolean) => {
    await dispatchUserUpdate({
      isActive: status,
      subUserId: user.companyId + '-' + user.customerNumber,
    })
  }
  const history = useHistory()

  return (
    <TableRow
      className={clsx(
        stylesOpenPositionTable.tableBodyRow,
        mobile && stylesOpenPositionTable.mobile
      )}
    >
      <Cell>
        <Typography variant={'body'} className={!userActive ? styles.grayedOut : ''}>
          {
            /* remove subuser-id which is always prefixed settings.name */
            user.settings.name.substring(user.settings.name.indexOf(' '))
          }
        </Typography>
      </Cell>
      {!mobile && (
        <Cell className={stylesOpenPositionTable.desktopCellPadding}>
          <Typography variant={'body'} className={!userActive ? styles.grayedOut : ''}>
            {user.customerNumber}
          </Typography>
        </Cell>
      )}
      {!mobile && (
        <Cell className={clsx(stylesOpenPositionTable.desktopCellPadding, styles.centered)}>
          {user.type === UserType.sub ? (
            userActive ? (
              <>
                <OnIcon className={styles.pointer} onClick={() => toggleStatus(false)} />
                <p className={styles.textSmall}>
                  {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.ACTIVE')}
                </p>
              </>
            ) : (
              <>
                <OffIcon className={styles.pointer} onClick={() => toggleStatus(true)} />
                <p className={styles.textSmall}>
                  {t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.INACTIVE')}
                </p>
              </>
            )
          ) : (
            ''
          )}
        </Cell>
      )}

      {!mobile && !tablet && (
        <Cell className={stylesOpenPositionTable.desktopCellPadding}>
          <OverviewTablePermissionsIcons permissions={user.permissions} userActive={userActive} />
        </Cell>
      )}

      <TableCellRightAligned className={stylesOpenPositionTable.desktopCellPadding}>
        {showManageButton && (
          <div className={stylesOpenPositionTable.icon}>
            <TertiaryButton
              rightIcon={<ChevronRight />}
              onClick={() => {
                history.push(`/user-management/${user.customerNumber}`)
              }}
              size={desktop ? 'small' : 'large'}
            >
              {mobile ? '' : t('USER_MANAGEMENT.TABLE_USER_OVERVIEW.MANAGE_USER')}
            </TertiaryButton>
          </div>
        )}
      </TableCellRightAligned>
    </TableRow>
  )
}

type IUserOverviewTable = {
  users?: UserSubuser[]
  loggedInUser?: UserSubuser
}

export const UserOverviewTable: FC<IUserOverviewTable> = (props) => {
  const { users, loggedInUser } = props

  return (
    <TableContainer component={Paper} className={stylesOpenPositionTable.tableContainer}>
      <Table aria-label="user-overview-table">
        <UserOverviewTableHead totalCountItems={users?.length} />
        <TableBody>
          {users?.map((subuser, index) => (
            <UserOverviewTableRow
              user={subuser}
              key={subuser.customerNumber}
              showManageButton={
                loggedInUser?.type === 'main' ||
                (loggedInUser?.type === 'sub' && subuser.type !== 'main')
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
