import React from 'react'
import styles from './CheckoutOrderPending.module.scss'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import clsx from 'clsx'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import dayjs from 'dayjs'
import { ContinueShopping } from './ContinueShopping'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'

interface Props {
  netSum?: number
  numberOfPositions?: number
  shippingDate?: string
  totalSum?: number
  type?: ShippingType
  placeholder?: boolean
  withOverlay?: boolean
  isSessionCartCheckout?: boolean
}

export const CheckoutOrderPending: React.FC<Props | null> = (props) => {
  const { t } = useTranslation()
  const {
    netSum,
    numberOfPositions,
    shippingDate,
    totalSum,
    type,
    placeholder,
    withOverlay,
    isSessionCartCheckout,
  } = props

  return (
    <Grid
      container
      direction="row"
      className={clsx(
        styles.mainContainer,
        styles.mainContainerHeight,
        withOverlay && styles.withOverlay
      )}
    >
      <Grid size={{ xs: 12 }}>
        <div className={styles.animationMainContainer}>
          <div className={styles.circleLoader} />
        </div>

        {placeholder ? (
          <div className={styles.placeholderHeadline}>
            <TextSkeleton width={'22rem'} />
          </div>
        ) : (
          <Typography variant={'h3'} className={styles.headerText}>
            {t('SHOPPING_CART.CHECKOUT.ORDER_IS_BEING_CREATED')}...
          </Typography>
        )}

        {placeholder ? (
          <div className={styles.placeholderBody}>
            <TextSkeleton width={'10rem'} height={'1.38rem'} />
          </div>
        ) : (
          <Box className={styles.dataLines}>
            <Typography variant={'body'}>
              {t('SHOPPING_CART.CHECKOUT.NUMBER_OF_POSITIONS')}:
            </Typography>
            <Typography variant={'bodyBold'} className={styles.dataValues}>
              {numberOfPositions !== undefined ? numberOfPositions : ' - '}{' '}
            </Typography>
          </Box>
        )}

        {placeholder ? (
          <div className={styles.placeholderBody}>
            <TextSkeleton width={'10rem'} height={'1.38rem'} />
          </div>
        ) : (
          <Box className={styles.dataLines}>
            <Typography variant={'body'}>{t('SHOPPING_CART.OVERALL_VALUE')}:</Typography>
            <Typography variant={'bodyBold'} className={styles.dataValues}>
              {netSum !== undefined ? normalizePrice(netSum) : ' - '}
            </Typography>
          </Box>
        )}

        {placeholder ? (
          <div className={styles.placeholderBody}>
            <TextSkeleton width={'10rem'} height={'1.38rem'} />
          </div>
        ) : (
          <Box className={styles.dataLines}>
            <Typography variant={'body'}>{t('SHOPPING_CART.CHECKOUT.SUM')}:</Typography>
            <Typography variant={'bodyBold'} className={styles.dataValues}>
              {totalSum !== undefined ? normalizePrice(totalSum) : ' - '}
            </Typography>
          </Box>
        )}

        {placeholder ? (
          <div className={styles.placeholderBody}>
            <TextSkeleton width={'10rem'} height={'1.38rem'} />
          </div>
        ) : (
          <Box className={styles.dataLines}>
            {(type === ShippingType.DefaultParcel ||
              type === ShippingType.FastParcel ||
              type === ShippingType.Cargo) && (
              <Typography variant={'body'}>
                {t('SHOPPING_CART.PREFERRED_DELIVERY_DATE')}:
              </Typography>
            )}
            {(type === ShippingType.DefaultPickup ||
              type === ShippingType.ExpressPickup ||
              type === ShippingType.PickupRenzbox) && (
              <Typography variant={'body'}>
                {t('SHOPPING_CART.PREFERRED_COLLECTION_DATE')}:
              </Typography>
            )}
            <Typography variant={'bodyBold'} className={styles.dataValues}>
              {shippingDate !== undefined ? dayjs(shippingDate).format('DD.MM.YYYY') : ' - '}{' '}
            </Typography>
          </Box>
        )}

        {!isSessionCartCheckout && <ContinueShopping />}
      </Grid>
    </Grid>
  )
}
