import { useCallback, useEffect, useState } from 'react'
import { gqlUserSubusers } from '../../queries/userV2Subusers'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { useQuery } from '@apollo/client'
import { GetUserSubusersQuery, GetUserSubusersQueryVariables } from '@obeta/schema'
import { UserOverviewSorting, useUserOverviewContext } from '../../stores/useUserOverviewContext'
import { useLocalSearchControlled } from '../useLocalSearchControlled'
import { useDebouncedEffect } from '../useDebouncedEffect'

function sortItems(items: UserSubuser[], sorting: UserOverviewSorting) {
  const itemsToSort = [...items]
  return itemsToSort.sort((a, b) => {
    let left = 0
    let right = 0
    switch (sorting.orderBy) {
      case 'customerNumber': {
        left = parseInt(a['customerNumber'].replace(/-/g, ''))
        right = parseInt(b['customerNumber'].replace(/-/g, ''))
        return sorting.order === 'asc' ? left - right : right - left
      }
      case 'name': {
        const nameLeftWithId = a['settings']['name']
        const nameRightWithId = b['settings']['name']
        const left = nameLeftWithId.substring(nameLeftWithId.indexOf(' '))
        const right = nameRightWithId.substring(nameRightWithId.indexOf(' '))
        return sorting.order === 'asc'
          ? left.toLowerCase().localeCompare(right.toLowerCase())
          : right.toLowerCase().localeCompare(left.toLowerCase())
      }
      default:
        break
    }

    return sorting.order === 'asc' ? left - right : right - left
  })
}

export const useSortedUserData = (userData: UserSubuser[]) => {
  const { sorting } = useUserOverviewContext()
  const [searchResults, setSearchResults] = useState<UserSubuser[]>(userData)

  useEffect(() => {
    setSearchResults(userData)
  }, [userData])

  const sortedItems = sortItems(userData, sorting)
  return {
    sortedItems,
    searchResults,
  }
}
const searchKeys = ['customerNumber', 'settings.name']

export const useUserOverviewTableData = () => {
  const [subusers, setSubusers] = useState<UserSubuser[]>([])
  const [allUsersWithoutSearchFilter, setAllUsersWithoutSearchFilter] = useState<UserSubuser[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loading, setLoading] = useState(true)

  const { refetch: refetchUsers } = useQuery<GetUserSubusersQuery, GetUserSubusersQueryVariables>(
    gqlUserSubusers,
    {
      skip: subusers !== undefined && subusers.length !== 0,
      onCompleted: (data) => {
        searchText === '' && setSubusers(data.getUserSubusers)
        setAllUsersWithoutSearchFilter(data.getUserSubusers)
        setLoading(false)
      },
    }
  )

  useEffect(() => {
    searchText === '' && setSubusers(allUsersWithoutSearchFilter)
  }, [searchText, allUsersWithoutSearchFilter])

  const { sortedItems: unfilteredItemsSorted } = useSortedUserData(allUsersWithoutSearchFilter)
  const { search } = useLocalSearchControlled()

  const updateUser = useCallback((user: UserSubuser) => {
    setSubusers((prevSubUsers) => {
      return prevSubUsers.map((prevUser) =>
        prevUser.customerNumber === user.customerNumber ? user : prevUser
      )
    })
  }, [])

  useDebouncedEffect(
    () => {
      if (searchText === '') return

      setSubusers(search(unfilteredItemsSorted, searchText, searchKeys, { minMatchCharLength: 1 }))
    },
    [searchText],
    500
  )

  return {
    sortedItems: subusers,
    setSearchText,
    searchText,
    setSubusers,
    updateUser,
    loading,
    refetchUsers,
    unfilteredUsers: allUsersWithoutSearchFilter,
  }
}
