import React, { useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import {
  Box,
  SvgIcon,
  Typography,
  TextField,
  Modal,
  Divider,
  useTheme,
  IconButton,
} from '@mui/material'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { LoadingIndicator } from '../split-button/LoadingIndicator'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { DarkButton } from '../custom-button/CustomButton'
import { InputNumber } from '../input-number/InputNumber'
import editAddressOverlayStyles from './CheckoutEditAddressOverlay.module.scss'
import { Mutation, MutationInquireOfferArgs } from '@obeta/schema'
import styles from './CheckoutInquireOfferOverlay.module.scss'

interface OfferInquiryOverlay {
  showOfferInquiryOverlay: boolean
  setShowOfferInquiryOverlay: (value: boolean) => void
  setShowSuccessOverlay: (value: boolean) => void
  setShowFailureOverlay: (value: boolean) => void
  setSelectedStore: (value?: StoreV2) => void
  selectedCartId: string
}

interface OfferInquiryForm {
  name: string
  email: string
  phone: number | undefined
  commission: string | undefined
}

interface SubmitSuccessResponse {
  success: boolean
  storeId: string
}

export const inquireOfferMutation = gql`
  mutation inquireOffer($input: OfferInquiryInput!) {
    inquireOffer(input: $input) {
      offerInquiryHandle {
        success
        storeId
      }
    }
  }
`

export const CheckoutInquireOfferOverlay: React.FC<OfferInquiryOverlay> = (props) => {
  const [loading, setLoading] = useState(false)
  const stores: StoreV2[] = useEntities('storesv2')
  const client = useApolloClient()

  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OfferInquiryForm>({ mode: 'onChange' })

  const theme = useTheme()
  const { user } = useUserDataV2()
  const {
    showOfferInquiryOverlay,
    setShowOfferInquiryOverlay,
    setShowSuccessOverlay,
    setShowFailureOverlay,
    setSelectedStore,
    selectedCartId,
  } = props

  const onSubmit = (data: OfferInquiryForm) => {
    setLoading(true)
    submitOfferInquiry(data, selectedCartId)
      .then((data) => {
        setLoading(false)
        setShowOfferInquiryOverlay(false)
        if ('storeId' in data) {
          const selectedStore = stores.find((store) => store.id === data.storeId)
          setSelectedStore(selectedStore)
        }
        setShowSuccessOverlay(true)
        clearTimeout(generalTimeout)
      })
      .catch((error: unknown) => {
        if (error instanceof Error) handleError(error)

        setLoading(false)
        setShowOfferInquiryOverlay(false)
        const defaultStore = stores.find((store) => store.id === user?.settings?.defaultStoreId)
        setSelectedStore(defaultStore)
        setShowFailureOverlay(true)
        clearTimeout(generalTimeout)
      })

    //Show the failure overlay in case nothing happens after 30 sec
    const generalTimeout = setTimeout(() => {
      const defaultStore = stores.find((store) => store.id === user?.settings?.defaultStoreId)
      setSelectedStore(defaultStore)
      setShowFailureOverlay(true)
    }, 30000)
  }

  const handleClose = () => {
    setShowOfferInquiryOverlay(false)
  }

  const submitOfferInquiry = async (
    data: OfferInquiryForm,
    cartId: string
  ): Promise<SubmitSuccessResponse | Error> => {
    const response = await client.mutate<Mutation, MutationInquireOfferArgs>({
      mutation: inquireOfferMutation,
      variables: {
        input: {
          cartId: cartId,
          name: data.name,
          email: data.email,
          phone: data.phone,
          commission: data.commission,
        },
      },
    })

    if (response?.data?.inquireOffer.offerInquiryHandle.success) {
      return response.data.inquireOffer.offerInquiryHandle
    }

    return Promise.reject(new Error('backend-err'))
  }

  let emailErrorMessage = ''

  if (errors?.email?.type === 'validate') {
    emailErrorMessage = t('OFFERINQUIRY.ERROR.EMAIL_INVALID')
  } else if (errors?.email?.type === 'required') {
    emailErrorMessage = t('OFFERINQUIRY.ERROR.EMAIL_REQUIRED')
  }

  return (
    <Modal
      open={showOfferInquiryOverlay}
      onClose={handleClose}
      aria-labelledby="modal-modal-checkout-add-address"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.mainDiv}>
        <Box className={styles.heading}>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleClose}
            className={editAddressOverlayStyles.buttonAddressLocationCard}
          >
            <SvgIcon
              component={ChevronLeftIcon}
              fontSize={'large'}
              htmlColor={theme.palette.grayVariant.dark}
            />
          </IconButton>
          <Typography variant={'h4'} className={styles.textInquireOffer}>
            {t('OFFERINQUIRY.INQUIRE_OFFER')}
          </Typography>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleClose}
            className={clsx(editAddressOverlayStyles.buttonAddressLocationCard, styles.noMargin)}
          >
            <SvgIcon
              component={CloseIcon}
              fontSize={'large'}
              htmlColor={theme.palette.grayVariant.dark}
            />
          </IconButton>
        </Box>
        <div className={styles.textInfoForInquiry}>
          <Typography variant={'bodyBold'}>{t('OFFERINQUIRY.INFO_FOR_OFFER_INQUIRY')}</Typography>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.formStyles}>
          <Typography variant={'body'}>{t('OFFERINQUIRY.NAME')}</Typography>
          <Controller
            render={({ field: { onChange, onBlur } }) => (
              <TextField
                className={editAddressOverlayStyles.text_input}
                variant="outlined"
                id="name"
                name="name"
                onChange={onChange}
                onBlur={onBlur}
                helperText={
                  errors.name ? (
                    <Box display={'flex'}>
                      <SvgIcon
                        className={styles.helperTextIcon}
                        component={WarningIcon}
                        fontSize={'small'}
                        htmlColor={theme.palette.primary.main}
                      />
                      <Typography variant={'smallText'} color={theme.palette.primary.main}>
                        {errors.name.type === 'minLength'
                          ? t('OFFERINQUIRY.ERROR.MIN_LENGTH_NAME')
                          : errors.name.type === 'required'
                          ? t('OFFERINQUIRY.ERROR.NAME_REQUIRED')
                          : ''}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  )
                }
              />
            )}
            name="name"
            control={control}
            rules={{
              required: true,
              minLength: 3,
            }}
          />

          <div className={styles.fieldWrapper}>
            <Typography variant={'body'}>{t('OFFERINQUIRY.EMAIL')}</Typography>
            <Controller
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  className={editAddressOverlayStyles.text_input}
                  variant="outlined"
                  id="email"
                  name="email"
                  onChange={onChange}
                  onBlur={onBlur}
                  helperText={
                    errors.email ? (
                      <Box display={'flex'}>
                        <SvgIcon
                          className={styles.helperTextIcon}
                          component={WarningIcon}
                          fontSize={'small'}
                          htmlColor={theme.palette.primary.main}
                        />
                        <Typography variant={'smallText'} color={theme.palette.primary.main}>
                          {emailErrorMessage}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )
                  }
                />
              )}
              name="email"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  return [/@/, /\./].every((pattern) => pattern.test(value)) || false
                },
              }}
            />
          </div>

          <div className={styles.fieldWrapper}>
            <Typography variant={'body'}>{t('OFFERINQUIRY.PHONE')}</Typography>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputNumber
                    withDecimals={false}
                    id="phone"
                    name="phone"
                    className={editAddressOverlayStyles.text_input}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>

          <div className={styles.fieldWrapper}>
            <Typography variant={'body'}>{t('OFFERINQUIRY.COMMISSION')}</Typography>
            <Controller
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  className={editAddressOverlayStyles.text_input}
                  variant="outlined"
                  id="commission"
                  name="commission"
                  onChange={onChange}
                  onBlur={onBlur}
                  helperText={
                    errors.commission ? (
                      <Box display={'flex'}>
                        <SvgIcon
                          className={styles.helperTextIcon}
                          component={WarningIcon}
                          fontSize={'small'}
                          htmlColor={theme.palette.primary.main}
                        />
                        <Typography variant={'smallText'} color={theme.palette.primary.main}>
                          {errors?.commission?.type === 'maxLength' &&
                            t('OFFERINQUIRY.ERROR.COMMISSION_MAX_LENGTH')}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )
                  }
                />
              )}
              name="commission"
              control={control}
              rules={{
                maxLength: 30,
              }}
            />
          </div>
          <Divider className={editAddressOverlayStyles.divider} />
          <div className={styles.submitButtonWrapper}>
            <DarkButton
              type="submit"
              fullWidth={true}
              sx={loading ? { minWidth: '7.625rem' } : undefined}
            >
              {loading ? (
                <LoadingIndicator isOfferInquirySubmitButton={true} />
              ) : (
                t('OFFERINQUIRY.BUTTON_TEXT')
              )}
            </DarkButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}
