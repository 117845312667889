import { Box, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ShoppingCartPickupOptions } from '../shoppingcart/ShoppingCartPickupOptions'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { StoreManagementModal } from './StoreManagementModal'
import styles from './ShoppingCartCheckoutInformation.module.scss'
import { ShoppingCartFilterEnum } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import {
  ShippingOptions,
  ShippingType,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'
import { PartialShippingDataPickup } from '@obeta/models/lib/models/ShoppingCart/ShippingData'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'

interface Props {
  preFilterCartItems: (mode: ShoppingCartFilterEnum) => void
  selectStoreId: (storeId: string) => void
  selectedStoreId: string
  stores: StoreV2[]
  xpressPickupEnabled: boolean
  checkoutStoreSearchBox: boolean
  partialShippingDataPickup: PartialShippingDataPickup
  savedShippingType: ShippingType
  setCheckoutStoreSearchBox: (i: boolean) => void
  setValuesForMemoryBox: (param) => void
  shippingOptions: ShippingOptions[]
  selectedShippingType: string
  selectedShippingDate: string
  updateShippingDate: (date: string) => void
  setPartialShippingDataPickup: (shippingData: PartialShippingDataPickup) => void
  updateShippingType: (shippingType: ShippingType) => void
  cartContainsSpecialItems: boolean
}

export const ShoppingCartCheckoutPickupInformation: React.FC<Props> = (props) => {
  const userV2 = useUserV2()
  const { t } = useTranslation()
  const {
    preFilterCartItems,
    selectStoreId,
    selectedStoreId,
    stores,
    xpressPickupEnabled,
    checkoutStoreSearchBox,
    partialShippingDataPickup,
    savedShippingType,
    setCheckoutStoreSearchBox,
    setValuesForMemoryBox,
    shippingOptions,
    selectedShippingType,
    selectedShippingDate,
    updateShippingDate,
    setPartialShippingDataPickup,
    updateShippingType,
    cartContainsSpecialItems,
  } = props

  if (!stores.length) {
    return null
  }
  let store = stores.find((store) => store.id === selectedStoreId)
  if (!store) {
    store = stores.find((store) => store.id === userV2?.settings?.defaultStoreId)
  }
  if (!store) {
    store = stores[0]
    console.warn(
      `selectedStoreId ${selectedStoreId} not found within stores, therefore first available store ${store.id} is used instead`
    )
  }

  const openingHours = formatOpeningHours(store.openingHours)

  return (
    <div>
      <Grid container direction={'column'} className={styles.gridContainer}>
        <Grid>
          <Box className={styles.headerBox}>
            <Typography data-testid="checkout-pickup-header-text" variant={'h4'}>
              {t('SHOPPING_CART.CHECKOUT.PICKUP')}
            </Typography>
            <Button
              data-testid="store-selection-button"
              className={styles.storeSelectionButton}
              variant={'tertiary'}
              size={'small'}
              onClick={() => setCheckoutStoreSearchBox(true)}
            >
              {t('SHOPPING_CART.NAVIGATION.CHOOSE_STORE')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Typography data-testid="pickup-store-name" variant={'bodyBold'}>
        {store.address.name1} <br />
        <br />
      </Typography>

      <Typography data-testid="pickup-store-address" variant={'body'}>
        {store.address.street + ' ' + store.address.houseNumber} <br />
        {store.address.zipCode + ' ' + store.address.city} <br />
        <br />
      </Typography>

      <div data-testid="pickup-store-hours" className={styles.flexRow}>
        <div className={styles.days}>
          {openingHours.map((openingHourGrouping) => {
            return (
              <Typography key={openingHourGrouping.days} variant="body">
                {openingHourGrouping.days}
              </Typography>
            )
          })}
        </div>
        <div className={styles.times}>
          {openingHours.map((openingHourGrouping) => {
            return (
              <Typography key={openingHourGrouping.days} variant="body">
                {openingHourGrouping.times}
              </Typography>
            )
          })}
        </div>
      </div>
      <Grid size={{ xs: 12 }}>
        <LightGrayDivider className={styles.pickupDivider} />
      </Grid>
      <Grid className={styles.optionsGrid} container direction={'column'}>
        <Typography data-testid="pickup-options-header" variant="h4">
          {t('SHOPPING_CART.CHECKOUT.PICKUP_OPTIONS')}
        </Typography>
        <ShoppingCartPickupOptions
          savedShippingType={savedShippingType}
          preFilterCartItems={preFilterCartItems}
          selectedStore={store}
          shippingOptions={shippingOptions}
          selectedShippingType={selectedShippingType}
          selectedShippingDate={selectedShippingDate}
          updateShippingDate={updateShippingDate}
          stockSufficientForExpressPickup={xpressPickupEnabled}
          partialShippingDataPickup={partialShippingDataPickup}
          setPartialShippingDataPickup={setPartialShippingDataPickup}
          setValuesForMemoryBox={setValuesForMemoryBox}
          updateShippingType={updateShippingType}
          cartContainsSpecialItems={cartContainsSpecialItems}
        />
      </Grid>
      {checkoutStoreSearchBox && (
        <StoreManagementModal
          checkoutStoreSearchBox={checkoutStoreSearchBox}
          onSelectStoreId={selectStoreId}
          selectedStoreId={store.id}
          setCheckoutStoreSearchBox={setCheckoutStoreSearchBox}
          selectedShippingType={partialShippingDataPickup.shippingType}
        />
      )}
    </div>
  )
}
