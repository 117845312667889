import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { usePullToRefresh } from '@obeta/components/lib/pull-to-refresh'

enum LoadingStatus {
  Loaded = 'loaded',
  Fetching = 'fetching',
  Idle = 'idle',
}

export interface ISuppliersOverviewFilters {
  name?: string | null
  hasTecselect: boolean | null
}

export interface ISupplierOverviewItem {
  id: string
  name: string
  filterName: string
  homepage: string
  hasTecselect: boolean
  hasVoltimum: boolean
  logoUrl: {
    sapId: string
    large: string
  }
}

interface ISupplierOverviewTotals {
  all: number
  hasTecselect: number
}

interface ISupplierOverviewContextData {
  suppliers: ISupplierOverviewItem[]
  updatePagination: () => void
  total: number
  totals: ISupplierOverviewTotals
  updateFilters: (value: ISuppliersOverviewFilters) => void
  filters: ISuppliersOverviewFilters
  pagination: {
    start: number
    limit: number
  }
  isLoaded: boolean
}

export const suppliersOverviewQuery = gql`
  query getSuppliersOverview($pagination: PaginationArgs!, $filters: FiltersArgs) {
    getSuppliersOverview(pagination: $pagination, filters: $filters) {
      total
      allTotals {
        all
        hasTecselect
      }
      data {
        id
        name
        filterName
        isActive
        hasTecselect
        homepage
        logoUrl {
          sapId
          large
        }
      }
    }
  }
`

type RequestSuppliersOverviewProps = {
  shouldRefetch: number
  filters?: {
    name?: string | null
    hasTecselect?: boolean | null
  }
  pagination: {
    start: number
    limit: number
  }
}

function removeNullAndEmptyProperties(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== '')
  )
}

const useRequestSuppliersOverview = ({
  shouldRefetch,
  filters,
  pagination,
}: RequestSuppliersOverviewProps) => {
  const [suppliers, setSuppliers] = useState<ISupplierOverviewItem[]>([])
  const [suppliersTotal, setSuppliersTotal] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Idle)
  const [totals, setTotals] = useState({
    all: 0,
    hasTecselect: 0,
  })
  const [error, setError] = useState<unknown>(null)

  const client = useApolloClient()

  useEffect(() => {
    const getData = async () => {
      setLoadingStatus(LoadingStatus.Fetching)

      try {
        const response = await client.query<{
          getSuppliersOverview: {
            data: ISupplierOverviewItem[]
            total: number
            allTotals: ISupplierOverviewTotals
          }
        }>({
          query: suppliersOverviewQuery,
          variables: { pagination, filters: removeNullAndEmptyProperties(filters) },
        })

        const suppliersOverview = response.data.getSuppliersOverview

        // Update suppliers state when filters changed
        if (!pagination || pagination?.start === 0) {
          setSuppliers(suppliersOverview.data)
        } else {
          setSuppliers([...suppliers, ...suppliersOverview.data])
        }

        setSuppliersTotal(suppliersOverview.total)
        setTotals(suppliersOverview.allTotals)

        setLoadingStatus(LoadingStatus.Loaded)
      } catch (err: unknown) {
        if (err instanceof Error) handleError(err)

        setError(err)
        setLoadingStatus(LoadingStatus.Idle)
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch])

  return {
    suppliers: suppliers,
    totals,
    total: suppliersTotal,
    error,
    loadingStatus: loadingStatus,
  }
}

export const SuppliersOverviewContext = createContext<ISupplierOverviewContextData | null>(null)

export const SuppliersOverviewContextProvider = ({ children }) => {
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 120,
  })

  const [filters, setFilters] = useState<ISuppliersOverviewFilters>({
    name: null,
    hasTecselect: null,
  })

  // We just manage with this flag when we want to update data, to avoid batching, since we keep sort/filters as separate values
  const [shouldRefetch, setShouldRefetch] = useState(1)

  const { suppliers, totals, total, loadingStatus } = useRequestSuppliersOverview({
    shouldRefetch,
    filters,
    pagination,
  })

  // There is no way to wait for the query refetch in this hook. Store the
  // resolve function so we can call it later.
  const completePullToRefreshRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (completePullToRefreshRef.current && loadingStatus === LoadingStatus.Loaded) {
      completePullToRefreshRef.current()
      completePullToRefreshRef.current = null
    }
  }, [loadingStatus])

  usePullToRefresh({
    onRefresh() {
      setShouldRefetch((prev) => prev + 1)

      return new Promise((resolve) => {
        completePullToRefreshRef.current = resolve
      })
    },
  })

  const updatePagination = () => {
    setPagination({ ...pagination, start: suppliers.length })
    setShouldRefetch((prev) => prev + 1)
  }

  const onResetCurrentPage = () => setPagination({ ...pagination, start: 0 })

  const updateFilters = (filters) => {
    onResetCurrentPage()
    setFilters(filters)
    setShouldRefetch((prev) => prev + 1)
  }

  return (
    <SuppliersOverviewContext.Provider
      value={{
        suppliers,
        updatePagination,
        updateFilters,
        total,
        totals,
        pagination,
        filters,
        isLoaded: loadingStatus === LoadingStatus.Loaded,
      }}
    >
      {children}
    </SuppliersOverviewContext.Provider>
  )
}

export const useSuppliersOverviewProvider = () => {
  const searchData = useContext(SuppliersOverviewContext)

  if (!searchData) {
    throw new Error(
      'suppliersOverview data is not defined. Make sure you call useSuppliersOverviewProvider inside one of SuppliersOverviewContextProvider child'
    )
  }

  return searchData
}
