import React from 'react'
import { Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { trackCustom } from '@obeta/utils/lib/tracking'

interface Props {
  description: string
  value?: number
  currency?: string
  variantDescription?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
  variantValue?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
  loading?: boolean
  classNameTitle?: string
  classNameValue?: string
}

export const ShoppingCartSummaryPrice: React.FC<Props> = (props) => {
  const {
    description,
    value,
    currency,
    variantDescription,
    variantValue,
    loading = false,
    classNameTitle,
    classNameValue,
  } = props

  if (((!loading && !value) || (!loading && value === 0)) && description === 'Nettowert') {
    trackCustom('shopping-cart-without-net-price')
  }

  return (
    <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography
        data-testid={`${description}-name`}
        variant={variantDescription || 'body'}
        className={classNameTitle || ''}
      >
        {description}
      </Typography>
      <Typography
        data-testid={`${description}-price`}
        variant={variantValue || 'bodyBold'}
        className={classNameValue || ''}
      >
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" height={24} width={100} />
        ) : (
          normalizePrice(value, currency)
        )}
      </Typography>
    </Grid>
  )
}
