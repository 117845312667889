import React, { FC } from 'react'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'

export const UserManagementSkeleton: FC = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    <TextSkeleton height="1.25rem" />
    <TextSkeleton height="2.5rem" />
    <TextSkeleton height="25.75rem" />
  </div>
)
