import { PullToRefreshStore, PullToRefreshStoreValue } from './store'
import { usePullToRefreshState } from './use-pull-to-refresh-state'

export interface PullToRefreshContainerProps {
  store: PullToRefreshStore
  children: React.ReactNode
}

export function PullToRefreshContainer(props: PullToRefreshContainerProps) {
  const { store, children } = props

  const state = usePullToRefreshState({ store })

  return <div style={getStyle(state)}>{children}</div>
}

function getStyle(state: PullToRefreshStoreValue) {
  return {
    transition: state.state === 'pulling' ? 'none' : 'transform 0.2s ease',
    transform: getTransform(state),
  }
}

function getTransform(state: PullToRefreshStoreValue) {
  switch (state.state) {
    case 'pulled':
    case 'refreshing':
      return 'translateY(60px)'
    case 'pulling':
      return `translateY(${state.progress * 60}px)`
    default:
      return 'translateY(0)'
  }
}
