import React, { FC } from 'react'
import { Divider, FormControl } from '@mui/material'
import styles from './form.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

type Props = { noIndentation?: boolean }

export const CollapsibleContentContainer: FC<Props> = ({ children, noIndentation }) => {
  const { mobile, desktop, tabletAll } = useBreakpoints()

  const getDynamicFormControlStyles = () => {
    if (desktop) return { paddingLeft: '2.25rem' }
    if (mobile) return { paddingLeft: '0' }
    if (tabletAll) {
      if (noIndentation) return { paddingLeft: '0.25rem' }
      return { paddingLeft: '2.65rem' }
    }
  }
  return (
    <div>
      <Divider variant="fullWidth" className={styles.dividerMargin} />
      <FormControl sx={{ width: '100%', ...getDynamicFormControlStyles() }}>{children}</FormControl>
    </div>
  )
}
