import styles from './ShoppingCartSummary.module.scss'
import { ShoppingCartSummaryPrice } from '../shoppingcart/ShoppingCartSummaryPrice'
import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import {
  Button,
  Card,
  CardContent,
  Divider,
  SvgIcon,
  Typography,
  useTheme,
  Link,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as VoltimumIcon } from '@obeta/assets/icon/voltimum.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import { ReactComponent as StrikeThroughCartIcon } from 'assets/icon/designsystem/remove_shopping_cart.svg'
import { ReactComponent as TransferIcon } from 'assets/icon/designsystem/swap_horiz.svg'
import {
  ShoppingCartContextEnum,
  ShoppingCartItem,
  ShoppingCartPrices,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart'
import { OfferForDropdown, OfferV2 } from '@obeta/models/lib/models/Offer/OfferV2'
import { useTranslation } from 'react-i18next'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'
import { Dropdown } from '../dropdown/Dropdown'
import {
  PricePermissions,
  UserAddressV2,
  UserV2,
  UserV2Data,
} from '@obeta/models/lib/models/Users/UserV2'
import { ShippingData } from '@obeta/models/lib/models/ShoppingCart/ShippingData'
import { OrderButton } from '../orders/OrderButton'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { CompanyBaseData } from '@obeta/models/lib/models/Company/CompanyBaseData'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { ContentMask } from './ContentMask'
import { ReactComponent as LayersIcon } from 'assets/icon/designsystem/layers.svg'
import { CheckoutInquireOfferOverlay } from '../shoppingcart/CheckoutInquireOfferOverlay'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { CheckoutOfferInquiryFeedbackOverlay } from './CheckoutOfferInquiryFeedbackOverlay'
import { Browser } from '@capacitor/browser'
import { WWW_URL } from '@obeta/utils/lib/config'
import { Capacitor } from '@capacitor/core'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ReplacementArticleDialog } from '../replacement-articles/ReplacementArticleDialog'
import { Checkbox } from '../checkbox/Checkbox'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { SecondaryButton } from '../custom-button/CustomButton'
import { IsPaymentMethodPermittedContext } from '@obeta/data/lib/hooks/useIsPaymentMethodPermittedProvider'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import dayjs from 'dayjs'
import { CheckoutPromotionInfo } from './CheckoutPromotionInfo'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { ApolloProvider } from '@apollo/client'

interface Props {
  cartOfferId?: string
  context: ShoppingCartContextEnum
  shippingData?: ShippingData
  offers: OfferV2[]
  orderButtonEnabled?: boolean
  selectedCartId: string
  shoppingCartPrices?: ShoppingCartPrices
  updateCartIsCompleteDelivery: (checked: boolean) => void
  updateCartOffer: (offerId: string) => void
  checkBoxIsCompleteDelivery: boolean
  user: UserV2 | null
  loadingPrices?: boolean
  notifyShoppingCart?: () => void
  submitShoppingCart?: () => void
  userMainAddress?: UserAddressV2
  activeCart?: ShoppingCartV2
  userData?: UserV2Data
  companyData?: CompanyBaseData
  isDeactivated?: boolean
  deliveryDisabled?: boolean
  specialOrCustomProductsInCart?: boolean
  voltimumPoints: number
  isLoadingVoltimumPoints: boolean
  hasPvProductsInCart?: boolean
  isEmptyCart?: boolean
  optimisticPricesCartSummary?: OptimisticPricesCartSummary
  setUtilizeOptimisticPrices?: (boolean) => void
  utilizeOptimisticPrices?: boolean
  deadProductsInCart?: boolean
  itemsDiscontinuedWithPartialStock?: ShoppingCartItem[]
  deleteCartItems?: (cartItems: ShoppingCartItem[]) => void
  setItemOfChoiceShouldBeDeleted?: (itemOfChoice: ShoppingCartItem) => void
  indexToShowForDiscontinuedItem?: number
  setIndexToShowForDiscontinuedItem?: React.Dispatch<React.SetStateAction<number>>
  setItemsDiscontinuedWithPartialStock?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  setMarkedItemsOfChoiceForDeletion?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount?: React.Dispatch<
    React.SetStateAction<ShoppingCartItem[]>
  >
  setReplacementItemsToBeAddedToCart?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  markedItemsOfChoiceForDeletion?: ShoppingCartItem[]
  transferOciCartAndSubmit?: () => void
  transferIdsCartAndSubmit: () => void
  promotionId?: string
  userCanSubmitCart?: boolean
}

export type OptimisticPricesCartSummary = Pick<
  Partial<ShoppingCartPrices>,
  'net' | 'metal' | 'netSum' | 'vat' | 'totalSum' | 'shipping'
>

const INITIAL_OPTIMISTIC_PRICES = {
  net: 0,
  metal: 0,
  shipping: 0.001,
  netSum: 0.001,
  vat: 0,
  totalSum: 0.001,
}

const REQUEST_OFFER_LIMIT = 1000

export const ShoppingCartSummary: React.FC<Props> = (props) => {
  const [showOfferInquiryOverlay, setShowOfferInquiryOverlay] = useState(false)
  const [openReplacementArticleDialog, setOpenReplacementArticleDialog] = useState(false)
  const { isSelectedPaymentMethodPermitted } = useContext(IsPaymentMethodPermittedContext)
  const theme = useTheme()

  const defaultProps = {
    context: ShoppingCartContextEnum.DETAILS,
    orderButtonEnabled: true,
    selectedOfferId: '',
    notifyShoppingCart: () => {
      //
    },
    shippingBlockIsActivated: false,
    submitShoppingCart: () => {
      //
    },
  }
  props = {
    ...defaultProps,
    ...props,
  }

  const { desktop } = useBreakpoints()
  const apolloClient = useStrapiGql()
  const { t } = useTranslation()
  const history = useHistory()

  const [showSuccessOverlay, setShowSuccessOverlay] = useState(false)
  const [showFailureOverlay, setShowFailureOverlay] = useState(false)
  const [selectedStore, setSelectedStore] = useState<StoreV2 | undefined>(undefined)
  const secondCartText = t('SHOPPING_CART.DELIVERY_RELATED_TEXT_CART_MESSAGE_2')
  const specialAndCustomProductsInformationText = t(
    'SHOPPING_CART.SPECIAL_AND_CUSTOM_PRODUCTS_SHIPPING_RELATED_INFORMATION'
  )
  const completeDeliveryInfoText = t('SHOPPING_CART.INFORMATION.COMPLETE_DELIVERY_INFO')

  const pvArticlesInCartInfoText = t('SHOPPING_CART.PV_ARTICLE_IN_CART_IEXT_MESSAGE')

  const {
    cartOfferId,
    context,
    shippingData,
    offers,
    orderButtonEnabled,
    selectedCartId,
    shoppingCartPrices,
    updateCartIsCompleteDelivery,
    updateCartOffer,
    checkBoxIsCompleteDelivery,
    user,
    notifyShoppingCart,
    submitShoppingCart,
    isDeactivated,
    deliveryDisabled = false,
    specialOrCustomProductsInCart = false,
    voltimumPoints,
    isLoadingVoltimumPoints,
    hasPvProductsInCart,
    isEmptyCart,
    optimisticPricesCartSummary,
    setUtilizeOptimisticPrices,
    utilizeOptimisticPrices,
    itemsDiscontinuedWithPartialStock,
    deadProductsInCart,
    indexToShowForDiscontinuedItem,
    setIndexToShowForDiscontinuedItem,
    setItemsDiscontinuedWithPartialStock,
    setMarkedItemsOfChoiceForDeletion,
    setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount,
    setReplacementItemsToBeAddedToCart,
    markedItemsOfChoiceForDeletion = [],
    transferOciCartAndSubmit,
    transferIdsCartAndSubmit,
    activeCart,
    promotionId,
    userCanSubmitCart = false,
  } = props

  const sessionContext = getSessionContext()
  const userIsOciUser = sessionContext?.type === 'oci'
  const userIsIdsUser = useIsIdsTransferBackPossible()

  useEffect(() => {
    setUtilizeOptimisticPrices && setUtilizeOptimisticPrices(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCartPrices?.net])

  useEffect(() => {
    if (
      !optimisticPricesCartSummary ||
      !shoppingCartPrices ||
      Object.keys(optimisticPricesCartSummary).length === 0 ||
      JSON.stringify(INITIAL_OPTIMISTIC_PRICES) === JSON.stringify(optimisticPricesCartSummary)
    ) {
      return
    }
    let priceDiscrepancyError = ''
    Object.keys(optimisticPricesCartSummary).forEach((key) => {
      const calculatedPrice = Math.round(optimisticPricesCartSummary[key] * 100) / 100
      const remotePrice = Math.round(shoppingCartPrices[key] * 100) / 100
      if (calculatedPrice !== remotePrice) {
        priceDiscrepancyError =
          priceDiscrepancyError +
          `Found discrepancy between optimistically calculated prices and remote prices in ${selectedCartId}. optimisticPricesCartSummary[${key}] is ` +
          calculatedPrice +
          ` while shoppingCartPrices[${key}] is ` +
          remotePrice +
          '. '
      }
    })

    if (priceDiscrepancyError) {
      const error = new Error()
      error.message = priceDiscrepancyError
      handleError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCartPrices?.net])

  const offerIsValid = (offer: OfferV2) => {
    const validFrom = dayjs(offer.validFrom, 'YYYYMMDD')
    const validTo = dayjs(offer.validTo, 'YYYYMMDD')
    const now = dayjs()

    return (
      offer.active &&
      !offer.locked &&
      !offer.cancelled &&
      // It is essential to compare days instead of timestamps to support offers starting or expiring today
      (validFrom.isSame(now, 'day') || validFrom.isBefore(now, 'day')) &&
      (validTo.isSame(now, 'day') || validTo.isAfter(now, 'day'))
    )
  }
  const offersForDropdown: OfferForDropdown[] = []
  offers.forEach((offer) => {
    if (offerIsValid(offer)) {
      offersForDropdown.push({
        id: offer.id,
        name: offer.offerName === '' ? offer.id : offer.offerName,
      })
    }
  })
  const selectedOfferForDropdown: OfferForDropdown | undefined = offersForDropdown.find(
    (offer) => offer.id === cartOfferId
  )

  const showLoadingSkeleton = false

  useEffect(() => {
    if (!itemsDiscontinuedWithPartialStock) return
    if (indexToShowForDiscontinuedItem === -1) return
    if (itemsDiscontinuedWithPartialStock.length && !openReplacementArticleDialog) {
      setOpenReplacementArticleDialog(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexToShowForDiscontinuedItem, itemsDiscontinuedWithPartialStock?.length])

  return (
    <>
      <Card className={styles.card}>
        {isDeactivated && <ContentMask />}
        <CardContent className={styles.cardContent}>
          <Grid container direction={'column'} rowSpacing={1.25}>
            <Grid>
              <Typography data-testid="cart-overview-text" variant={'h3'}>
                {t('SHOPPING_CART.CART_OVERVIEW')}
              </Typography>
            </Grid>
            <Grid>
              <Divider variant="fullWidth" />
            </Grid>
            {user?.permissions?.Global_canReadPrices !== PricePermissions.PurchasePrice && (
              <Grid>
                <SimpleInfoBlock
                  body={'Fehlende Berechtigung zur Anzeige von Preisen.'}
                  Icon={InfoIcon}
                  variant="light"
                />
              </Grid>
            )}
            {user?.permissions?.Global_canReadPrices === PricePermissions.PurchasePrice && (
              <>
                <Grid>
                  <ShoppingCartSummaryPrice
                    description={'Nettowert'}
                    value={
                      utilizeOptimisticPrices && optimisticPricesCartSummary?.net
                        ? optimisticPricesCartSummary.net
                        : shoppingCartPrices?.net
                    }
                    variantDescription="subTitle"
                    variantValue="boldText"
                    loading={showLoadingSkeleton}
                  />
                </Grid>
                <Grid>
                  <ShoppingCartSummaryPrice
                    description={'Metallzuschlag'}
                    value={
                      utilizeOptimisticPrices && optimisticPricesCartSummary?.metal
                        ? optimisticPricesCartSummary.metal
                        : shoppingCartPrices?.metal
                    }
                    variantDescription="subTitle"
                    variantValue="boldText"
                    loading={showLoadingSkeleton}
                  />
                </Grid>
                <Grid>
                  <ShoppingCartSummaryPrice
                    description={'Lieferkosten'}
                    value={
                      utilizeOptimisticPrices && optimisticPricesCartSummary?.shipping
                        ? optimisticPricesCartSummary.shipping
                        : shoppingCartPrices?.shipping
                    }
                    variantDescription="subTitle"
                    variantValue="boldText"
                    loading={showLoadingSkeleton}
                  />
                </Grid>
                <Grid>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <ShoppingCartSummaryPrice
                    description={t('SHOPPING_CART.OVERALL_VALUE')}
                    value={
                      utilizeOptimisticPrices && optimisticPricesCartSummary?.netSum
                        ? optimisticPricesCartSummary.netSum
                        : shoppingCartPrices?.netSum
                    }
                    variantDescription="h3"
                    variantValue="h3"
                    loading={showLoadingSkeleton}
                  />
                </Grid>
                <ShoppingCartSummaryPrice
                  description={'MwSt'}
                  value={
                    utilizeOptimisticPrices && optimisticPricesCartSummary?.vat
                      ? optimisticPricesCartSummary.vat
                      : shoppingCartPrices?.vat
                  }
                  variantDescription="subTitle"
                  variantValue="boldText"
                  loading={showLoadingSkeleton}
                />
                <ShoppingCartSummaryPrice
                  description={'Gesamtbetrag'}
                  value={
                    utilizeOptimisticPrices && optimisticPricesCartSummary?.totalSum
                      ? optimisticPricesCartSummary.totalSum
                      : shoppingCartPrices?.totalSum
                  }
                  variantDescription="boldText"
                  variantValue="boldText"
                  loading={showLoadingSkeleton}
                />
              </>
            )}

            <Grid size={{ xs: 12 }}>
              <Dropdown
                items={offersForDropdown}
                updateCartOffer={updateCartOffer}
                selectedItem={selectedOfferForDropdown}
                clearable={true}
              />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ width: '100%' }}>
              <Grid
                sx={{ width: '100%' }}
                alignItems="center"
                justifyContent={desktop ? 'flex-start' : 'center'}
              >
                <Checkbox
                  checked={checkBoxIsCompleteDelivery}
                  disabled={!shippingData?.isCompleteDeliveryPossible}
                  indeterminate={!shippingData?.isCompleteDeliveryPossible}
                  label={t('SHOPPING_CART.COMPLETE_DELIVERY')}
                  tooltip={desktop && completeDeliveryInfoText}
                  onChange={(event) => updateCartIsCompleteDelivery(event.target.checked)}
                />
              </Grid>
            </Grid>

            <Grid size={{ xs: 12 }}>
              {(context === ShoppingCartContextEnum.DETAILS ||
                context === ShoppingCartContextEnum.DETAILS_EDITING) && (
                <>
                  {userIsOciUser && (
                    <Button
                      className={`${styles.fullWidth} ${styles.submitIDSButton}`}
                      type="submit"
                      variant={'contained'}
                      color={'primary'}
                      startIcon={
                        <SvgIcon htmlColor={theme.palette.white.main} component={TransferIcon} />
                      }
                      onClick={transferOciCartAndSubmit}
                    >
                      {t('SHOPPING_CART.CART_SUBMIT_OCI')}
                    </Button>
                  )}
                  {!userIsOciUser && (
                    <>
                      <Button
                        className={clsx(styles.fullWidth, userIsIdsUser && styles.mb1)}
                        type="submit"
                        disabled={
                          deadProductsInCart || context === ShoppingCartContextEnum.DETAILS_EDITING
                        }
                        variant={'contained'}
                        color={'primary'}
                        startIcon={
                          <SvgIcon htmlColor={theme.palette.white.main} component={CartIcon} />
                        }
                        onClick={() => {
                          if (
                            itemsDiscontinuedWithPartialStock &&
                            itemsDiscontinuedWithPartialStock?.length > 0
                          ) {
                            setOpenReplacementArticleDialog(true)
                            if (indexToShowForDiscontinuedItem === -1)
                              setIndexToShowForDiscontinuedItem &&
                                setIndexToShowForDiscontinuedItem(0)
                          } else {
                            history.push('/shopping-cart-checkout/' + selectedCartId)
                          }
                        }}
                      >
                        {t('SHOPPING_CART.NAVIGATION.CONTINUE_TO_ORDER_DETAILS')}
                      </Button>
                      {userIsIdsUser && (
                        <SecondaryButton
                          fullWidth
                          type="submit"
                          leftIcon={
                            <SvgIcon
                              htmlColor={theme.palette.white.main}
                              component={StrikeThroughCartIcon}
                            />
                          }
                          onClick={transferIdsCartAndSubmit}
                        >
                          {t('SHOPPING_CART.CART_SUBMIT_IDS')}
                        </SecondaryButton>
                      )}
                    </>
                  )}
                  {shoppingCartPrices && shoppingCartPrices.netSum >= REQUEST_OFFER_LIMIT && (
                    <Button
                      className={clsx(
                        styles.fullWidth,
                        styles.marginButtonInquireOffer,
                        styles.colorButtonInquireOffer
                      )}
                      variant={'outlined'}
                      startIcon={<SvgIcon component={LayersIcon} />}
                      onClick={() => {
                        setShowOfferInquiryOverlay(true)
                      }}
                    >
                      {t('SHOPPING_CART.REQUEST_OFFER')}
                    </Button>
                  )}
                </>
              )}

              {(context === ShoppingCartContextEnum.CHECKOUT_DELIVERY ||
                context === ShoppingCartContextEnum.CHECKOUT_PICKUP) &&
                notifyShoppingCart &&
                submitShoppingCart && (
                  <div className={styles.orderButtonContainer}>
                    <OrderButton
                      orderButtonEnabled={
                        (orderButtonEnabled && isSelectedPaymentMethodPermitted) ?? true
                      }
                      userCanSubmitCart={userCanSubmitCart}
                      notifyShoppingCart={notifyShoppingCart}
                      submitShoppingCart={submitShoppingCart}
                      deliveryDisabled={deliveryDisabled}
                    />
                    {userIsIdsUser && (
                      <SecondaryButton
                        fullWidth
                        type="submit"
                        leftIcon={
                          <SvgIcon
                            htmlColor={theme.palette.white.main}
                            component={StrikeThroughCartIcon}
                          />
                        }
                        onClick={transferIdsCartAndSubmit}
                      >
                        {t('SHOPPING_CART.CART_SUBMIT_IDS')}
                      </SecondaryButton>
                    )}
                  </div>
                )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div>
                <Typography variant={'bodyBold'}>
                  Ich stimme den{' '}
                  <Link
                    href={ShopRoutes.Terms}
                    className={styles.blackLink}
                    onClick={(event) => {
                      event.preventDefault()
                      if (Capacitor.isNativePlatform()) {
                        Browser.open({
                          url: WWW_URL + ShopRoutes.Terms,
                        })
                      } else {
                        history.push(ShopRoutes.Terms)
                      }
                    }}
                  >
                    AGB
                  </Link>{' '}
                  zu.
                </Typography>
              </div>
            </Grid>
            {activeCart && promotionId && (
              <Grid size={{ xs: 12 }}>
                <ApolloProvider client={apolloClient}>
                  <CheckoutPromotionInfo activeCart={activeCart} promotionId={promotionId} />
                </ApolloProvider>
              </Grid>
            )}
            {!isEmptyCart && (
              <Grid>
                <SimpleInfoBlock
                  body={
                    <div className={styles.voltimum}>
                      <span>{t('SHOPPING_CART.VOLTIMUM.0')}&nbsp;</span>
                      {isLoadingVoltimumPoints ? (
                        <TextSkeleton height={14} width={20} />
                      ) : (
                        <span>{voltimumPoints}</span>
                      )}
                      <span className={styles.voltimumIcon}>
                        <VoltimumIcon />
                      </span>
                      <span>{t('SHOPPING_CART.VOLTIMUM.1')}</span>
                    </div>
                  }
                  Icon={InfoIcon}
                  variant="light"
                />
              </Grid>
            )}

            {user?.isTecselectUser && (
              <Grid>
                <SimpleInfoBlock
                  body=""
                  Icon={InfoIcon}
                  variant="light"
                  tecselectPoints={shoppingCartPrices?.tecselect}
                />
              </Grid>
            )}

            <Grid data-testid="delivery-information">
              <SimpleInfoBlock body={secondCartText} Icon={InfoIcon} variant="light" />
            </Grid>

            {specialOrCustomProductsInCart && (
              <Grid>
                <SimpleInfoBlock
                  body={specialAndCustomProductsInformationText}
                  Icon={InfoIcon}
                  variant="light"
                />
              </Grid>
            )}

            {hasPvProductsInCart && (
              <Grid>
                <SimpleInfoBlock body={pvArticlesInCartInfoText} Icon={InfoIcon} variant="light" />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {showOfferInquiryOverlay && (
        <CheckoutInquireOfferOverlay
          showOfferInquiryOverlay={showOfferInquiryOverlay}
          setShowOfferInquiryOverlay={setShowOfferInquiryOverlay}
          setShowSuccessOverlay={setShowSuccessOverlay}
          setShowFailureOverlay={setShowFailureOverlay}
          setSelectedStore={setSelectedStore}
          selectedCartId={selectedCartId}
        />
      )}

      {(showSuccessOverlay || showFailureOverlay) && (
        <CheckoutOfferInquiryFeedbackOverlay
          store={selectedStore as StoreV2}
          states={{
            showSuccessOverlay,
            setShowSuccessOverlay,
            showFailureOverlay,
            setShowFailureOverlay,
          }}
        />
      )}

      {itemsDiscontinuedWithPartialStock &&
        itemsDiscontinuedWithPartialStock[indexToShowForDiscontinuedItem as number] && (
          <ReplacementArticleDialog
            key={
              itemsDiscontinuedWithPartialStock[indexToShowForDiscontinuedItem as number].product
                .sapId
            }
            open={openReplacementArticleDialog}
            onClose={() => {
              if (markedItemsOfChoiceForDeletion.length > 0 && setMarkedItemsOfChoiceForDeletion) {
                setMarkedItemsOfChoiceForDeletion((items) => {
                  return items.slice(0, -1)
                })
              }
              setOpenReplacementArticleDialog(false)
              setIndexToShowForDiscontinuedItem &&
                setIndexToShowForDiscontinuedItem((oldValue: number) => {
                  return oldValue + 1
                })
            }}
            itemOfChoice={
              itemsDiscontinuedWithPartialStock[indexToShowForDiscontinuedItem as number]
            }
            showDeleteButtonForDiscontinuedItem={true}
            setOpenReplacementArticleDialog={setOpenReplacementArticleDialog}
            setIndexToShowForDiscontinuedItem={setIndexToShowForDiscontinuedItem}
            setMarkedItemsOfChoiceForDeletion={setMarkedItemsOfChoiceForDeletion}
            itemsDiscontinuedWithPartialStock={itemsDiscontinuedWithPartialStock}
            setItemsDiscontinuedWithPartialStock={setItemsDiscontinuedWithPartialStock}
            setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount={
              setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount
            }
            setReplacementItemsToBeAddedToCart={setReplacementItemsToBeAddedToCart}
            activeCartName={props.activeCart?.name as string}
          />
        )}
    </>
  )
}
