import { gql } from '@apollo/client'

export const gqlPossibleProjects = gql`
  query getPossibleProjects {
    getPossibleProjects {
      id
      name
    }
  }
`
