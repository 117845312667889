import { FC } from 'react'
import { useSearchPromotionBanner } from '@obeta/data/lib/hooks/useSearchPromotionBanner'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'
import { ProductSearchUserData } from '@obeta/schema'
import styles from './SearchPromotionBanner.module.scss'

type Props = {
  bannerData: Array<ProductSearchUserData>
}

export const SearchPromotionBanner: FC<Props> = ({ bannerData }) => {
  const promotionBanner = useSearchPromotionBanner(bannerData)
  if (!promotionBanner) return null

  return (
    <div className={styles.container}>
      <ShopLink className={styles.link} href={promotionBanner.target}>
        <img src={promotionBanner.imageUrl} alt={promotionBanner.actionName} />
      </ShopLink>
    </div>
  )
}
