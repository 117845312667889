import { createContext } from 'react'
import { PullToRefreshStore } from './store'

export type PullToRefreshContextValue = Readonly<{
  enabled: boolean
  store: PullToRefreshStore
  register: (handler: () => Promise<void>) => () => void
}>

export const PullToRefreshContext = createContext<PullToRefreshContextValue | null>(null)
