import { createContext, useContext, useEffect, useState } from 'react'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

type UserManagementReadOnlyValue = { readOnly: boolean }
const UserManagementReadOnlyContext = createContext<UserManagementReadOnlyValue>({
  readOnly: false,
})

export const UserManagementReadOnlyProvider = ({ children }) => {
  const [readOnly, setReadOnly] = useState(false)
  const user = useUserDataV2()

  useEffect(() => {
    if (user && user.permissions) {
      setReadOnly(!user.permissions.Global_canSeeUserManagement)
    }
  }, [user])

  return (
    <UserManagementReadOnlyContext.Provider value={{ readOnly }}>
      {children}
    </UserManagementReadOnlyContext.Provider>
  )
}

export const useUserManagementReadOnly = () => {
  return useContext(UserManagementReadOnlyContext).readOnly
}
